import { faCartShopping, faCode, faCogs, faCreditCard, faDashboard, faEnvelope, faFileExport, faLinkHorizontal, faMessage, faMobile, faObjectGroup, faPenRuler, faSms } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { Alert, Layout, Menu } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import MWare_Logo from '../../../assets/images/logo.png';
import MWare_Icon from '../../../assets/images/icon.png';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import { BillingContextProvider } from '../../../contexts/billingContext';
import * as DeploymentService from '../../../services/deployments/deployment';
import Dashboard from './dashboard';
import EmailGateways from './emailGateways';
import PushGateways from './pushGateways';
import PaymentMethods from './paymentMethods';
import AppStoreMethods from './appstoreMethods';
import Wallets from './wallets';
import Settings from './settings';
import ShoppingCarts from './shoppingCarts';
import AppStores from './appStores';
import SMSGateways from './smsGateways';
import APIBuilder from './apibuilder';
import APIS from './apis';
import * as ProductService from '../../../services/areas/billing/productService';
import * as ShoppingCartService from '../../../services/areas/billing/shoppingCartService';
import * as PaymentProviderService from '../../../services/areas/billing/paymentProviderService';
import * as EmailGatewayService from '../../../services/areas/billing/emailGatewayService';
import * as SmsGatewayService from '../../../services/areas/billing/smsGatewayService';
import * as TaxService from '../../../services/areas/billing/taxService';
import { useEffectOnce, useLocation } from 'react-use';
import { hasRole } from '../../../utils/Auth/role.auth';
import { AuthContext } from '../../../context_api/AuthContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getOpenKeys, getSelectedKey } from '../../../utils/menu';

const Billing = () => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const inventoryUrl = url.split('/').slice(0, -1).concat('inventory').join('/');
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  const history = useHistory();
  var locSplit = location?.pathname?.split('/');
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }
  const [languagesInstalled, setLangaugesInstalled] = useState(true);
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'billing') {
      history.push(url + '/shopping-carts');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  const getLanguages = async (langs) => {
    var languagesOut = [] as any;
    try {
      const fetchCarouselPromises = langs.map(async (language) => await axios.get('https://cloudtv.akamaized.net/' + clientName + '/' + deployment + '/' + service + '/carts/translations/' + language.name + '.json'));
      Promise.allSettled(fetchCarouselPromises).then((values) => {
        values.forEach((value, index) => {
          if (value.status === 'rejected') {
            languagesOut.push({
              available: false,
              name: langs[index].name,
            });
          } else {
            languagesOut.push({
              available: true,
              name: langs[index].name,
            });
          }
        });
        var test = languagesOut.find((f) => f.available == false);
        if (test != undefined) {
          setLangaugesInstalled(false);
        }
      });
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    if (data != undefined) {
      getLanguages(data.services[0].languages);
    }
  }, [data]);

  const taxes = useQuery(
    ['tax', 1, 100],
    async () => {
      return (
        await TaxService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const products = useQuery(
    ['products', 1, 100],
    async () => {
      return (
        await ProductService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const paymentgateways = useQuery(
    ['payment-providers', 1, 100],
    async () => {
      return (
        await PaymentProviderService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const emailgateways = useQuery(
    ['email-gateway', 1, 100],
    async () => {
      return (
        await EmailGatewayService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const smsgateways = useQuery(
    ['sms-gateway', 1, 100],
    async () => {
      return (
        await SmsGatewayService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const shoppingCarts = useQuery(
    ['shoppingCarts', 1, 100],
    async () => {
      return (
        await ShoppingCartService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );

  function getItem(label: React.ReactNode, key: React.Key, link: any, icon?: React.ReactNode, children?: any[]): any {
    return {
      label,
      key,
      link,
      icon,
      children,
    } as any;
  }

  return (
    <Layout>
      <Layout.Sider
        width="300"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            padding: 40,
            paddingTop: 57,
            paddingBottom: 56,
            marginBottom: 20,
            borderBottom: '1px #444 solid',
            backgroundColor: '#002140',
          }}
        >
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </a>
          )}
        </div>
        <Menu
          mode={'inline'}
          inlineCollapsed={collapsed}
          theme="dark"
          defaultSelectedKeys={getSelectedKey(location?.pathname ?? '', 'billing')}
          defaultOpenKeys={getOpenKeys(location?.pathname ?? '', 'billing')}
          items={[
            isClient
              ? {
                  icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
                  label: (
                    <div>
                      <span>{t('dashboard')}</span>
                    </div>
                  ),
                  key: 'dashboard',
                  children: [
                    {
                      label: (
                        <Link to={`${url}`}>
                          <span>{t('dashboard')}</span>
                        </Link>
                      ),
                      key: 'dashboard-dashboard',
                    },
                  ],
                }
              : {
                  label: <></>,
                  key: 'empty',
                },
            {
              icon: <FontAwesomeIcon icon={faCartShopping} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('webshops')}</span>
                </div>
              ),
              key: 'shopping-carts',
              children: [
                {
                  label: (
                    <Link to={`${url}/shopping-carts`}>
                      <span>{t('carts-and-portals')}</span>
                    </Link>
                  ),
                  key: 'web-shops',
                },
                // {
                //   label: (
                //     <Link to={`${url}/app-stores`}>
                //       <span>{t('app-stores')}</span>
                //     </Link>
                //   ),
                //   key: 'app-stores',
                // },
                // {
                //   label: (
                //     <Link to={`${url}/api-stores`}>
                //       <span>{t('api-stores')}</span>
                //     </Link>
                //   ),
                //   key: 'api-stores',
                // },
              ],
            },

            {
              icon: <FontAwesomeIcon icon={faLinkHorizontal} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('gateways')}</span>
                </div>
              ),
              key: 'gateways',
              children: [
                {
                  label: (
                    <div>
                      <span>{t('payment-gateways')}</span>
                    </div>
                  ),
                  key: 'payment-method',
                  children: [
                    {
                      label: (
                        <Link to={`${url}/payment-gateways`}>
                          <span>{t('payment-gateways')}</span>
                        </Link>
                      ),
                      key: 'payment-methods',
                    },
                    {
                      label: (
                        <Link to={`${url}/payment-gateways/my-gateways`}>
                          <span>{t('my-payment-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-payment-methods',
                    },
                  ],
                },
                {
                  label: (
                    <div>
                      <span>{t('appstore-gateways')}</span>
                    </div>
                  ),
                  key: 'appstore-gateways',
                  children: [
                    {
                      label: (
                        <Link to={`${url}/appstore-gateways`}>
                          <span>{t('app-gateways')}</span>
                        </Link>
                      ),
                      key: 'app-gateway',
                    },
                    {
                      label: (
                        <Link to={`${url}/appstore-gateways/my-gateways`}>
                          <span>{t('my-app-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-app-gateways',
                    },
                  ],
                },
                {
                  label: (
                    <div>
                      <span>{t('email-gateways')}</span>
                    </div>
                  ),
                  key: 'email-gateways',
                  children: [
                    {
                      label: (
                        <Link to={`${url}/email-gateways`}>
                          <span>{t('email-gateways')}</span>
                        </Link>
                      ),
                      key: 'email-gateway',
                    },
                    {
                      label: (
                        <Link to={`${url}/email-gateways/my-gateways`}>
                          <span>{t('my-email-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-email-gateways',
                    },
                  ],
                },
                {
                  label: (
                    <div>
                      <span>{t('sms-gateways')}</span>
                    </div>
                  ),
                  key: 'sms-gateways',
                  children: [
                    {
                      label: (
                        <Link to={`${url}/sms-gateways`}>
                          <span>{t('sms-gateways')}</span>
                        </Link>
                      ),
                      key: 'sms-gateway',
                    },
                    {
                      label: (
                        <Link to={`${url}/sms-gateways/my-gateways`}>
                          <span>{t('my-sms-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-sms-gateways',
                    },
                  ],
                },
                {
                  label: (
                    <div>
                      <span>{t('push-gateways')}</span>
                    </div>
                  ),
                  key: 'push-gateways',
                  children: [
                    {
                      label: (
                        <Link to={`${url}/push-gateways`}>
                          <span>{t('push-gateways')}</span>
                        </Link>
                      ),
                      key: 'push-gateway',
                    },
                    {
                      label: (
                        <Link to={`${url}/push-gateways/my-gateways`}>
                          <span>{t('my-push-gateways')}</span>
                        </Link>
                      ),
                      key: 'my-push-gateways',
                    },
                  ],
                },
              ],
            },

            {
              icon: <FontAwesomeIcon icon={faFileExport} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('message-templates')}</span>
                </div>
              ),
              key: 'templates',
              children: [
                {
                  label: (
                    <Link to={`${url}/settings/sms-templates`}>
                      <span>{t('sms-templates')}</span>
                    </Link>
                  ),
                  key: 'settings-sms-templates',
                },
                {
                  label: (
                    <Link to={`${url}/settings/email-templates`}>
                      <span>{t('email-templates')}</span>
                    </Link>
                  ),
                  key: 'settings-email-templates',
                },
                {
                  label: (
                    <Link to={`${url}/settings/app-templates`}>
                      <span>{t('app-templates')}</span>
                    </Link>
                  ),
                  key: 'settings-app-templates',
                },
                {
                  label: (
                    <Link to={`${url}/settings/push-templates`}>
                      <span>{t('push-templates')}</span>
                    </Link>
                  ),
                  key: 'settings-push-templates',
                },
              ],
            },
            {
              icon: <FontAwesomeIcon icon={faMessage} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('message-settings')}</span>
                </div>
              ),
              key: 'messages',
              children: [
                {
                  label: (
                    <Link to={`${url}/settings/welcome-message`}>
                      <span>{t('welcome-message')}</span>
                    </Link>
                  ),
                  key: 'settings-welcomeMessage',
                },
                {
                  label: (
                    <Link to={`${url}/settings/registration`}>
                      <span>{t('registration')}</span>
                    </Link>
                  ),
                  key: 'settings-registration',
                },
                {
                  label: (
                    <Link to={`${url}/settings/password-recovery-message`}>
                      <span>{t('password-recovery')}</span>
                    </Link>
                  ),
                  key: 'settings-passwordRecovery',
                },
                {
                  label: (
                    <Link to={`${url}/settings/renewals`}>
                      <span>{t('renewals')}</span>
                    </Link>
                  ),
                  key: 'settings-renewals',
                },
                {
                  label: (
                    <Link to={`${url}/settings/dunning`}>
                      <span>{t('dunning')}</span>
                    </Link>
                  ),
                  key: 'settings-dunning',
                },
                {
                  label: (
                    <Link to={`${url}/settings/promotions`}>
                      <span>{t('promotions')}</span>
                    </Link>
                  ),
                  key: 'settings-promotions',
                },
                {
                  label: (
                    <Link to={`${url}/settings/payment-received-message`}>
                      <span>{t('payment-received')}</span>
                    </Link>
                  ),
                  key: 'settings-paymentReceivedMessage',
                },
                {
                  label: (
                    <Link to={`${url}/settings/payment-failed-message`}>
                      <span>{t('payment-failed')}</span>
                    </Link>
                  ),
                  key: 'settings-paymentFailedMessage',
                },
                {
                  label: (
                    <Link to={`${url}/settings/change-base-subscription-message`}>
                      <span>{t('change-subscription')}</span>
                    </Link>
                  ),
                  key: 'settings-changeBaseSubscriptionMessage',
                },
                {
                  label: (
                    <Link to={`${url}/settings/add-extra-subscription-message`}>
                      <span>{t('add-subscription')}</span>
                    </Link>
                  ),
                  key: 'settings-addExtraSubscriptionMessage',
                },
                {
                  label: (
                    <Link to={`${url}/settings/remove-extra-subscription-message`}>
                      <span>{t('remove-subscription')}</span>
                    </Link>
                  ),
                  key: 'settings-removeExtraSubscriptionMessage',
                },
                {
                  label: (
                    <Link to={`${url}/settings/renewed-subscription-message`}>
                      <span>{t('renewed-subscription')}</span>
                    </Link>
                  ),
                  key: 'settings-renewExtraSubscriptionMessage',
                },
              ],
            },
            {
              icon: <FontAwesomeIcon icon={faCogs} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('settings')}</span>
                </div>
              ),
              key: 'settings',
              children: [
                {
                  label: (
                    <Link to={`${url}/settings/subscription-rules`}>
                      <span>{t('subscription-rules')}</span>
                    </Link>
                  ),
                  key: 'settings-subscription-rules',
                },
                {
                  label: (
                    <Link to={`${url}/settings/translations`}>
                      <span>{t('translations')}</span>
                    </Link>
                  ),
                  key: 'settings-translations',
                },
              ],
            },

            {
              icon: <FontAwesomeIcon icon={faCode} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span>{t('billing-api')}</span>
                </div>
              ),
              key: 'billing-api',
              children: [
                {
                  label: (
                    <Link to={`${url}/apibuilder`}>
                      <span>{t('api-builder')}</span>
                    </Link>
                  ),
                  key: 'billing-api-builder',
                },
                {
                  label: (
                    <Link to={`${url}/apis/v1`}>
                      <span>{t('tvms-api')}</span>
                    </Link>
                  ),
                  key: 'billing-api-add',
                },
                {
                  label: (
                    <Link to={`${url}/apis/mybundle`}>
                      <span>{t('mybundle-api')}</span>
                    </Link>
                  ),
                  key: 'billing-api-mybundle',
                },
              ],
            },
          ]}
        />
      </Layout.Sider>
      <Layout>
        <div style={{ paddingLeft: 0, width: '100%' }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          {isError && <span>{t('something-went-wrong')}</span>}
          <div className="mt-3 ml-5 mr-5">
            {taxes.data?.docs.length == 0 && (
              <Alert
                className="mt-4"
                message={t('taxes')}
                showIcon
                description={t('you-have-not-yet-setup-any-tax-rules-you-need-the-tax-rules-before-you-can-create-packages')}
                type="error"
                action={
                  <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${inventoryUrl}/settings/taxes`}>
                    {t('setup-taxes')}
                  </Link>
                }
              />
            )}
            {products.data?.docs.length == 0 && (
              <Alert
                className="mt-4"
                message={t('products')}
                showIcon
                description={t('you-have-not-yet-defined-any-products-you-need-products-before-you-can-create-a-shopping-cart')}
                type="error"
                action={
                  <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${inventoryUrl}/packages`}>
                    {t('create-product')}
                  </Link>
                }
              />
            )}
            {paymentgateways.data?.docs.length == 0 && (
              <Alert
                className="mt-4"
                message={t('payment-gateway')}
                showIcon
                description={t('you-have-not-yet-defined-any-payment-gateways-you-need-a-payment-gateway-before-you-can-create-a-shopping-cart')}
                type="info"
                action={
                  <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${url}/payment-gateways`}>
                    {t('create-payment-gateway')}
                  </Link>
                }
              />
            )}
            {emailgateways.data?.docs.length == 0 && smsgateways.data?.docs.length == 0 && (
              <Alert
                className="mt-4"
                message={t('email-or-sms-gateway')}
                showIcon
                description={t('you-have-not-yet-defined-any-communication-gateways-you-need-either-an-email-or-sms-gateway-before-you-can-create-a-shopping-cart')}
                type="info"
                action={
                  <div className="col">
                    <div className="row mb-2">
                      <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${url}/email-gateways`}>
                        {t('create-email-gateway')}
                      </Link>
                    </div>
                    <div className="row">
                      <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${url}/sms-gateways`}>
                        {t('create-sms-gateway')}
                      </Link>
                    </div>
                  </div>
                }
              />
            )}
            {shoppingCarts.data?.docs.length == 0 && (
              <Alert
                className="mt-4"
                message={t('shopping-carts')}
                showIcon
                description={t('you-have-not-yet-defined-any-shopping-carts-you-need-shopping-carts-before-you-can-add-customers')}
                type="error"
                action={
                  <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${url}/shopping-carts`}>
                    {t('create-shopping-cart')}
                  </Link>
                }
              />
            )}
            {!languagesInstalled && (
              <Alert
                className="mt-4"
                message={t('cart-and-portal-translations')}
                showIcon
                description={t('you-have-not-yet-installed-the-cart-and-portal-translations-this-is-a-mandatory-element')}
                type="info"
                action={
                  <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${url}/settings/translations`}>
                    {t('install-translations')}
                  </Link>
                }
              />
            )}
          </div>
          {!isLoading && !isError && data && (
            <BillingContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
              <Switch>
                <Route path={`${path}/sms-gateways`}>
                  <SMSGateways />
                </Route>
                <Route path={`${path}/email-gateways`}>
                  <EmailGateways />
                </Route>
                <Route path={`${path}/push-gateways`}>
                  <PushGateways />
                </Route>
                <Route path={`${path}/wallets`}>
                  <Wallets />
                </Route>

                <Route path={`${path}/shopping-carts`}>
                  <ShoppingCarts />
                </Route>
                <Route path={`${path}/app-stores`}>
                  <AppStores />
                </Route>
                <Route path={`${path}/payment-gateways`}>
                  <PaymentMethods />
                </Route>
                <Route path={`${path}/appstore-gateways`}>
                  <AppStoreMethods />
                </Route>
                <Route path={`${path}/settings`}>
                  <Settings />
                </Route>
                <Route path={`${path}/apibuilder`}>
                  <APIBuilder />
                </Route>
                <Route path={`${path}/apis`}>
                  <APIS />
                </Route>
                <Route path={`${path}`}>{isClient && <Dashboard />}</Route>
              </Switch>
            </BillingContextProvider>
          )}
        </div>
      </Layout>
    </Layout>
  );
};

export default Billing;
