import ReactApexChart from 'react-apexcharts';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator';
import { useTranslation } from 'react-i18next';
import { getChartProps } from '@mwaretv/database/build/backend/apexChartConfig/apexChartConfig';

export const BarChart = ({ chartInput }: { chartInput: ChartInput }) => {
  const { t } = useTranslation();
  
  if (!chartInput.series || chartInput.series.length === 0) {
    return <></>;
  }
  
  const chartProps = getChartProps(chartInput, t('chart-design-no-data'), t('amount'));

  return <ReactApexChart options={chartProps.options} series={chartProps.options.series} type={chartProps.type} width={chartProps.width} height={chartProps.height} />;
};
