import { Form, notification } from 'antd';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { BackButton } from '../../../../common/components/BackButton';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import reportSettingsService from '../../../../services/areas/reporting/reportsettings/reportsettingsservice';
import { IMSNameParams } from '../../../dashboard';
import { useEffectOnce } from 'react-use';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';
import { ReportSettings } from '@mwaretv/database/build/backend/models/reporting/reportSettings/reportSettings';
import { ReportingContext } from '../../../../contexts/reportingContext';
import { ReportSettingsFields } from './reportsettingsform';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
 
const DetailsReportSettings = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(ReportingContext);
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorModalData, setErrorModalData] = useState([] as any);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [reportSettings, setReportSettings] = useState<ReportSettings | undefined>(undefined);
  const [form] = Form.useForm();

  const handleSubmit = async (reportSettings) => {
    setIsSubmitting(true);

    try {
      const requestData: TvmsApiRequestData<ReportSettings> = {
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
        payload: {
          data: reportSettings,
        }
      };

      let reportSettingsResponse: TvmsApiResponseData<ReportSettings>

      if (reportSettings._id) {
        requestData.urlParams = {
          id: reportSettings._id,
        };
        reportSettingsResponse = await reportSettingsService.Update(requestData);
      }
      else {
        reportSettingsResponse = await reportSettingsService.Add(requestData);
      }

      if (reportSettingsResponse.statusCode === HttpStatusCodes.OK) {
        notification.success({
          message: t('updated-successfully'),
        });
        history.push(redirectUrl);
      } else {
        notification.error({
          message: t('an-error-occured'),
        });
      }

    }
    catch (error) {
      console.log(error);
      notification.error({
        message: t('an-error-occured'),
      });
    }
    
    setIsSubmitting(false);
  };

  const getReportSettings = async () => {
    setIsLoading(true);

    try {
      const requestData: TvmsApiRequestData<ReportSettings> = {
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
      };

      const responseData = await reportSettingsService.GetOnlyOrDefault(requestData);
      if (responseData.statusCode === HttpStatusCodes.OK) {

        if (responseData.data) {
          const values: any = responseData.data;

          form.setFieldsValue(values);

          setReportSettings(responseData.data);
        }
      } else {
        console.log(responseData);
        setIsError(true);
      }
    }
    catch (error) {
      console.log(error);
      setIsError(true);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      await getReportSettings();
    };
    fetchData();
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('chart-design-back-to-reports')} url={redirectUrl} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('chart-design-update-report-settings')} subtitle={t('chart-design-update-report-settings-subtitle')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={errorModalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            onFinish={(values) => {
              handleSubmit(values);
            }}
            onFinishFailed={(errorInfo) => {
              setErrorModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
            layout="vertical"
          >
            {!isLoading && !isError &&
            <>
              <ReportSettingsFields logo={reportSettings?.logo} />
              <div className="text-right">
                <Button htmlType="submit" loading={isSubmitting} disabled={isSubmitting} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('chart-design-update-report-settings')}
                </Button>
              </div>
            </>
            }
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default DetailsReportSettings;
