import { Button, Col, Form, Row, Switch, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import ScheduleModal from './ScheduleModal';
import { ContentContext } from '../../../../contexts/contentContext';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';

const ReleaseSchedules = (params: { setSchedules: any; data?: any }) => {
  const [scheduleToUpdate, setScheduleToUpdate] = useState([] as any);
  const [showScheduleUpdateModal, setShowScheduleUpdateModal] = useState<boolean>(false);
  const [isLoadingSchedules, setIsLoadingSchedules] = useState(false);
  const { deployment } = useContext(ContentContext);
  const countriesDeployment = (deployment?.countries as []) || [];
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [releaseScheduleLocal, setReleaseScheduleLocal] = useState([] as any);

  const scheduleColumns = [
    {
      title: t('country'),
      dataIndex: 'country',
    },
    {
      title: t('start'),
      dataIndex: 'start',
      render: (i, row) => {
        return <span>{moment.unix(row.start).format('YYYY-MM-DD')}</span>;
      },
    },
    {
      title: t('end'),
      dataIndex: 'end',
      render: (i, row) => {
        return <span>{moment.unix(row.end).format('YYYY-MM-DD')}</span>;
      },
    },
    {
      title: t('enable-preview'),
      dataIndex: 'show_preview',
      render: (i, row) => {
        return <Switch style={{ marginTop: 0 }} checked={row.show_preview}></Switch>;
      },
    },
    {
      title: t('ribbon-preview'),
      dataIndex: 'show_preview_ribbon',
      render: (i, row) => {
        return <Switch style={{ marginTop: 0 }} checked={row.show_preview_ribbon}></Switch>;
      },
    },
    {
      title: t('ribbon-new-release'),
      dataIndex: 'show_release_ribbon',
      render: (i, row) => {
        return <Switch style={{ marginTop: 0 }} checked={row.show_release_ribbon}></Switch>;
      },
    },
    {
      dataKey: 'actions',
      title: t('actions'),
      render: (i, row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Button
                onClick={() => {
                  setScheduleToUpdate(row);
                  setShowScheduleUpdateModal(!showScheduleUpdateModal);
                }}
                type="text"
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
              >
                <FontAwesomeIcon className="action-icon" icon={faEdit} />
                <div>{t('edit')}</div>
              </Button>
            </Row>
          </Col>
        );
      },
    },
  ];

  useEffect(() => {
    if (params.data != undefined) {
      setReleaseScheduleLocal(params.data);
      setIsLoadingSchedules(false);
    } else {
      var releaseschedule = {
        schedules: countriesDeployment.map((country: any) => {
          return {
            country: country.name,
            start: moment().unix(),
            end: moment().add(100, 'years').unix(),
            show_preview: false,
            show_preview_ribbon: false,
            show_release_ribbon: false,
          };
        }),
      };
      setReleaseScheduleLocal(releaseschedule);
      setIsLoadingSchedules(false);
    }
  }, [params.data]);

  const convertScheduleDateTimesFromDayjsToUnix = (schedule: any): any => {
    if (!schedule) {
      return undefined;
    }
    return {
      ...schedule,
      start: schedule.start?.unix(),
      end: schedule.end?.unix(),
    };
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('release-schedules')}</h4>
      <p>{t('release-schedules-info')}</p>
      <div className="row">
        <div className="col-md-12">
          {showScheduleUpdateModal && scheduleToUpdate && (
            <ScheduleModal
              initialData={scheduleToUpdate}
              onCancel={() => {
                setShowScheduleUpdateModal(!showScheduleUpdateModal);
              }}
              onSubmit={(values: any) => {
                values = convertScheduleDateTimesFromDayjsToUnix(values);

                params.setSchedules({
                  schedules: releaseScheduleLocal?.schedules?.map((country: any) => {
                    if (country.country == values.country) {
                      return values;
                    }
                    return country;
                  }) ?? []
                });
                setShowScheduleUpdateModal(!showScheduleUpdateModal);
              }}
              visible={showScheduleUpdateModal}
            />
          )}

          <Form form={form} component={false}>
            <Table
              rowClassName="editable-row"
              rowKey={(languagesLocal) => languagesLocal.name}
              loading={isLoadingSchedules}
              columns={scheduleColumns}
              dataSource={releaseScheduleLocal?.schedules ?? []}
              key={JSON.stringify(releaseScheduleLocal)}
              pagination={{
                position: ['bottomLeft'],
                pageSize: 10,
                hideOnSinglePage: true,
                showTotal: (total, range) => {
                  return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total });
                },
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};
export default ReleaseSchedules;
