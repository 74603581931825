import { faInboxOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Logo } from '@mwaretv/database/build/backend/models/reporting/reportSettings/reportSettings';


const ReportLogoUploader = ({ logo }: { logo?: Logo}) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const [logoState, setLogo] = useState<Logo | undefined>(logo);

  return (
    <div>
      <h4 className="text-primary mt-5">{t('service-logo')}</h4>
      <div className="col-md-12 card-bg-light" style={{ backgroundColor: '#fafafc' }}>
        <div className="row" style={{ alignItems: 'center' }}>
          <div className="col-md-6 row ml-2 p-2" style={{ alignItems: 'center' }}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      {!logoState?.contentBase64 && (
                        <div style={{ width: 200, height: '100%', border: '2px dashed #999' }}>
                          <div
                            style={{
                              fontSize: 12,
                              textAlign: 'center',
                              margin: 15,
                            }}
                          >
                            {t('any-size')}
                          </div>
                        </div>
                      )}
                      {logoState?.contentBase64 && (
                        <div style={{ height: 100 }}>
                          <img src={logoState.contentBase64} height="100px" alt="logo" />
                        </div>
                      )}
                    </div>
                  </td>
                  <td style={{ paddingLeft: 25 }}></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 mb-0 mt-4">
            <Form.Item name={['logo', 'fileName']} hidden={true} />
            <Form.Item name={['logo', 'contentType']} hidden={true} />
            <Form.Item name={['logo', 'contentBase64']} hidden={true} />
            <Upload.Dragger
              listType="picture"
              className="default-upload"
              maxCount={1}
              showUploadList={{
                showPreviewIcon: true,
              }}
              multiple={false}
              accept=".png, .jpg, .jpeg"
              beforeUpload={file => {
                try {
                  const reader = new FileReader();
          
                  reader.onload = e => {
                    const newLogo: Logo = {
                      fileName: file.name,
                      contentType: file.type,
                      contentBase64: e.target?.result?.toString(),
                    };
                    
                    form.setFieldValue(['logo', 'fileName'], file.name);
                    form.setFieldValue(['logo', 'contentType'], file.type);
                    form.setFieldValue(['logo', 'contentBase64'], e.target?.result?.toString());
                    
                    setLogo(newLogo);
                  };

                  reader.readAsDataURL(file);
                }
                catch (error) {
                  console.log(error);
                }
        
                return false;
              }}
            >
              <div className="media d-inline-flex align-items-center">
                <FontAwesomeIcon size="2x" className="mr-3" icon={faInboxOut} color="#1ac884" />
                <div className="media-body text-left my-3">
                  <h6 className="mt-0 text-primary">{t('upload-your-file')}</h6>
                  <p className="mb-0 h6 icon-gray font-weight-normal">
                    {t('drag-files-here-or')} <span className="text-secondary"> {t('browse')}</span>
                  </p>
                </div>
              </div>
            </Upload.Dragger>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportLogoUploader;
