import { Button, Form, Input, notification, Switch, Select, Alert } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { CustomNotification } from '../../../../../common/components/Notification';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import ratingService from '../../../../../services/areas/appbuilder/applications/ageratings';
import epgService from '../../../../../services/areas/content/epg/epg_settings';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import Menus from '../../../../../common/components/AppBuilder/Menus';
import { STORAGE_ENDPOINTS } from '../../../../../services/storage_paths';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import NestedApps from '../../../../../common/components/AppBuilder/NestedApps';
import axios from 'axios';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import MenuBuilder from './helpers/menu';
import { VisibleForRole } from '../../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../../constants/TVMSRoles';

const AddApp = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [form] = useForm();
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [formDetails, setFormDetails] = useState([] as any);
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [ratings, setRatings] = useState([] as any);
  const [switch3, setSwitch3] = useState(false);
  const [switch4, setSwitch4] = useState(false);

  const [switch5, setSwitch5] = useState(false);
  const [switch6, setSwitch6] = useState(false);
  const [switch7, setSwitch7] = useState(false);
  const [switch9, setSwitch9] = useState(false);
  const [switch10, setSwitch10] = useState(false);
  const [switch11, setSwitch11] = useState(false);
  const [switch12, setSwitch12] = useState(false);
  const [switch13, setSwitch13] = useState(false);
  const [switch14, setSwitch14] = useState(false);

  const [switch15, setSwitch15] = useState(false);
  const [switch16, setSwitch16] = useState(false);
  const [switch17, setSwitch17] = useState(false);
  const [switch18, setSwitch18] = useState(false);
  const [switch19, setSwitch19] = useState(false);
  const [switch20, setSwitch20] = useState(false);

  const [switch21, setSwitch21] = useState(false);
  const [switch22, setSwitch22] = useState(false);
  const [switch23, setSwitch23] = useState(false);
  const [switch24, setSwitch24] = useState(false);
  const [switch25, setSwitch25] = useState(false);

  const [switch27, setSwitch27] = useState(false);
  const [switch28, setSwitch28] = useState(false);

  const [switch29, setSwitch29] = useState(false);
  const [switch30, setSwitch30] = useState(false);

  const [switch31, setSwitch31] = useState(false);
  const [switch32, setSwitch32] = useState(false);

  const [offerType, setOfferType] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [loadedMenus, setLoadedMenus] = useState([] as any);

  const [defaultLanguages, setDefaultLanguages] = useState([] as any);
  const [epgSettings, setEpgSettings] = useState([] as any);
  const [selectedNestedApps, setSelectedNestedApps] = useState([] as any);
  const [app, setApp] = useState([] as any);
  const history = useHistory();
  const [languages, setLanguages] = useState([] as any);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];

  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 50,
    query_params: {
      instance: clientName,
      cms: deployment?.name ?? '',
    },
  });

  const handleSubmit2 = async (values: any) => {
    var rating = [] as any;
    if (values.settings.agerating != 'NoRating') {
      rating = ratings.find((r) => r.name == values.settings.agerating.name);
    }

    var langs = [] as any;
    values.settings.languages?.name?.forEach((language) => {
      var lang_ = languages.find((l) => l.label == language);

      if (lang_ != undefined) {
        langs.push({
          name: lang_.label,
          code: lang_.code,
        });
      }
    });
    if (values.settings.languages?.name == undefined) {
      defaultLanguages.forEach((language) => {
        var lang_ = languages.find((l) => l.label == language);

        if (lang_ != undefined) {
          langs.push({
            name: lang_.label,
            code: lang_.code,
          });
        }
      });
    }

    setIsLoading(true);
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', app.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      tp: 'sZP7IYe6T6Pg3Sbi0lhL06k1Tu5iFSaZIlf-ClA6IOkg0S5r0lho0lUeClb6FOPlUY3zWokgbgjNIOf9flPe3uRL3ofZFDC_3lB-3QatCZzrIQfZFSeLCKhk3lbo3oBcImfVfK4_bQgZCYxNWoryIQXzImf90Sbc0Sbi3lai0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lRc3L0Z0Lbk0SezFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikf6i6CDrebKjNIOfVfKXpIwPqdDxzU6fVfKINbK4zU6fVfKgqbZfVfGxNsK4pf6i6UwIqbZfVfGUgCKjLfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya',
      deleted: false,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...formDetails,
      settings: {
        ...formDetails.settings,
        phone: values.settings.phone,
        skype: values.settings.skype,
        whatsapp: values.settings.whatsapp,
        email: values.settings.email,
        website: values.settings.website,
        webtv: values.settings.webtv,

        offer_type: offerType,
        enable_onboarding: switch21,
        enable_connectcode: switch22,
        enable_signup: switch23,
        enable_profiles: switch3,
        enable_nested: selectedNestedApps.length == 0 ? false : switch12,
        enable_register: switch13,
        enable_anonymous: switch29,
        enable_upsell: switch30,
        enable_forgot: switch14,
        enable_change: switch31,

        enable_direct_to_television: switch4,
        clock_format: values.settings.clock_format,
        sleep_mode_setting: values.settings.sleep_mode_setting,
        allow_favorites: switch27,
        allow_watchlist: switch28,
        epg_rentention: epgSettings.epg_days != undefined ? epgSettings.epg_days : 1,

        show_change_language: switch5,
        show_change_parental_code: switch6,
        show_change_clock: switch7,

        show_info_subscription: switch9,
        show_info_manual: true,
        show_info_renewal: switch10,
        show_info_support: switch11,
        show_info_apps: switch15,
        show_info_about: switch24,
        show_info_devices: switch16,
        show_info_updates: switch17,
        show_info_refresh: switch18,
        show_info_disclaimer: switch19,
        show_info_logout: switch20,
        show_info_messages: switch25,

        agerating: rating,
        languages: langs,

        nested_apps: selectedNestedApps,
        objectstorage: switch32,
      },
      menus: loadedMenus,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('update-successfully'),
      });
      history.go(0);
    } else CustomNotification({ error: response.errors });
    setIsLoading(false);
  };

  const handleFormFill = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setFormDetails(response.data);
      form.setFieldsValue(response.data);
      setApp(resp);
      if (resp.settings) {
        setSelectedNestedApps(resp.settings.nested_apps);

        setSwitch3(resp.settings.enable_profiles);
        setSwitch4(resp.settings.enable_direct_to_television);

        setSwitch5(resp.settings.show_change_language);
        setSwitch6(resp.settings.show_change_parental_code);
        setSwitch7(resp.settings.show_change_clock);
        setSwitch9(resp.settings.show_info_subscription);
        setSwitch10(resp.settings.show_info_renewal);
        setSwitch11(resp.settings.show_info_support);
        setSwitch15(resp.settings.show_info_apps);
        setSwitch16(resp.settings.show_info_devices);
        setSwitch17(resp.settings.show_info_updates);
        setSwitch18(resp.settings.show_info_refresh);
        setSwitch19(resp.settings.show_info_disclaimer);
        setSwitch20(resp.settings.show_info_logout);
        setSwitch13(resp.settings.enable_register);
        setSwitch14(resp.settings.enable_forgot);
        setSwitch31(resp.settings.enable_change);
        setSwitch12(resp.settings.enable_nested);
        setSwitch21(resp.settings.enable_onboarding);
        setSwitch22(resp.settings.enable_connectcode);
        setSwitch23(resp.settings.enable_signup);
        setSwitch24(resp.settings.show_info_about);
        setSwitch25(resp.settings.show_info_messages);
        setSwitch27(resp.settings.allow_favorites);
        setSwitch28(resp.settings.allow_watchlist);
        setSwitch29(resp.settings.enable_anonymous);
        setSwitch30(resp.settings.enable_upsell);
        setSwitch32(resp.settings.objectstorage);
        setOfferType(resp.settings.offer_type);

        var langs = [] as any;
        if (resp.settings !== undefined) {
          resp.settings.languages.forEach((element) => {
            langs.push(element.name);
          });
        }
        setDefaultLanguages(langs);
      }

      getLanguages();

      if (resp.menus) {
        setLoadedMenus(resp.menus);
      }
    }
    setIsLoading(false);
  };

  const getLanguages = async () => {
    var languagesOut = [] as any;
    try {
      const fetchCarouselPromises = languagesDeployment.map(async (language) => await axios.get('https://cloudtv.akamaized.net/' + clientName + '/' + deployment?.name + '/appbuilder/translations/' + language.name + '.json'));
      Promise.allSettled(fetchCarouselPromises).then((values) => {
        values.forEach((value, index) => {
          if (value.status !== 'rejected') {
            languagesOut.push({
              label: languagesDeployment[index].name,
              value: languagesDeployment[index].name,
              code: languagesDeployment[index].code,
            });
          }
        });
        setLanguages(languagesOut);
      });
    } catch (err) {
    } finally {
    }
  };

  const getAgeRatings = async (list: ICMS_Lists) => {
    let response = await ratingService.List_AgeRatings(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setRatings(response.data.data);
    }
  };
  const getSettings = async () => {
    var values = {
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await epgService.Details_Epg_Settings(values as any);
    if (response !== undefined && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setEpgSettings(resp);
    }
  };

  useEffectOnce(() => {
    (async () => await getSettings())();
    (async () => await getAgeRatings(tableSearchState))();
    (async () => await handleFormFill())();
  });

  function setData(values: any) {
    setLoadedMenus(values);
  }
  function setServices(values: any) {}

  function changeOfferType(values: any) {
    if (values == 'freemium') {
      setSwitch21(true);
      setSwitch23(false);
    }
    if (values == 'cart') {
      setSwitch21(false);
      setSwitch23(true);
    }
    setOfferType(values);
  }

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <div className="container-fluid">
            <div className="row-md-12">
              <div className="col-md-12 mb-2">
                <h2 className="page-title mt-4 mb-4">
                  {t('settings-parenthesis')}
                  {app.branding?.name}/{app.branding?.packageid}
                  {t('close-parenthesis')}
                </h2>
              </div>
              <MenuBuilder application={app} redirectUrl={redirectUrl} id={id}></MenuBuilder>
            </div>
            {(epgSettings.na_text == undefined || epgSettings.na_text == '') && !isLoading && <Alert className="mt-4 mb-4" message={t('epg-settings')} showIcon description={`${t('epg-settings-are-not-yet-defined')} ${t('content')} > ${t('epg-settings')}`} type="error" />}
            <Form
              form={form}
              initialValues={{
                ...formDetails,
              }}
              onFinish={async (values) => {
                await handleSubmit2(values);
              }}
              onFinishFailed={(errorInfo) => {}}
            >
              <div className="card p-5">
                <div className="card-body">
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('app-menu')}</h4>
                    <p>
                      {t('select-the-menu-items-you-want-to-offer-your-customers-you-can-always-add-more-in-a-later-stage')}
                      <br />
                      <b>{t('important')}:</b> {t('the-order-in-which-you-place-the-menu-items-here-is-the-order-the-menu-will-show-in-the-apps')}
                    </p>
                    <div className="row-md-12">
                      <Menus setData={setData} data={loadedMenus}></Menus>
                    </div>
                  </div>

                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('nested-apps')}</h4>
                    <p>{t('nested-apps-are-used-to-link-other-app-and-design-via-an-app-id')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('enable-nested-apps')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'enable_nested']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch12} defaultChecked={false} onChange={() => setSwitch12(!switch12)} />
                      </Form.Item>
                    </div>
                    {switch12 && (
                      <div className="row-md-12">
                        <NestedApps setData={setSelectedNestedApps} data={selectedNestedApps} app={app}></NestedApps>
                      </div>
                    )}
                  </div>

                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('customer-journey')}</h4>
                    <p>{t('select-an-option-below-that-defines-your-situation-when-it-comes-to-your-offerings-and-how-you-want-the-app-to-behave')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('offer-type')}</label>
                      <div className="col-md-7">
                        <Form.Item name={['settings', 'offer_type']}>
                          <Select onChange={(value) => changeOfferType(value)} className="default-select" placeholder={t('select-type')}>
                            <Select.Option value={'anonymous'} key={'anonymous'}>
                              {t('you-offer-freemium-content-and-require-anonymous-access-to-the-apps')}
                            </Select.Option>
                            <Select.Option value={'freemium'} key={'freemium'}>
                              {t('you-offer-freemium-content-and-require-simple-registration-via-the-app')}
                            </Select.Option>
                            <Select.Option value={'cart'} key={'cart'}>
                              {t('you-offer-paid-subscriptions-and-want-to-let-customers-signup-via-the-apps-and-your-website-using-our-carts')}
                            </Select.Option>
                            <Select.Option value={'api'} key={'api'}>
                              {t('you-offer-paid-subscriptions-and-want-to-let-customers-signup-via-your-website-using-our-apis')}
                            </Select.Option>
                            {/* <Select.Option value={'sideload'} key={'sideload'}>
                              You offer paid subscriptions and want to let customers signup in app (Android only) via your api's
                            </Select.Option> */}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    {offerType != '' && (
                      <div className="card-bg-light p-5 mb-4 bg-white">
                        <h4 className="text-primary mb-2">{t('onboarding')}</h4>
                        <p>{t('onboarding-slides-are-used-to-inform-the-customer-about-the-app-features-and-where-to-signup')}</p>
                        <div className="row">
                          <label className="col-md-4 col-form-label">{t('enable-onboarding')}</label>
                          <Form.Item className="col-md-5" name={['settings', 'enable_onboarding']} valuePropName="checked">
                            <Switch className="ch-switch" checked={switch21} defaultChecked={false} onChange={() => setSwitch21(!switch21)} />
                          </Form.Item>
                        </div>

                        {offerType == 'freemium' && (
                          <>
                            <h4 className="text-primary mb-2">{t('register')}</h4>
                            <p>{t('this-feature-is-for-freemium-access-to-the-app-and-let-users-register-an-account-via-the-app-with-name-email-and-of-mobile')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-register')}</label>
                              <Form.Item className="col-md-5" name={['settings', 'enable_register']} valuePropName="checked">
                                <Switch className="ch-switch mt-2" checked={switch13} defaultChecked={false} onChange={() => setSwitch13(!switch13)} />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        {offerType == 'anonymous' && (
                          <>
                            <h4 className="text-primary mb-2">{t('anonymous')}</h4>
                            <p>{t('this-feature-is-for-anonymous-access-to-the-app-and-let-users-use-the-app-without-registering-for-an-account-via-the-app-with-name-email-and-of-mobile')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-anonymous')}</label>
                              <Form.Item className="col-md-5" name={['settings', 'enable_anonymous']} valuePropName="checked">
                                <Switch className="ch-switch mt-2" checked={switch29} defaultChecked={false} onChange={() => setSwitch29(!switch29)} />
                              </Form.Item>
                            </div>
                            <h4 className="text-primary mb-2">{t('upsell')}</h4>
                            <p>{t('this-feature-show-the-a-login-form-and-so-users-can-go-from-anonymous-access-to-a-registered-user-with-a-paid-package')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-upsell')}</label>
                              <Form.Item className="col-md-5" name={['settings', 'enable_upsell']} valuePropName="checked">
                                <Switch className="ch-switch mt-2" checked={switch30} defaultChecked={false} onChange={() => setSwitch30(!switch30)} />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        {offerType == 'cart' && (
                          <>
                            <h4 className="text-primary mb-2">{t('signup')}</h4>
                            <p>{t('this-feature-is-to-get-customers-started-from-the-app-and-get-them-to-the-website-cart-to-purchase-a-subscription-or-start-a-trial')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('enable-signup')}</label>
                              <Form.Item className="col-md-5" name={['settings', 'enable_signup']} valuePropName="checked">
                                <Switch className="ch-switch" checked={switch23} defaultChecked={false} onChange={() => setSwitch23(!switch23)} />
                              </Form.Item>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('connect-code')}</h4>
                    <p>{t('let-your-tv-app-users-login-super-easy-via-their-mobile-phone-or-computer-with-a-qr-code')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('enable-onboarding')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'enable_connectcode']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch21} defaultChecked={false} onChange={() => setSwitch22(!switch22)} />
                      </Form.Item>
                    </div>

                    <h4 className="text-primary mb-2">{t('webtv-url')}</h4>
                    <p>{t('enter-the-full-url-to-the-webtv-page-this-is-used-to-authenticate-the-user-and-validate-the-qr-code')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('url')}</label>
                      {!isLoading && (
                        <Form.Item extra={t('the-web-address-where-you-have-place-the-webtv-iframe-code')} style={{ padding: 0 }} name={['settings', 'webtv']}>
                          <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://yourwebtvwebsite.com/webtv" />
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('profiles')}</h4>
                    <p>{t('profiles-are-used-to-store-personal-information-for-your-clients-like-recordings-parental-codes-etc')} </p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('enable-profiles')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'enable_profiles']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch3} defaultChecked={false} onChange={() => setSwitch3(!switch3)} />
                      </Form.Item>
                    </div>

                    <h4 className="text-primary mb-2">{t('connect-code')}</h4>
                    <p>{t('let-television-app-users-login-easy-via-their-mobile-phone-or-computer-with-a-qr-code-or-website-url')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('enable-onboarding')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'enable_connectcode']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch21} defaultChecked={false} onChange={() => setSwitch22(!switch22)} />
                      </Form.Item>
                    </div>

                    <h4 className="text-primary mb-2">{t('forgot')}</h4>
                    <p>{t('allow-you-clients-to-restore-their-lost-login-information-via-the-app-a-valid-mobile-or-email-is-needed-for-this-function-to-work')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('enable-forgot')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'enable_forgot']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch14} defaultChecked={false} onChange={() => setSwitch14(!switch14)} />
                      </Form.Item>
                    </div>
                    <h4 className="text-primary mb-2">{t('change-password')}</h4>
                    <p>{t('allow-your-client-to-start-the-change-password-process-via-the-applications')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('enable-change-password')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'enable_change']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch31} defaultChecked={false} onChange={() => setSwitch31(!switch31)} />
                      </Form.Item>
                    </div>
                    <h4 className="text-primary mb-2">{t('reset-user-data')}</h4>
                    <p>{t('reset-user-data-such-as-service-id-username-password-and-selected-language-when-customer-select-logout-option-from-the-settings-menu')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('enable-reset')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'enable_delete_userdata']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch14} defaultChecked={false} onChange={() => setSwitch14(!switch14)} />
                      </Form.Item>
                    </div>
                    {/* <h4 className="text-primary mb-2">Direct to TV</h4>
                    <p>In some cases its more efficient to start the app directly to LiveTV, in order to do so enable this features</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">Direct to TV</label>
                      <Form.Item
                        className="col-md-5"
                        name={["settings", "enable_direct_to_television"]}
                        valuePropName="checked">
                        <Switch className="ch-switch" checked={switch4} defaultChecked={false} onChange={() => setSwitch4(!switch4)} />
                      </Form.Item>
                    </div> */}
                  </div>
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('general')}</h4>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('clock')}</label>
                      <div className="col-md-5">
                        <Form.Item name={['settings', 'clock_format']}>
                          <Select className="default-select" placeholder={t('select-type')}>
                            <Select.Option value={'AM/PM'} key={'AM/PM'}>
                              AM/PM
                            </Select.Option>
                            <Select.Option value={'24h'} key={'24h'}>
                              24h
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('sleep-mode-mins')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'sleep_mode_setting']}>
                        <Input defaultValue={0} type={'number'} min={0} className="form-control bg-white font-size-14 text-default" placeholder="300" />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('allow-favorites')}</label>
                      <Form.Item extra={t('by-enabling-this-switch-your-customers-can-add-remove-their-favorites-make-sure-to-create-a-rails-component-for-this-and-place-that-on-the-home-content-or-detail-pages')} className="col-md-5" name={['settings', 'allow_favorites']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch27} defaultChecked={false} onChange={() => setSwitch27(!switch27)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('allow-watchlist')}</label>
                      <Form.Item extra={t('by-enabling-this-switch-your-customers-can-manage-their-watchlist-and-the-watchlists-are-build-up-automatically-make-sure-to-create-a-rails-component-for-this-and-place-that-on-the-home-content-or-detail-pages')} className="col-md-5" name={['settings', 'allow_watchlist']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch28} defaultChecked={false} onChange={() => setSwitch28(!switch28)} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('settings')}</h4>
                    <p>{t('enable-disable-showing-features-in-the-setting-menu-on-the-apps')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('change-languages')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_change_language']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch5} defaultChecked={false} onChange={() => setSwitch5(!switch5)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('change-parental-code')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_change_parental_code']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch6} defaultChecked={false} onChange={() => setSwitch6(!switch6)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('change-clock')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_change_clock']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch7} defaultChecked={false} onChange={() => setSwitch7(!switch7)} />
                      </Form.Item>
                    </div>
                    {/* <div className="row">
                      <label className="col-md-4 col-form-label">Change DVR Offset</label>
                      <Form.Item
                        className="col-md-5"
                        name={["settings", "show_change_dvr_offset"]}
                        valuePropName="checked">
                        <Switch className="ch-switch" checked={switch8} defaultChecked={false} onChange={() => setSwitch8(!switch8)} />
                      </Form.Item>
                    </div> */}
                  </div>
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('account')}</h4>
                    <p>{t('enable-disable-showing-information-in-the-account-section-of-the-apps')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('subscripton')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_subscription']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch9} defaultChecked={false} onChange={() => setSwitch9(!switch9)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('renewal')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_renewal']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch10} defaultChecked={false} onChange={() => setSwitch10(!switch10)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('support')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_support']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch11} defaultChecked={false} onChange={() => setSwitch11(!switch11)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('apps')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_apps']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch15} defaultChecked={false} onChange={() => setSwitch15(!switch15)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('about')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_about']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch24} defaultChecked={false} onChange={() => setSwitch24(!switch24)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('devices')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_devices']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch16} defaultChecked={false} onChange={() => setSwitch16(!switch16)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('updates')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_updates']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch17} defaultChecked={false} onChange={() => setSwitch17(!switch17)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('refresh')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_refresh']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch18} defaultChecked={false} onChange={() => setSwitch18(!switch18)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('disclaimer')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_disclaimer']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch19} defaultChecked={false} onChange={() => setSwitch19(!switch19)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('logout')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_logout']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch20} defaultChecked={false} onChange={() => setSwitch20(!switch20)} />
                      </Form.Item>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('messages')}</label>
                      <Form.Item className="col-md-5" name={['settings', 'show_info_messages']} valuePropName="checked">
                        <Switch className="ch-switch" checked={switch25} defaultChecked={false} onChange={() => setSwitch25(!switch25)} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('age-ratings')}</h4>
                    <p>{t('select-an-age-rating-setup-if-your-app-shows-sensitive-content-and-you-can-provide-rating-identifiers-via-your-content-metadata-such-as-epg-movies-series-and-courses')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('age-rating')}</label>
                      <div className="col-md-5">
                        <Form.Item name={['settings', 'agerating', 'name']}>
                          <Select className="default-select" placeholder={t('select-setup')}>
                            <Select.Option value={'NoRating'} key={'NoRating'}>
                              {t('disable-ratings')}
                            </Select.Option>
                            {ratings.map((rating) => {
                              return (
                                <Select.Option value={rating.name} key={rating._id}>
                                  {rating.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('app-languages')}</h4>
                    <p>
                      {t('select-the-languages-you-want-to-offer-to-your-customers-in-this-app')} <br />
                      <b>important:</b> {t('if-the-selection-is-empty-then-you-have-not-yet-installed-the-language-files-to-do-so-open-settings-app-translations-and-install-edit-them')}
                    </p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('app-languages')}</label>
                      <div className="col-md-5">
                        {!isLoading && (
                          <Form.Item style={{ padding: 0 }} name={['settings', 'languages', 'name']}>
                            <Select
                              showSearch
                              loading={isLoading}
                              mode="multiple"
                              className="default-select"
                              allowClear
                              defaultValue={defaultLanguages}
                              filterOption={(input, option) => {
                                return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
                              }}
                              options={isLoading ? [] : languages}
                            />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('support-settings')}</h4>
                    <p>{t('add-your-contact-information-so-your-customers-can-reach-you-for-support')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('phone-number')}</label>
                      <div className="col-md-5">
                        {!isLoading && (
                          <Form.Item style={{ padding: 0 }} name={['settings', 'phone']}>
                            <Input className="form-control bg-white font-size-14 text-default" placeholder="001-1234567890" />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('skype-address')}</label>
                      <div className="col-md-5">
                        {!isLoading && (
                          <Form.Item style={{ padding: 0 }} name={['settings', 'skype']}>
                            <Input className="form-control bg-white font-size-14 text-default" placeholder="@yourskype" />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('whatsapp-number')}</label>
                      <div className="col-md-5">
                        {!isLoading && (
                          <Form.Item style={{ padding: 0 }} name={['settings', 'whatsapp']}>
                            <Input className="form-control bg-white font-size-14 text-default" placeholder="001-1234567890" />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('email-address')}</label>
                      <div className="col-md-5">
                        {!isLoading && (
                          <Form.Item style={{ padding: 0 }} name={['settings', 'email']}>
                            <Input type={'email'} className="form-control bg-white font-size-14 text-default" placeholder="your@support.com" />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('website-address')}</label>
                      <div className="col-md-5">
                        {!isLoading && (
                          <Form.Item style={{ padding: 0 }} name={['settings', 'website']}>
                            <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://yourwebsite.com" />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-bg-light p-5 mb-4">
                    <h4 className="text-primary mb-2">{t('epg-retention')}</h4>
                    <p>{t('this-value-is-configured-through-the-content-epg-settings')}</p>
                    <div className="row">
                      <label className="col-md-4 col-form-label">{t('epg-retention')}</label>
                      <div className="col-md-5">
                        {!isLoading && (
                          <Form.Item style={{ padding: 0 }} name={['settings', 'epg_rentention']}>
                            <Input disabled className="form-control bg-white font-size-14 text-default" />
                          </Form.Item>
                        )}
                      </div>
                    </div>
                  </div>

                  <VisibleForRole roles={[TVMSRoles.SuperAdmin]}>
                    <div className="card-bg-light p-5 mb-4">
                      <h4 className="text-primary mb-2">Object Storage</h4>
                      <div className="row">
                        <label className="col-md-4 col-form-label">Enabled</label>
                        <Form.Item className="col-md-5" name={['settings', 'objectstorage']} valuePropName="checked">
                          <Switch className="ch-switch mt-2" checked={switch32} defaultChecked={false} onChange={() => setSwitch32(!switch32)} />
                        </Form.Item>
                      </div>
                    </div>
                  </VisibleForRole>
                </div>
              </div>
              <div className="text-right">
                <Button loading={isLoading} htmlType="submit" className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('update-settings')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddApp;
