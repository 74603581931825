import { DatePicker, Form, Input, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index: any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const [startDate1, setStartDate1] = useState(dayjs());
  const [endDate1, setEndDate1] = useState(dayjs().add(99, 'year'));

  useEffect(() => {
    if (data != undefined) {
      if (data.scheduler != undefined) {
        if (data.scheduler.start != undefined) {
          setStartDate1(dayjs.unix(data.scheduler.start));
          setEndDate1(dayjs.unix(data.scheduler.end));
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (data.scheduler != undefined) {
      form.setFieldsValue(data.animation);
    }
  }, [data, form]);

  const onSubmitLocal = (values: any) => {
    let dataOut = {
      ...data,
      scheduler: {
        start: dayjs(startDate1).unix(),
        end: dayjs(endDate1).unix(),
      },
    };
    onSubmit(dataOut, type, props.index);
  };

  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-rails-schedule')}</h4>
            <div>{t('with-the-scheduler-you-can-manage-when-the-rails-should-be-shown-to-your-customers')} </div>
            <div>{t('this-way-you-can-setup-rails-upfront-for-certain-events-or-content-releases')} </div>
            <div>{t('its-as-easy-as-set-the-start-and-end-date-to-manage-showing-this-rails-in-the-apps')}</div>
            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="row">
                    <div className="col card-bg-light p-4 mb-2 mr-4">
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('start-date')}</label>

                        <Form.Item initialValue={startDate1} className="form-group pb-0 ">
                          <DatePicker
                            value={startDate1}
                            defaultValue={startDate1}
                            showToday
                            className="form-control height-48 bg-white font-size-14 text-default"
                            onChange={(value) => {
                              if (value != undefined) {
                                setStartDate1(value);
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('end-date')}</label>
                        <Form.Item initialValue={endDate1} className="form-group pb-0 ">
                          <DatePicker
                            value={endDate1}
                            showToday
                            defaultValue={endDate1}
                            className="form-control height-48 bg-white font-size-14 text-default"
                            onChange={(value) => {
                              if (value != undefined) {
                                setEndDate1(value);
                              }
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-schedule')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
