import { Route, Switch, useRouteMatch } from 'react-router';
import EmailCampaignsList from './email-campaigns/list';

const EmailCampaignsIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${path}/details/:_id`}>
        <EmailCampaignDetails />
      </Route>
      <Route path={`${path}/add`}>
        <EmailCampaignAdd />
      </Route> */}
      <Route path={`${path}/`}>
        <EmailCampaignsList />
      </Route>
    </Switch>
  );
};

export default EmailCampaignsIndex;
