import Hero_New from '../../../assets/images/appbuilder/new/hero.png';
import Slider_Hero_New from '../../../assets/images/appbuilder/new/slider_hero.png';
import Single_Hero_New from '../../../assets/images/appbuilder/new/single_hero.png';
import Player_Hero_New from '../../../assets/images/appbuilder/new/player_hero.png';
import Long_Rails_New from '../../../assets/images/appbuilder/new/long_rails.png';
import Menu_Rails_New from '../../../assets/images/appbuilder/new/menu_rails.png';
import Rectangle_Rails_New from '../../../assets/images/appbuilder/new/rectangle_rails.png';
import Banner_Rails_New from '../../../assets/images/appbuilder/Banner Rails.png';
import Round_Rails_New from '../../../assets/images/appbuilder/new/round_rails.png';
import Actors_Rails_New from '../../../assets/images/appbuilder/ActorsRails.png';
import Filter_Rails_New from '../../../assets/images/appbuilder/ProgramRails.png';
import Square_Rails_New from '../../../assets/images/appbuilder/new/square_rails.png';
import Tile_Rails_New from '../../../assets/images/appbuilder/new/tile_rails.png';
import Wide_Rails_New from '../../../assets/images/appbuilder/new/wide_rails.png';
import Top10_Rails_New from '../../../assets/images/appbuilder/new/top10_rails.png';
import Genre_Rails_New from '../../../assets/images/appbuilder/new/genre_rails.png';
import Player_Rails_New from '../../../assets/images/appbuilder/new/player_rails.png';
import Morphing_Rails_New from '../../../assets/images/appbuilder/new/morphing_rails.png';
import Add_Banner_New from '../../../assets/images/appbuilder/new/ad_banner.png';
import Add_Fullscreen_New from '../../../assets/images/appbuilder/new/ad_fullscreen.png';
import Logo_Time_New from '../../../assets/images/appbuilder/new/logo_time.png';
import Logo_Wide_New from '../../../assets/images/appbuilder/new/logo_wide.png';
import Search_Bar_New from '../../../assets/images/appbuilder/new/search_bar.png';
import Dvr_Progess_New from '../../../assets/images/appbuilder/new/dvr_progress.png';
import Message_New from '../../../assets/images/appbuilder/new/message.png';
import Promotion_New from '../../../assets/images/appbuilder/new/promotion.png';
import MenuDropdown_New from '../../../assets/images/appbuilder/new/menu_dropdown.png';
import Player_Video_New from '../../../assets/images/appbuilder/new/player_video.png';
import Player_Audio_New from '../../../assets/images/appbuilder/new/player_audio.png';
import Details_Video_New from '../../../assets/images/appbuilder/new/details_video.png';
import Details_Audio_New from '../../../assets/images/appbuilder/new/details_audio.png';
import Webview_New from '../../../assets/images/appbuilder/new/webview.png';
import Survey_New from '../../../assets/images/appbuilder/new/survey.png';
import TV_Guide_New from '../../../assets/images/appbuilder/new/tvguide.png';
import Download_List_New from '../../../assets/images/appbuilder/new/download_list.png';
import moment from 'moment';

//temp
// import appLongRailsService from '../../../services/areas/appbuilder/builder/elements/rails/long';
// import appRectangleRailsService from '../../../services/areas/appbuilder/builder/elements/rails/rectangle';
// import appBannerRailsService from '../../../services/areas/appbuilder/builder/elements/rails/banner';
// import appWidescreenRailsService from '../../../services/areas/appbuilder/builder/elements/rails/widescreen';
// import appPlayerRailsService from '../../../services/areas/appbuilder/builder/elements/rails/player';
// import appSquareRailsService from '../../../services/areas/appbuilder/builder/elements/rails/square';
// import appTop10RailsService from '../../../services/areas/appbuilder/builder/elements/rails/top10';
// import appGenreRailsService from '../../../services/areas/appbuilder/builder/elements/rails/genre';
// import appRoundRailsService from '../../../services/areas/appbuilder/builder/elements/rails/round';
// import appActorsRailsService from '../../../services/areas/appbuilder/builder/elements/rails/actors';
// import appTileRailsService from '../../../services/areas/appbuilder/builder/elements/rails/tile';
// import appMorphingRailsService from '../../../services/areas/appbuilder/builder/elements/rails/morphing';
// import appMenuRailsService from '../../../services/areas/appbuilder/builder/elements/rails/menu';
// import appMenuService from '../../../services/areas/appbuilder/builder/elements/navigation/menu';
// import appSingleService from '../../../services/areas/appbuilder/builder/elements/heros/single';
// import appSliderService from '../../../services/areas/appbuilder/builder/elements/heros/slider';
// import appPlayerService from '../../../services/areas/appbuilder/builder/elements/heros/player';
// import appAdsService from '../../../services/areas/appbuilder/builder/elements/promotions/ads';
// import appKaiAdsService from '../../../services/areas/appbuilder/builder/elements/promotions/kaiads';
// import appKaiFullscreenService from '../../../services/areas/appbuilder/builder/elements/promotions/kaifullscreen';
// import appCastoolaAdsService from '../../../services/areas/appbuilder/builder/elements/promotions/castoolaads';
// import appCastoolaFullscreenService from '../../../services/areas/appbuilder/builder/elements/promotions/castoolafullscreen';
// import appSurveyService from '../../../services/areas/appbuilder/builder/elements/promotions/survey';
// import appMessageService from '../../../services/areas/appbuilder/builder/elements/promotions/messages';
// import appOtaService from '../../../services/areas/appbuilder/builder/elements/promotions/ota';
// import appDvrProgressService from '../../../services/areas/appbuilder/builder/elements/misc/dvrplayer';
// import appFilterBarService from '../../../services/areas/appbuilder/builder/elements/misc/filterbar';
// import appSearchBarService from '../../../services/areas/appbuilder/builder/elements/misc/searchbar';
// import appLogoTimeService from '../../../services/areas/appbuilder/builder/elements/misc/logotime';
// import appLogoWideService from '../../../services/areas/appbuilder/builder/elements/misc/logowide';
// import appWebviewService from '../../../services/areas/appbuilder/builder/elements/misc/webview';
// import appListDownloads from '../../../services/areas/appbuilder/builder/elements/lists/downloads';
// import appAudio from '../../../services/areas/appbuilder/builder/elements/details/audiostandard/audiostandard';
// import appGuide from '../../../services/areas/appbuilder/builder/elements/tvguide/tvguide';
// import appDetailsBottom from '../../../services/areas/appbuilder/builder/elements/details/bottom/bottom';
// import appDetailsTop from '../../../services/areas/appbuilder/builder/elements/details/top/top';
// import appDetailsStandard from '../../../services/areas/appbuilder/builder/elements/details/standard/standard';
// import appDetailsMinimal from '../../../services/areas/appbuilder/builder/elements/details/minimal/minimal';
// import appDetailsCentered from '../../../services/areas/appbuilder/builder/elements/details/centered/centered';
// import appLongGridsService from '../../../services/areas/appbuilder/builder/elements/grids/long';
// import appRectangleGridsService from '../../../services/areas/appbuilder/builder/elements/grids/rectangle';
// import appWidescreenGridsService from '../../../services/areas/appbuilder/builder/elements/grids/widescreen';
// import appPlayerGridsService from '../../../services/areas/appbuilder/builder/elements/grids/player';
// import appSquareGridsService from '../../../services/areas/appbuilder/builder/elements/grids/square';
// import appTileGridsService from '../../../services/areas/appbuilder/builder/elements/grids/tile';

// import * as HttpStatusCodes from 'http-status-codes';

import LogoTime from './LiveComponents/misc/logotime';
import Single from './LiveComponents/heros/single';
import RectangleRails from './LiveComponents/rails/rectangle';
import BannerRails from './LiveComponents/rails/banner';
import SquareRails from './LiveComponents/rails/square';
import Top10Rails from './LiveComponents/rails/top10';
import GenreRails from './LiveComponents/rails/genre';
import MenuRails from './LiveComponents/rails/menu';
import RoundRails from './LiveComponents/rails/round';
import ActorsRails from './LiveComponents/rails/actors';
import FilterRails from './LiveComponents/rails/filter';
import WideRails from './LiveComponents/rails/wide';
import MorphingRails from './LiveComponents/rails/morphing';
import PlayerRails from './LiveComponents/rails/player';
import TileRails from './LiveComponents/rails/tile';
import LongRails from './LiveComponents/rails/long';
import KaiAd from './LiveComponents/promotions/kaiad';
import CastoolaAd from './LiveComponents/promotions/castoolaad';
import Ad from './LiveComponents/promotions/ad';
import CastoolaFullscreen from './LiveComponents/promotions/castoolafullscreen';
import KaiFullscreen from './LiveComponents/promotions/kaifullscreen';
import MessagePromo from './LiveComponents/promotions/message';
import OtaPromo from './LiveComponents/promotions/ota';
import Webview from './LiveComponents/misc/webview';
import LogoWide from './LiveComponents/misc/logowide';
import Slider from './LiveComponents/heros/slider';
import Player from './LiveComponents/heros/player';
import DvrProgress from './LiveComponents/misc/dvrprogress';
import FilterBar from './LiveComponents/misc/filterbar';
import SearchBar from './LiveComponents/misc/searchbar';
import InfoPromo from './LiveComponents/promotions/info';
import MenuBar from './LiveComponents/misc/menubar';
import SquareGrid from '../../../assets/images/appbuilder/new/square_grid.png';
import LongGrid from '../../../assets/images/appbuilder/new/long_grid.png';
import RectangleGrid from '../../../assets/images/appbuilder/new/rectangle_grid.png';
import WideGrid from '../../../assets/images/appbuilder/new/wide_grid.png';
import PlayerGrid from '../../../assets/images/appbuilder/new/player_grid.png';
import TileGrid from '../../../assets/images/appbuilder/new/tile_grid.png';
import Survey from './LiveComponents/misc/survey';
import WideGrids from './LiveComponents/grids/wide';
import LongGrids from './LiveComponents/grids/long';
import TileGrids from './LiveComponents/grids/tile';
import RectangleGrids from './LiveComponents/grids/rectangle';
import PlayerGrids from './LiveComponents/grids/player';
import SquareGrids from './LiveComponents/grids/square';
import TvGuide from './LiveComponents/tvguide/tvguide';
import AudioPlayer from './LiveComponents/players/audio';
import VideoPlayer from './LiveComponents/players/video';
import DetailsVideoStandard from './LiveComponents/details/videostandard';
import DetailsAudioStandard from './LiveComponents/details/audiostandard';
import DownloadList from './LiveComponents/lists/downloads';
import { useTranslation } from 'react-i18next';

class Utils {
  static getCorrectElement = (splitDraggable) => {
    var test = Utils.getHeaderContent()?.find((n) => n.name == splitDraggable[1])?.items as any;
    var test2 = test?.find((i) => i.type == splitDraggable[0]);
    if (test && test2) {
      return test2;
    } else {
      test = Utils.getHeroContent()?.find((n) => n.name == splitDraggable[1])?.items as any;
      test2 = test?.find((i) => i.type == splitDraggable[0]);
      if (test && test2) {
        return test2;
      } else {
        test = Utils.getContentContent()?.find((n) => n.name == splitDraggable[1])?.items as any;
        test2 = test?.find((i) => i.type == splitDraggable[0]);
        if (test && test2) {
          return test2;
        } else {
          test = Utils.getFooterContent()?.find((n) => n.name == splitDraggable[1])?.items as any;
          test2 = test?.find((i) => i.type == splitDraggable[0]);
          if (test && test2) {
            return test2;
          } else {
            return undefined;
          }
        }
      }
    }
  };
  static addElementToApplication = (result: any, application: any, pageName: any, elementIn?: any, indexIn?: any) => {
    var testIndex = application.screens.findIndex((s: any) => s.type == pageName);
    var droppableSplit = result.destination.droppableId.split('_');
    var splitDraggable = result.draggableId.split('_');
    var element = elementIn != undefined ? elementIn : Utils.getCorrectElement(splitDraggable);

    if (element == undefined) {
      return application;
    }

    switch (splitDraggable[0]) {
      case 'Survey':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { survey: element });
        return application;
      case 'Ads':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { ads: element });
        return application;
      case 'KaiAds':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { kaiads: element });
        return application;
      case 'KaiFS':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { kaifullscreen: element });
        return application;
      case 'CastoolaAds':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { castoolaads: element });
        return application;
      case 'CastoolaFS':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { castoolafullscreen: element });
        return application;
      case 'Messages':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { messages: element });
        return application;
      case 'OTA':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { otas: element });
        return application;
      case 'Single':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { singles: element });
        return application;
      case 'Slider':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { sliders: element });
        return application;
      case 'Player':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { players: element });
        return application;
      case 'Filter Bar':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { filterbars: element });
        return application;
      case 'Search Bar':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { searchbars: element });
        return application;
      case 'DVR Progress':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { dvrprogress: element });
        return application;
      case 'Logo & Time':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { logotime: element });
        return application;
      case 'Logo & Wide':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { logowide: element });
        return application;
      case 'Menu':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { menus: element });
        return application;
      case 'Webview':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { webview: element });
        return application;
      case 'Square Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { square_rails: element });
        return application;
      case 'Top10 Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { top10_rails: element });
        return application;
      case 'Genre Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { genre_rails: element });
        return application;
      case 'Round Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { round_rails: element });
        return application;
      case 'Actors Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { actors_rails: element });
        return application;
      case 'Filter Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { filter_rails: element });
        return application;
      case 'Rectangle Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { rectangle_rails: element });
        return application;
      case 'Banner Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { banner_rails: element });
        return application;
      case 'Tile Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { tile_rails: element });
        return application;
      case 'Long Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { long_rails: element });
        return application;
      case 'Wide Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { widescreen_rails: element });
        return application;
      case 'Player Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { player_rails: element });
        return application;
      case 'Morphing Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { morphing_rails: element });
        return application;
      case 'Menu Rails':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { menu_rails: element });
        return application;
      case 'Square Grid':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { square_grids: element });
        return application;
      case 'Rectangle Grid':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { rectangle_grids: element });
        return application;
      case 'Tile Grid':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { tile_grids: element });
        return application;
      case 'Long Grid':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { long_grids: element });
        return application;
      case 'Wide Grid':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { widescreen_grids: element });
        return application;
      case 'Player Grid':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { player_grids: element });
        return application;
      case 'Details Bottom':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn + 1 : result.destination.index + 1, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Video Details Standard':
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Top':
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Centered':
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Minimal':
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Standard':
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Audio Details Standard':
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_audiostandard: {
            ...element,
            type: 'Audio Standard',
          },
        });
        application.screens[testIndex][droppableSplit[0]]?.push({
          details_audio_standard: {
            ...element,
            type: 'Audio Details Standard',
          },
        });
        return application;
      case 'Guide Horizontal':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { guide_horizontal: element });
        return application;
      case 'Download List':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { list_downloads: element });
        return application;
      case 'Video Player':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { player_video: element });
        return application;
      case 'Audio Player':
        application.screens[testIndex][droppableSplit[0]]?.splice(indexIn != undefined ? indexIn : result.destination.index, 0, { player_audio: element });
        return application;
      default:
        return application;
    }
  };
  static editElementToApplication = (type: any, application: any, pageName: any, element: any, index: any, screenType: any) => {
    var testIndex = application.screens.findIndex((s: any) => s.type == pageName);
    switch (type) {
      case 'Survey':
        application.screens[testIndex][screenType]?.splice(index, 1, { survey: element });
        return application;
      case 'Ads':
        application.screens[testIndex][screenType]?.splice(index, 1, { ads: element });
        return application;
      case 'KaiAds':
        application.screens[testIndex][screenType]?.splice(index, 1, { kaiads: element });
        return application;
      case 'KaiFS':
        application.screens[testIndex][screenType]?.splice(index, 1, { kaifullscreen: element });
        return application;
      case 'CastoolaAds':
        application.screens[testIndex][screenType]?.splice(index, 1, { castoolaads: element });
        return application;
      case 'CastoolaFS':
        application.screens[testIndex][screenType]?.splice(index, 1, { castoolafullscreen: element });
        return application;
      case 'Messages':
        application.screens[testIndex][screenType]?.splice(index, 1, { messages: element });
        return application;
      case 'OTA':
        application.screens[testIndex][screenType]?.splice(index, 1, { otas: element });
        return application;
      case 'Single':
        application.screens[testIndex][screenType]?.splice(index, 1, { singles: element });
        return application;
      case 'Slider':
        application.screens[testIndex][screenType]?.splice(index, 1, { sliders: element });
        return application;
      case 'Player':
        application.screens[testIndex][screenType]?.splice(index, 1, { players: element });
        return application;
      case 'Filter Bar':
        application.screens[testIndex][screenType]?.splice(index, 1, { filterbars: element });
        return application;
      case 'Search Bar':
        application.screens[testIndex][screenType]?.splice(index, 1, { searchbars: element });
        return application;
      case 'DVR Progress':
        application.screens[testIndex][screenType]?.splice(index, 1, { dvrprogress: element });
        return application;
      case 'Logo & Time':
        application.screens[testIndex][screenType]?.splice(index, 1, { logotime: element });
        return application;
      case 'Logo & Wide':
        application.screens[testIndex][screenType]?.splice(index, 1, { logowide: element });
        return application;
      case 'Menu':
        application.screens[testIndex][screenType]?.splice(index, 1, { menus: element });
        return application;
      case 'Webview':
        application.screens[testIndex][screenType]?.splice(index, 1, { webview: element });
        return application;
      case 'Square Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { square_rails: element });
        return application;
      case 'Top10 Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { top10_rails: element });
        return application;
      case 'Genre Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { genre_rails: element });
        return application;
      case 'Round Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { round_rails: element });
        return application;
      case 'Actors Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { actors_rails: element });
        return application;
      case 'Filter Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { filter_rails: element });
        return application;
      case 'Rectangle Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { rectangle_rails: element });
        return application;
      case 'Banner Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { banner_rails: element });
        return application;
      case 'Tile Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { tile_rails: element });
        return application;
      case 'Long Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { long_rails: element });
        return application;
      case 'Wide Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { widescreen_rails: element });
        return application;
      case 'Player Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_rails: element });
        return application;
      case 'Morphing Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { morphing_rails: element });
        return application;
      case 'Menu Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { menu_rails: element });
        return application;
      case 'Square Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { square_grids: element });
        return application;
      case 'Rectangle Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { rectangle_grids: element });
        return application;
      case 'Tile Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { tile_grids: element });
        return application;
      case 'Long Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { long_grids: element });
        return application;
      case 'Wide Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { widescreen_grids: element });
        return application;
      case 'Player Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_grids: element });
        return application;
      case 'Video Details Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Bottom':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Top':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Centered':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Minimal':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Audio Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_audiostandard: {
            ...element,
            type: 'Audio Standard',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_audio_standard: {
            ...element,
            type: 'Audio Details Standard',
          },
        });
        return application;
      case 'Audio Details Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_audiostandard: {
            ...element,
            type: 'Audio Standard',
          },
        });
        application.screens[testIndex][screenType]?.splice(index + 1, 1, {
          details_audio_standard: {
            ...element,
            type: 'Audio Details Standard',
          },
        });
        return application;
      case 'Guide Horizontal':
        application.screens[testIndex][screenType]?.splice(index, 1, { guide_horizontal: element });
        return application;
      case 'Download List':
        application.screens[testIndex][screenType]?.splice(index, 1, { list_downloads: element });
        return application;
      case 'Player Video':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_video: element });
        return application;
      case 'Player Audio':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_audio: element });
        return application;
      default:
        return application;
    }
  };
  static convertElementToApplication = (type: any, application: any, pageName: any, element: any, index: any, screenType: any) => {
    var testIndex = application.screens.findIndex((s: any) => s.type == pageName);
    switch (type) {
      case 'Survey':
        application.screens[testIndex][screenType]?.splice(index, 1, { survey: element });
        return application;
      case 'Ads':
        application.screens[testIndex][screenType]?.splice(index, 1, { ads: element });
        return application;
      case 'KaiAds':
        application.screens[testIndex][screenType]?.splice(index, 1, { kaiads: element });
        return application;
      case 'KaiFS':
        application.screens[testIndex][screenType]?.splice(index, 1, { kaifullscreen: element });
        return application;
      case 'CastoolaAds':
        application.screens[testIndex][screenType]?.splice(index, 1, { castoolaads: element });
        return application;
      case 'CastoolaFS':
        application.screens[testIndex][screenType]?.splice(index, 1, { castoolafullscreen: element });
        return application;
      case 'Messages':
        application.screens[testIndex][screenType]?.splice(index, 1, { messages: element });
        return application;
      case 'OTA':
        application.screens[testIndex][screenType]?.splice(index, 1, { otas: element });
        return application;
      case 'Single':
        application.screens[testIndex][screenType]?.splice(index, 1, { singles: element });
        return application;
      case 'Slider':
        application.screens[testIndex][screenType]?.splice(index, 1, { sliders: element });
        return application;
      case 'Player':
        application.screens[testIndex][screenType]?.splice(index, 1, { players: element });
        return application;
      case 'Filter Bar':
        application.screens[testIndex][screenType]?.splice(index, 1, { filterbars: element });
        return application;
      case 'Search Bar':
        application.screens[testIndex][screenType]?.splice(index, 1, { searchbars: element });
        return application;
      case 'DVR Progress':
        application.screens[testIndex][screenType]?.splice(index, 1, { dvrprogress: element });
        return application;
      case 'Logo & Time':
        application.screens[testIndex][screenType]?.splice(index, 1, { logotime: element });
        return application;
      case 'Logo & Wide':
        application.screens[testIndex][screenType]?.splice(index, 1, { logowide: element });
        return application;
      case 'Menu':
        application.screens[testIndex][screenType]?.splice(index, 1, { menus: element });
        return application;
      case 'Webview':
        application.screens[testIndex][screenType]?.splice(index, 1, { webview: element });
        return application;
      case 'Square Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { square_rails: element });
        return application;
      case 'Top10 Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { top10_rails: element });
        return application;
      case 'Genre Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { genre_rails: element });
        return application;
      case 'Round Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { round_rails: element });
        return application;
      case 'Actors Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { actors_rails: element });
        return application;
      case 'Filter Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { filter_rails: element });
        return application;
      case 'Rectangle Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { rectangle_rails: element });
        return application;
      case 'Banner Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { banner_rails: element });
        return application;
      case 'Tile Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { tile_rails: element });
        return application;
      case 'Long Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { long_rails: element });
        return application;
      case 'Wide Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { widescreen_rails: element });
        return application;
      case 'Player Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_rails: element });
        return application;
      case 'Morphing Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { morphing_rails: element });
        return application;
      case 'Menu Rails':
        application.screens[testIndex][screenType]?.splice(index, 1, { menu_rails: element });
        return application;
      case 'Square Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { square_grids: element });
        return application;
      case 'Rectangle Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { rectangle_grids: element });
        return application;
      case 'Tile Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { tile_grids: element });
        return application;
      case 'Long Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { long_grids: element });
        return application;
      case 'Wide Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { widescreen_grids: element });
        return application;
      case 'Player Grid':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_grids: element });
        return application;
      case 'Video Details Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Bottom':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Top':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Centered':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Minimal':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Details Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_top: {
            ...element,
            type: 'Details Top',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_video_standard: {
            ...element,
            type: 'Video Details Standard',
          },
        });
        return application;
      case 'Audio Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_audiostandard: {
            ...element,
            type: 'Audio Standard',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_audio_standard: {
            ...element,
            type: 'Audio Details Standard',
          },
        });
        return application;
      case 'Audio Details Standard':
        application.screens[testIndex][screenType]?.splice(index, 1, {
          details_audiostandard: {
            ...element,
            type: 'Audio Standard',
          },
        });
        application.screens[testIndex][screenType]?.push({
          details_audio_standard: {
            ...element,
            type: 'Audio Details Standard',
          },
        });
        return application;
      case 'Guide Horizontal':
        application.screens[testIndex][screenType]?.splice(index, 1, { guide_horizontal: element });
        return application;
      case 'Download List':
        application.screens[testIndex][screenType]?.splice(index, 1, { list_downloads: element });
        return application;
      case 'Player Video':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_video: element });
        return application;
      case 'Player Audio':
        application.screens[testIndex][screenType]?.splice(index, 1, { player_audio: element });
        return application;
      default:
        return application;
    }
  };

  static getHeaderCurrentCount = (page: any) => {
    if (page.header == undefined) {
      return 1;
    } else if (page.header.length == 1) {
      return 0;
    } else if (page.header.length == 2) {
      return 0;
    } else {
      return 1;
    }
  };
  static getHeaderCount = (page: any) => {
    const { t } = useTranslation();
    if (page.header == undefined) {
      return t('2-2-slots-free');
    } else if (page.header.length == 1) {
      return t('1-2-slots-free');
    } else if (page.header.length == 2) {
      return t('0-2-slots-free');
    }
  };
  static getHeaderHeight = (page: any) => {
    if (page.header == undefined) {
      return 132;
    } else if (page.header.length == 1) {
      return 132;
    } else if (page.header.length == 2) {
      return 240;
    } else {
      return 132;
    }
  };
  static getHeroCurrentCount = (page: any) => {
    if (page.hero == undefined) {
      return 1;
    } else if (page.hero.length == 1) {
      return 0;
    } else {
      return 0;
    }
  };
  static getHeroCount = (page: any) => {
    const { t } = useTranslation();
    if (page.hero == undefined) {
      return t('1-1-slots-free');
    } else if (page.hero.length == 1) {
      return t('0-1-slots-free');
    }
  };
  static getHeroHeight = (page: any) => {
    if (page.hero == undefined) {
      return 134;
    } else if (page.hero.length == 1) {
      return 134;
    } else if (page.hero.length == 1) {
      return 240;
    } else {
      return 134;
    }
  };
  static getFooterCurrentCount = (page: any) => {
    if (page.footer == undefined) {
      return 1;
    } else if (page.footer.length == 1) {
      return 0;
    } else {
      return 1;
    }
  };
  static getFooterCount = (page: any) => {
    const { t } = useTranslation();
    if (page.footer == undefined) {
      return t('2-2-slots-free');
    } else if (page.header.length == 1) {
      return t('1-2-slots-free');
    } else if (page.header.length == 2) {
      return t('0-2-slots-free');
    }
  };
  static getFooterHeight = (page: any) => {
    if (page.footer == undefined) {
      return 132;
    } else if (page.footer.length == 1) {
      return 132;
    } else if (page.footer.length == 2) {
      return 240;
    } else {
      return 132;
    }
  };

  static getImage = (type: any) => {
    switch (type) {
      case 'Ads':
        return Add_Banner_New;
      case 'KaiAds':
        return Add_Banner_New;
      case 'KaiFullscreen':
        return Add_Fullscreen_New;
      case 'CastoolaAds':
        return Add_Banner_New;
      case 'CastoolaFullscreen':
        return Add_Fullscreen_New;
      case 'Logo & Time':
        return Logo_Time_New;
      case 'Logo & Wide':
        return Logo_Wide_New;
      case 'DVR Progress':
        return Dvr_Progess_New;
      case 'Filter Bar':
        return Search_Bar_New;
      case 'Search Bar':
        return Search_Bar_New;
      case 'Messages':
        return Promotion_New;
      case 'OTA':
        return Message_New;
      case 'Audio Player':
        return Player_Audio_New;
      case 'Video Player':
        return Player_Video_New;
      case 'Audio Details':
        return Details_Audio_New;
      case 'Video Details':
        return Details_Video_New;
      case 'Single':
        return Single_Hero_New;
      case 'Slider':
        return Slider_Hero_New;
      case 'Player':
        return Player_Hero_New;
      case 'Menu':
        return MenuDropdown_New;
      case 'Square Rails':
        return Square_Rails_New;
      case 'Top10 Rails':
        return Top10_Rails_New;
      case 'Genre Rails':
        return Genre_Rails_New;
      case 'Menu Rails':
        return Menu_Rails_New;
      case 'Wide Rails':
        return Wide_Rails_New;
      case 'Rectangle Rails':
        return Rectangle_Rails_New;
      case 'Banner Rails':
        return Banner_Rails_New;
      case 'Tile Rails':
        return Tile_Rails_New;
      case 'Long Rails':
        return Long_Rails_New;
      case 'Round Rails':
        return Round_Rails_New;
      case 'Actors Rails':
        return Actors_Rails_New;
      case 'Filter Rails':
        return Filter_Rails_New;
      case 'Player Rails':
        return Player_Rails_New;
      case 'Morphing Rails':
        return Morphing_Rails_New;
      case 'TV Guide':
        return TV_Guide_New;
      case 'Infobar':
        return Message_New;
      case 'Webview':
        return Webview_New;
      case 'Download List':
        return Download_List_New;
      case 'Survey':
        return Survey_New;
      case 'Square Grid':
        return SquareGrid;
      case 'Wide Grid':
        return WideGrid;
      case 'Long Grid':
        return LongGrid;
      case 'Player Grid':
        return PlayerGrid;
      case 'Tile Grid':
        return TileGrid;
      case 'Rectangle Grid':
        return RectangleGrid;

      default:
        return Hero_New;
    }
  };
  static getVersion = (type: any) => {
    switch (type) {
      case 'Ads':
        return 'v1.0.2';
      case 'KaiAds':
        return 'v1.0.2';
      case 'KaiFS':
        return 'v1.0.2';
      case 'CastoolaAds':
        return 'v1.0.2';
      case 'CastoolaFS':
        return 'v1.0.2';
      case 'Logo & Time':
        return 'v1.0.0';
      case 'Logo & Wide':
        return 'v1.0.0';
      case 'DVR Progress':
        return 'v1.0.0';
      case 'Filter Bar':
        return 'v1.0.0';
      case 'Search Bar':
        return 'v1.0.0';
      case 'Messages':
        return 'v1.0.0';
      case 'OTA':
        return 'v1.0.0';
      case 'Audio Player':
        return 'v3.0.5';
      case 'Video Player':
        return 'v3.0.5';
      case 'Audio Details':
        return 'v3.0.2';
      case 'Video Details':
        return 'v3.0.2';
      case 'Single':
        return 'v3.0.0';
      case 'Slider':
        return 'v3.0.0';
      case 'Player':
        return 'v2.0.3';
      case 'Menu':
        return 'v1.0.4';
      case 'Square Rails':
        return 'v1.0.0';
      case 'Top10 Rails':
        return 'v1.0.9';
      case 'Genre Rails':
        return 'v1.0.0';
      case 'Menu Rails':
        return 'v1.0.7';
      case 'Wide Rails':
        return 'v1.0.1';
      case 'Rectangle Rails':
        return 'v1.0.1';
      case 'Banner Rails':
        return 'v1.0.0';
      case 'Tile Rails':
        return 'v1.0.1';
      case 'Long Rails':
        return 'v1.0.1';
      case 'Round Rails':
        return 'v1.0.3';
      case 'Actors Rails':
        return 'v1.0.0';
      case 'Filter Rails':
        return 'v1.0.0';
      case 'Player Rails':
        return 'v1.0.6';
      case 'Morphing Rails':
        return 'v1.0.8';
      case 'TV Guide':
        return 'v2.0.5';
      case 'Webview':
        return 'v1.0.0';
      case 'Download List':
        return 'v1.0.0';
      case 'Survey':
        return 'v1.0.0';
      case 'Square Grid':
        return 'v1.0.7';
      case 'Rectangle Grid':
        return 'v1.0.7';
      case 'Long Grid':
        return 'v1.0.7';
      case 'Player Grid':
        return 'v1.0.9';
      case 'Wide Grid':
        return 'v1.0.7';
      case 'Tile Grid':
        return 'v1.0.7';
      default:
        return 'v1.0.0';
    }
  };
  static getTitle = (type: any) => {
    const { t } = useTranslation();
    switch (type) {
      case 'KaiAds':
        return t('kaios-banner-ad');
      case 'KaiFullscreen':
        return t('kaios-fullscreen-ad');
      case 'CastoolaAds':
        return t('castoola-banner-ad');
      case 'CastoolaFullscreen':
        return t('castoola-fullscreen-ad');
      default:
        return type;
    }
  };
  static getDescription = (type: any) => {
    const { t } = useTranslation();
    switch (type) {
      case 'Ads':
        return t('banner-advertisement-for-all-devices-served-from-the-our-ad-servers');
      case 'KaiAds':
        return t('banner-advertisement-for-kaios-devices-served-from-the-kaios-ad-servers');
      case 'KaiFullscreen':
        return t('fullscreen-advertisement-for-kaios-devices-served-from-the-kaios-ad-servers');
      case 'CastoolaAds':
        return t('banner-advertisement-for-all-devices-served-from-the-castoola-ad-servers-you-need-to-have-a-contract-with-castoola-to-run-their-ads-and-make-extra-revenue');
      case 'CastoolaFullscreen':
        return t('fullscreen-advertisement-for-all-devices-served-from-the-castoola-ad-servers-you-need-to-have-a-contract-with-castoola-to-run-their-ads-and-make-extra-revenue');
      case 'Logo & Time':
        return t('show-logo-and-time-on-all-devices-except-for-mobile-devices');
      case 'Logo & Wide':
        return t('this-component-displays-a-logo-across-the-entire-screen-of-the-app-helpful-for-supporting-two-brand-logos-or-powered-by-branding');
      case 'DVR Progress':
        return t('this-component-displays-how-much-storage-you-are-using-for-your-recordings');
      case 'Filter Bar':
        return t('filter-your-content-by-using-the-keyboard-search');
      case 'Search Bar':
        return t('using-the-search-feature-you-can-quickly-search-across-the-entire-content-catalogue');
      case 'Messages':
        return t('promote-new-features-or-content-by-placing-a-message-on-the-screen');
      case 'OTA':
        return t('publish-a-message-on-the-screen-announcing-the-new-version-of-the-app');
      case 'Audio Player':
        return t('this-component-allows-you-to-play-audio-files-such-as-radios-music-and-podcasts-from-your-apps');
      case 'Video Player':
        return t('the-component-for-playing-video-files-like-channels-cams-recordings-replaytv-movies-series-courses-and-shorts');
      case 'Audio Details':
        return t('a-component-for-displaying-audio-based-content-details-managing-favorites-watchlists-and-ppv-options-if-required');
      case 'Video Details':
        return t('component-that-showcases-video-content-details-allows-you-to-manage-your-favorites-watchlist-and-if-needed-your-ppv-subscriptions');
      case 'Single':
        return t('this-component-is-based-on-disney-and-is-an-automated-slider-for-your-content-including-channels-movies-and-series');
      case 'Slider':
        return t('this-component-is-an-automated-hero-slider-that-displays-content-such-as-channels-movies-and-series');
      case 'Player':
        return t('inspired-by-netflix-this-component-plays-content-from-the-rails-rows-that-you-have-linked-such-as-channels-movies-and-series');
      case 'Menu':
        return t('this-component-filters-your-content-by-category-on-mobile-it-renders-a-dropdown-menu-on-desktop-it-renders-a-scrollable-menu');
      case 'Square Rails':
        return t('this-component-shows-a-rails-with-content-for-channels-cams-radio-music-and-podcasts-with-the-ability-to-display-favorites-watchlists-content-lists-and-more');
      case 'Top10 Rails':
        return t('the-component-shows-a-rail-with-content-displayed-as-a-top-10-with-numbers-and-can-be-positioned-anywhere-in-the-app-on-any-page-it-can-be-filled-with-favorites-watchlists-content-lists-and-more');
      case 'Genre Rails':
        return t('component-that-shows-a-rail-with-categories-from-a-chosen-content-type-rails-can-be-placed-anywhere-in-the-app-on-any-page-and-can-contain-favorites-watchlists-content-lists-and-more');
      case 'Menu Rails':
        return t('this-component-shows-a-rail-with-menu-items-you-can-use-it-for-example-to-display-a-quickmenu-for-favorites-watchlists-content-lists-and-other-types-of-lists');
      case 'Wide Rails':
        return t('this-component-shows-a-rail-with-content-for-channels-cams-series-or-courses-that-can-be-placed-anywhere-in-the-app-on-any-page-and-filled-with-favorites-watchlists-or-content-lists');
      case 'Rectangle Rails':
        return t('this-component-shows-a-rail-with-content-for-movies-rails-can-be-placed-anywhere-in-the-application-and-can-contain-lists-of-favorites-watchlists-and-content');
      case 'Banner Rails':
        return t('this-component-shows-a-rail-with-a-widescreen-for-movies-and-series-rails-can-be-placed-anywhere-in-the-application-and-can-contain-lists-of-content');
      case 'Tile Rails':
        return t('this-component-displays-a-rails-filled-with-content-for-channels-cams-radio-music-and-podcasts-rails-can-be-placed-anywhere-in-the-app-and-can-display-favorites-watchlists-and-content-lists');
      case 'Long Rails':
        return t('this-component-shows-a-rail-with-content-for-channels-which-can-be-placed-anywhere-in-the-app-and-filled-with-favorites-watchlists-content-lists-and-many-other-types');
      case 'Round Rails':
        return t('this-component-displays-a-rails-filled-with-content-for-channels-cams-radio-music-and-podcasts-rails-can-be-placed-anywhere-in-the-app-and-can-display-favorites-watchlists-and-content-lists');
      case 'Actors Rails':
        return t('this-component-displays-a-rails-filled-with-actors-from-movies-and-series-rails-can-be-placed-anywhere-in-the-app');
      case 'Filter Rails':
        return t('this-component-lets-you-create-a-rail-with-filters-for-your-your-favorite-programs-it-scans-the-tv-guide-data-based-on-your-preferences-and-displays-the-results');
      case 'Player Rails':
        return t('this-component-shows-a-rail-that-contains-content-for-channels-movies-and-series-in-the-app-where-it-can-be-displayed-along-with-favorites-watchlists-content-lists-and-many-more');
      case 'Morphing Rails':
        return t('the-component-shows-a-rail-with-movie-content-which-can-be-placed-anywhere-in-the-application-on-any-page-along-with-favorites-watchlists-content-lists-and-other-types-of-content');
      case 'TV Guide':
        return t('fast-cross-platform-tv-guide-component-that-offers-features-such-as-date-selection-and-category-selection-and-a-special-vertical-version-for-mobile-devices');
      case 'Infobar':
        return t('provide-information-about-your-service-on-the-screen-so-that-people-can-learn-more-about-it');
      case 'Webview':
        return t('in-this-component-you-can-embed-an-iframe-or-webview-and-load-content-via-a-url-this-component-is-compatible-with-mobile-tablets-and-browsers');
      case 'Download List':
        return t('the-ready-to-use-component-lets-you-manage-your-downloaded-content-including-playing-deleting-and-resuming-downloads');
      case 'Survey':
        return t('get-a-detailed-understanding-of-how-your-customers-feel-about-your-service-by-conducting-a-customer-survey');
      case 'Square Grid':
        return t('this-component-displays-a-grid-filled-with-content-for-channels-cams-radio-music-and-podcasts-with-the-layout-feature-you-can-set-the-number-of-columns-per-device-type');
      case 'Rectangle Grid':
        return t('this-component-displays-a-grid-filled-with-content-for-movies-with-the-layout-feature-you-can-set-the-number-of-columns-per-device-type');
      case 'Wide Grid':
        return t('this-component-displays-a-grid-filled-with-content-for-channels-cams-series-and-courses-with-the-layout-feature-you-can-set-the-number-of-columns-per-device-type');
      case 'Long Grid':
        return t('this-component-displays-a-grid-filled-with-content-for-channels-and-cams-with-the-layout-feature-you-can-set-the-number-of-columns-per-device-type');
      case 'Player Grid':
        return t('this-component-displays-a-grid-filled-with-content-for-channels-cams-with-the-layout-feature-you-can-set-the-number-of-columns-per-device-type');
      case 'Tile Grid':
        return t('this-component-displays-a-grid-filled-with-content-for-channels-cams-radio-music-and-podcasts-with-the-layout-feature-you-can-set-the-number-of-columns-per-device-type');
    }
  };
  static getComponent = (element, application) => {
    switch (element.type) {
      case 'Ads':
        return <Ad data={element} application={application}></Ad>;
      case 'KaiAds':
        return <KaiAd data={element} application={application}></KaiAd>;
      case 'KaiFullscreen':
        return <KaiFullscreen data={element} application={application}></KaiFullscreen>;
      case 'CastoolaAds':
        return <CastoolaAd data={element} application={application}></CastoolaAd>;
      case 'CastoolaFullscreen':
        return <CastoolaFullscreen data={element} application={application}></CastoolaFullscreen>;
      case 'Logo & Time':
        return <LogoTime data={element} application={application}></LogoTime>;
      case 'Logo & Wide':
        return <LogoWide data={element} application={application}></LogoWide>;
      case 'Webview':
        return <Webview data={element} application={application}></Webview>;
      case 'DVR Progress':
        return <DvrProgress data={element} application={application}></DvrProgress>;
      case 'Filter Bar':
        return <FilterBar data={element} application={application}></FilterBar>;
      case 'Search Bar':
        return <SearchBar data={element} application={application}></SearchBar>;
      case 'Messages':
        return <MessagePromo data={element} application={application}></MessagePromo>;
      case 'OTA':
        return <OtaPromo data={element} application={application}></OtaPromo>;
      case 'Audio Player':
        return <AudioPlayer data={element} application={application}></AudioPlayer>;
      case 'Video Player':
        return <VideoPlayer data={element} application={application}></VideoPlayer>;
      case 'Single':
        return <Single data={element} application={application}></Single>;
      case 'Slider':
        return <Slider data={element} application={application}></Slider>;
      case 'Player':
        return <Player data={element} application={application}></Player>;
      case 'Menu':
        return <MenuBar data={element} application={application}></MenuBar>;
      case 'Square Grid':
        return <SquareGrids data={element} application={application}></SquareGrids>;
      case 'Wide Grid':
        return <WideGrids data={element} application={application}></WideGrids>;
      case 'Player Grid':
        return <PlayerGrids data={element} application={application}></PlayerGrids>;
      case 'Rectangle Grid':
        return <RectangleGrids data={element} application={application}></RectangleGrids>;
      case 'Tile Grid':
        return <TileGrids data={element} application={application}></TileGrids>;
      case 'Long Grid':
        return <LongGrids data={element} application={application}></LongGrids>;
      case 'Square Rails':
        return <SquareRails data={element} application={application}></SquareRails>;
      case 'Top10 Rails':
        return <Top10Rails data={element} application={application}></Top10Rails>;
      case 'Genre Rails':
        return <GenreRails data={element} application={application}></GenreRails>;
      case 'Menu Rails':
        return <MenuRails data={element} application={application}></MenuRails>;
      case 'Round Rails':
        return <RoundRails data={element} application={application}></RoundRails>;
      case 'Actors Rails':
        return <ActorsRails data={element} application={application}></ActorsRails>;
      case 'Filter Rails':
        return <FilterRails data={element} application={application}></FilterRails>;
      case 'Wide Rails':
        return <WideRails data={element} application={application}></WideRails>;
      case 'Morphing Rails':
        return <MorphingRails data={element} application={application}></MorphingRails>;
      case 'Player Rails':
        return <PlayerRails data={element} application={application}></PlayerRails>;
      case 'Rectangle Rails':
        return <RectangleRails data={element} application={application}></RectangleRails>;
      case 'Banner Rails':
        return <BannerRails data={element} application={application}></BannerRails>;
      case 'Tile Rails':
        return <TileRails data={element} application={application}></TileRails>;
      case 'Long Rails':
        return <LongRails data={element} application={application}></LongRails>;
      case 'Guide Horizontal':
        return <TvGuide data={element} application={application}></TvGuide>;
      case 'Infobar':
        return <InfoPromo data={element} application={application}></InfoPromo>;
      case 'Video Details Standard':
        return <DetailsVideoStandard data={element} application={application}></DetailsVideoStandard>;
      case 'Audio Details Standard':
        return <DetailsAudioStandard data={element} application={application}></DetailsAudioStandard>;
      case 'Download List':
        return <DownloadList data={element} application={application}></DownloadList>;
      case 'Survey':
        return <Survey data={element} application={application}></Survey>;
    }
  };

  static getAdsMessageStyling = () => {
    return {
      ut_start: moment().unix(),
      ut_end: moment().add(99, 'year').unix(),
      general: {
        background_color: '',
        border_radius: 0,
        border_width: 0,
        border_color: '',
        margin_top: 0,
        margin_left: 0,
        margin_bottom: 0,
        margin_right: 0,
      },
    };
  };
  static getHeroStyling = () => {
    return {
      components: {
        button: {
          _id: '',
        },
        modal: {
          _id: '',
        },
      },
      capped: false,
      content: {
        type: '',
        list: undefined,
      },
      texts: {
        title_color: '',
        title_size: '',
        title_bold: false,
        subtext_color: '',
        subtext_size: '',
        subtext_bold: false,
        show_title: false,
        show_subtext: false,
      },
    };
  };
  static getNavigationStyling = () => {
    return {
      orientation: 'horizontal',

      texts: {
        title_color: '#ffffff',
        title_size: 'Normal',
        title_bold: false,
        subtext_color: '#ffffff',
        subtext_size: 'Normal',
        subtext_bold: false,
        show_title: true,
        show_subtext: true,
      },
      components: {
        button: {
          _id: '',
        },
        dropdown: {
          _id: '',
        },
      },
      general: {
        background_color: '',
        border_radius: 0,
        border_width: 0,
        border_color: '',
        margin_top: 0,
        margin_left: 0,
        margin_bottom: 0,
        margin_right: 0,
      },
    };
  };
  static getRailsStyling = () => {
    return {
      content: {
        type: '',
        link_to_hero: false,
        play_directly: false,
        press_and_hold: false,
        enable_deeplinking: undefined,
        press_and_hold_message: false,
        list: undefined,
      },
      ribbon: {
        show_ribbon: false,
        border_radius: 0,
        align: '',
        background_color: '',
        title_color: '',
        type: '',
        title: [],
      },
      placement: {
        border_radius: 0,
        margin_top: 0,
        margin_left: 0,
        margin_bottom: 0,
        margin_right: 0,
        padding: 0,
        height: 100,
        background_color: '',
        title_color: '',
        title_size: '',
        title_bold: false,
        show_title: false,
      },
      texts: {
        title_color: '',
        title_size: '',
        title_bold: false,
        subtext_color: '',
        subtext_size: '',
        subtext_bold: false,
        subsubtext_color: '',
        subsubtext_size: '',
        subsubtext_bold: false,
        show_subsubtext: false,
        show_title: false,
        show_subtext: false,
        placement_text: '',
      },
      scheduler: {
        start: 0,
        end: 0,
      },
      components: {
        button: {
          _id: '',
        },
      },
    };
  };
  static getDetailsStyling = () => {
    return {
      texts: {
        title_color: '#ffffff',
        title_size: 'ExtraExtraLarge',
        title_bold: true,
        subtext_color: '#999999',
        subtext_size: 'Large',
        subtext_bold: true,
        show_title: true,
        show_subtext: true,
        metatext_color: '#ffffff',
        metatext_size: 'Normal',
        metatext_bold: false,

        casttext_color: '#ffffff',
        casttext_size: 'Normal',
        casttext_bold: false,

        show_meta: true,
        show_cast: true,
      },
      components: {
        button: {
          _id: '',
        },
        modal: {
          _id: '',
        },
        dropdown: {
          _id: '',
        },
      },
    };
  };
  static getMiscStyling = () => {
    return {
      general: {
        background_color: '',
        border_radius: 0,
        border_width: 0,
        border_color: '',
        margin_top: 0,
        margin_left: 0,
        margin_bottom: 0,
        margin_right: 0,
      },
      texts: {
        title_color: '',
        title_size: '',
        title_bold: false,
        subtext_color: '',
        subtext_size: '',
        subtext_bold: false,
        show_title: false,
        show_subtext: false,
        placement_text: '',
      },
    };
  };
  static getListStyling = () => {
    return {
      texts: {
        title_color: '#ffffff',
        title_size: 'ExtraExtraLarge',
        title_bold: true,
        subtext_color: '#999999',
        subtext_size: 'Large',
        subtext_bold: true,
        show_subtext: true,
        show_title: true,
        placement_text: '',
      },
      components: {
        button: {
          _id: '',
        },
        dropdown: {
          _id: '',
        },
      },
      placement: {
        border_radius: 0,
        margin_top: 0,
        margin_left: 0,
        margin_bottom: 0,
        margin_right: 0,
        padding: 0,
        height: 0,
        background_color: '',
        title_color: '',
        title_size: '',
        title_bold: false,
        show_title: false,
      },
    };
  };
  static getTvGuideStyling = () => {
    return {
      infobar: {
        general: {
          background_color: '#000000',
        },

        texts: {
          title_color: '#ffffff',
          title_size: 'ExtraExtraLarge',
          title_bold: false,
          subtext_color: '#999999',
          subtext_size: 'Large',
          subtext_bold: false,
          show_title: true,
          show_subtext: true,
        },
      },
      tvguide: {
        backgroundColor: '#000000',
        margin_top: 0,
        margin_bottom: 0,
        margin_left: 0,
        margin_right: 0,
        timeline: {
          alignItems: 'left',
          marginHorizontal: 10,
          fontSize: 'Normal',
          backgroundColor: '#333333',
          color: '#ffffff',
          borderColor: '#000000',
          padding: 0,
          borderRadius: 0,
          borderLeftWidth: 2,
          borderTopWidth: 0,
          borderBottomWidth: 0,
          borderRightWidth: 0,
        },
        cell: {
          backgroundColor: '#000000',
          borderColor: '#333333',
          borderWidth: 0,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          titleColor: '#ffffff',
          timeColor: '#999999',
          fontSize: 'Normal',
          borderRadius: 0,
          activeColor: 'red',
          padding: 2,
        },
        cellLive: {
          backgroundColor: '#555555',
          borderColor: '#555555',
          borderWidth: 0,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          color: '#ffffff',
          fontSize: 'Normal',
          borderRadius: 0,
          activeColor: 'red',
          padding: 2,
        },
        daySelector: {
          backgroundColor: '',
          color: '',
          fontSize: '',
        },
        channelLine: {
          textColor: '#ffffff',
          backgroundColor: '#333333',
          paddingLeft: 0,
          fontSize: 'Normal',
          borderWidth: 0,
          borderLeftWidth: 0,
          borderTopWidth: 0,
          borderBottomWidth: 2,
          borderRightWidth: 0,
        },
        marker: {
          backgroundColor: '#000000',
          width: 2,
        },
      },
      components: {
        dropdown: {
          _id: '',
        },
        modal: {
          _id: '',
        },
        button: {
          _id: '',
        },
      },
    };
  };
  static getPlayerStyling = () => {
    return {
      texts: {
        title_color: '#ffffff',
        title_size: 'ExtraLarge', //Small, Normal, Large, Extra Large
        title_bold: false,

        subtext_color: '#ffffff',
        subtext_size: 'Large', //Small, Normal, Large, Extra Large
        subtext_bold: false,

        show_title: true,
        show_subtext: true,
      },

      list: {
        general: {
          background_color: '#00000060',
        },
        texts: {
          title_color: '#ffffff',
          title_size: 'ExtraLarge',
          title_bold: false,
          subtext_color: '#ffffff',
          subtext_size: 'Large',
          subtext_bold: false,
          show_title: true,
          show_subtext: true,
        },
      },
      components: {
        button: {
          _id: '',
        },
        input: {
          _id: '',
        },
      },
    };
  };
  static getGridStyling = () => {
    return {
      layout: {
        columns_mobile: 1,
        columns_tablet: 3,
        columns_browser: 4,
        columns_television: 3,
      },

      placement: {
        border_radius: 0,

        margin_top: 0,
        margin_left: 0,
        margin_bottom: 0,
        margin_right: 0,

        padding: 0,
        height: 0,
        background_color: 'transparent',

        title_color: '#ffffff',
        title_size: 'Normal', //Small, Normal, Large, Extra Large
        title_bold: false,

        show_title: true,
      },

      ribbon: {
        show_ribbon: false,
        border_radius: 0,
        align: '',
        background_color: '',
        title_color: '',
        type: '',
        title: [],
      },

      texts: {
        title_color: '#ffffff',
        title_size: 'Normal', //Small, Normal, Large, Extra Large
        title_bold: false,

        subtext_color: '#ffffff',
        subtext_size: 'Normal', //Small, Normal, Large, Extra Large
        subtext_bold: false,

        subsubtext_color: '#ffffff',
        subsubtext_size: 'Normal',
        subsubtext_bold: false,

        subsubsubtext_color: '#ffffff',
        subsubsubtext_size: 'Normal',
        subsubsubtext_bold: false,

        show_subsubsubtext: true,
        show_subsubtext: true,
        show_title: true,
        show_subtext: true,

        placement_text: 'Below', //on image, below image
      },
      components: {
        button: {
          _id: '',
        },
      },
    };
  };

  static getHeaderContent = () => {
    return [
      {
        name: 'Ads & Messages',
        items: [
          {
            type: 'Ads',
            ...Utils.getAdsMessageStyling,
            image_url: '',
          },
          {
            type: 'KaiAds',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            app_name: '',
            slot_name: '',
          },
          {
            type: 'CastoolaAds',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            occurrence: '',
            single: true,
          },
          {
            type: 'Message',
            ...Utils.getAdsMessageStyling,
            image_url: '',
          },
          {
            type: 'OTA',
            ...Utils.getAdsMessageStyling,
          },
        ],
      },
      {
        name: 'Miscellaneous',
        items: [
          {
            type: 'Logo & Time',
            ...Utils.getMiscStyling(),
          },
          {
            type: 'Logo & Wide',
            ...Utils.getMiscStyling(),
            portrait: '',
            landscape: '',
          },
          {
            type: 'DVR Progress',
            ...Utils.getMiscStyling(),
          },
          {
            type: 'Filter Bar',
            ...Utils.getMiscStyling(),
            components: {
              button: {
                _id: '',
              },
              input: {
                _id: '',
              },
              keyboard: {
                _id: '',
              },
            },
          },
          {
            type: 'Search Bar',
            ...Utils.getMiscStyling(),
            components: {
              button: {
                _id: '',
              },
              input: {
                _id: '',
              },
              keyboard: {
                _id: '',
              },
            },
          },
        ],
      },
      {
        name: 'Navigation',
        items: [
          {
            type: 'Menu',
            ...Utils.getNavigationStyling(),
          },
        ],
      },
    ];
  };
  static getHeroContent = () => {
    return [
      {
        name: "Hero's",
        items: [
          {
            type: 'Slider',
            ...Utils.getHeroStyling(),
          },
          {
            type: 'Single',
            ...Utils.getHeroStyling(),
          },
          // {
          //   type: 'Player',
          //   ...Utils.getHeroStyling(),
          // },
        ],
      },
      {
        name: 'Details',
        items: [
          {
            type: 'Video Details Standard',
            ...Utils.getDetailsStyling(),
          },
          {
            type: 'Audio Details Standard',
            ...Utils.getDetailsStyling(),
          },
        ],
      },
    ];
  };
  static getContentContent = () => {
    return [
      {
        name: 'Ads & Messages',
        items: [
          {
            type: 'Ads',
            ...Utils.getAdsMessageStyling,
            image_url: '',
          },
          {
            type: 'KaiAds',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            app_name: '',
            slot_name: '',
          },
          {
            type: 'KaiFS',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            app_name: '',
            slot_name: '',
          },
          {
            type: 'CastoolaAds',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            occurrence: '',
            single: true,
          },
          {
            type: 'CastoolaFS',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            occurrence: '',
            single: true,
          },
          {
            type: 'Messages',
            ...Utils.getAdsMessageStyling,
            image_url: '',
          },
          {
            type: 'OTA',
            ...Utils.getAdsMessageStyling,
          },
        ],
      },
      {
        name: 'Miscellaneous',
        items: [
          {
            type: 'Logo & Time',
            ...Utils.getMiscStyling(),
          },
          {
            type: 'Logo & Wide',
            ...Utils.getMiscStyling(),
            portrait: '',
            landscape: '',
          },
          {
            type: 'DVR Progress',
            ...Utils.getMiscStyling(),
          },
          {
            type: 'Filter Bar',
            ...Utils.getMiscStyling(),
            components: {
              button: {
                _id: '',
              },
              input: {
                _id: '',
              },
              keyboard: {
                _id: '',
              },
            },
          },
          {
            type: 'Search Bar',
            ...Utils.getMiscStyling(),
            components: {
              button: {
                _id: '',
              },
              input: {
                _id: '',
              },
              keyboard: {
                _id: '',
              },
            },
          },
          {
            type: 'Survey',
            ...Utils.getMiscStyling(),
            questions: undefined,
          },
          {
            type: 'Webview',
            ...Utils.getMiscStyling(),
            url: '',
          },
        ],
      },
      {
        name: 'Navigation',
        items: [
          {
            type: 'Menu',
            ...Utils.getNavigationStyling(),
          },
        ],
      },
      {
        name: 'Rails',
        items: [
          {
            type: 'Square Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Wide Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Rectangle Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Banner Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Long Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Genre Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Menu Rails',
            ...Utils.getRailsStyling(),
            content: {
              menu: undefined,
            },
          },
          {
            type: 'Morphing Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Player Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Top10 Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Round Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Actors Rails',
            ...Utils.getRailsStyling(),
          },
          {
            type: 'Filter Rails',
            ...Utils.getRailsStyling(),
          },
        ],
      },
      {
        name: 'Grids',
        items: [
          {
            type: 'Square Grid',
            ...Utils.getGridStyling(),
          },
          {
            type: 'Wide Grid',
            ...Utils.getGridStyling(),
          },
          {
            type: 'Tile Grid',
            ...Utils.getGridStyling(),
          },
          {
            type: 'Rectangle Grid',
            ...Utils.getGridStyling(),
          },
          {
            type: 'Long Grid',
            ...Utils.getGridStyling(),
          },
          {
            type: 'Player Grid',
            ...Utils.getGridStyling(),
          },
        ],
      },
      {
        name: 'Guides',
        items: [
          {
            type: 'TV Guide',
            ...Utils.getTvGuideStyling(),
          },
        ],
      },
      {
        name: 'Lists',
        items: [
          {
            type: 'Download List',
            ...Utils.getListStyling(),
          },
        ],
      },
      {
        name: 'Players',
        items: [
          {
            type: 'Video Player',
            ...Utils.getPlayerStyling(),
          },
          {
            type: 'Audio Player',
            ...Utils.getPlayerStyling(),
          },
        ],
      },
    ];
  };
  static getFooterContent = () => {
    return [
      {
        name: 'Ads & Messages',
        items: [
          {
            type: 'Ads',
            ...Utils.getAdsMessageStyling,
            image_url: '',
          },
          {
            type: 'KaiAds',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            app_name: '',
            slot_name: '',
          },
          {
            type: 'CastoolaAds',
            ...Utils.getAdsMessageStyling,
            publisher_id: '',
            occurrence: '',
            single: true,
          },
          {
            type: 'Message',
            ...Utils.getAdsMessageStyling,
            image_url: '',
          },
          {
            type: 'OTA',
            ...Utils.getAdsMessageStyling,
          },
        ],
      },
    ];
  };

  //temp
  // static convertToAppbuilderV2 = async (item, deployment, clientName, application, pagename, index, type) => {
  //   if (item.ads != undefined) {
  //     var _id = item.ads;
  //     let response = await appAdsService.Get_Promotions_Ads({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication('Ads', application, pagename, response.data, index, type);
  //     } else {
  //       return application;
  //     }
  //   } else if (item.logowide != undefined) {
  //     var _id = item.logowide;
  //     let response = await appLogoWideService.Get_LogoWide({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication('Logo & Wide', application, pagename, response.data, index, type);
  //     } else {
  //       return application;
  //     }
  //   } else if (item.kaiads != undefined) {
  //     var _id = item.kaiads;
  //     let response = await appKaiAdsService.Get_Promotions_KaiAds({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication('KaiAds', application, pagename, response.data, index, type);
  //     } else {
  //       return application;
  //     }
  //   } else if (item.kaifullscreen != undefined) {
  //     var _id = item.kaifullscreen;
  //     let response = await appKaiFullscreenService.Get_Promotions_KaiFullscreen({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication('KaiFullscreen', application, pagename, response.data, index, type);
  //     } else {
  //       return application;
  //     }
  //   } else if (item.castoolaads != undefined) {
  //     var _id = item.castoolaads;
  //     let response = await appCastoolaAdsService.Get_Promotions_CastoolaAds({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication('CastoolaAds', application, pagename, response.data, index, type);
  //     } else {
  //       return application;
  //     }
  //   } else if (item.castoolafullscreen != undefined) {
  //     var _id = item.castoolafullscreen;
  //     let response = await appCastoolaFullscreenService.Get_Promotions_CastoolaFullscreen({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication('CastoolaFullscreen', application, pagename, response.data, index, type);
  //     } else {
  //       return application;
  //     }
  //   } else if (item.otas != undefined) {
  //     var _id = item.otas;
  //     let response = await appOtaService.Get_Promotions_Ota({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Ota',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.messages != undefined) {
  //     var _id = item.messages;
  //     let response = await appMessageService.Get_Promotions_Messages({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Messages',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.filterbars != undefined) {
  //     var _id = item.filterbars;
  //     let response = await appFilterBarService.Get_FilterBar({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Filter Bar',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.searchbars != undefined) {
  //     var _id = item.searchbars;
  //     let response = await appSearchBarService.Get_SearchBar({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Search Bar',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.dvrprogress != undefined) {
  //     var _id = item.dvrprogress;
  //     let response = await appDvrProgressService.Get_DvrProgress({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'DVR Progress',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.logotime != undefined) {
  //     var _id = item.logotime;
  //     let response = await appLogoTimeService.Get_LogoTime({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Logo & Time',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.menus != undefined) {
  //     var _id = item.menus;
  //     let response = await appMenuService.Get_Navigation_Menu({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Menu',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   }
  //   // else if (item.dropdowns != undefined) {
  //   //   var _id = item.dropdowns;
  //   // }
  //   // else if (item.audio != undefined) {
  //   //   var _id = item.audio;
  //   //   let response = await appAudio.Get_Details_AudioStandard({
  //   //     query_params: {
  //   //       cms: deployment?.name ?? '',
  //   //       instance: clientName,
  //   //     },
  //   //     _id: _id,
  //   //   });
  //   //   if (response && response.status_code === HttpStatusCodes.OK) {
  //   //     return await Utils.convertElementToApplication(
  //   //       {
  //   //         draggableId: 'Audio_',
  //   //         destination: {
  //   //           index: 1,
  //   //         },
  //   //       },
  //   //       application,
  //   //       pagename,
  //   //       response.data,
  //   //       index,
  //   //     );
  //   //   }
  //   // }
  //   else if (item.square_rails != undefined) {
  //     var _id = item.square_rails;
  //     let response = await appSquareRailsService.Get_Square({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Square Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.menu_rails != undefined) {
  //     var _id = item.menu_rails;
  //     let response = await appMenuRailsService.Get_Menu({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Menu Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.round_rails != undefined) {
  //     var _id = item.round_rails;
  //     let response = await appRoundRailsService.Get_Round({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Round Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.rectangle_rails != undefined) {
  //     var _id = item.rectangle_rails;
  //     let response = await appRectangleRailsService.Get_Rectangle({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Rectangle Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   }else if (item.banner_rails != undefined) {
  //     var _id = item.banner_rails;
  //     let response = await appBannerRailsService.Get_Banner({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Banner Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   }
  //    else if (item.top10_rails != undefined) {
  //     var _id = item.top10_rails;
  //     let response = await appTop10RailsService.Get_Top10({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Top10 Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.widescreen_rails != undefined) {
  //     var _id = item.widescreen_rails;
  //     let response = await appWidescreenRailsService.Get_Widescreen({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Wide Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.player_rails != undefined) {
  //     var _id = item.player_rails;
  //     let response = await appPlayerRailsService.Get_Player({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Player Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.morphing_rails != undefined) {
  //     var _id = item.morphing_rails;
  //     let response = await appMorphingRailsService.Get_Morphing({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Morphing Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.long_rails != undefined) {
  //     var _id = item.long_rails;
  //     let response = await appLongRailsService.Get_Long({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Long Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.tile_rails != undefined) {
  //     var _id = item.tile_rails;
  //     let response = await appTileRailsService.Get_Tile({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Tile Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.genre_rails != undefined) {
  //     var _id = item.genre_rails;
  //     let response = await appGenreRailsService.Get_Genre({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Genre Rails',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.square_grids != undefined) {
  //     var _id = item.square_grids;
  //     let response = await appSquareGridsService.Get_Square({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Square Grid',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.rectangle_grids != undefined) {
  //     var _id = item.rectangle_grids;
  //     let response = await appRectangleGridsService.Get_Rectangle({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Rectangle Grid',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.widescreen_grids != undefined) {
  //     var _id = item.widescreen_grids;
  //     let response = await appWidescreenGridsService.Get_Widescreen({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Wide Grid',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.player_grids != undefined) {
  //     var _id = item.player_grids;
  //     let response = await appPlayerGridsService.Get_Player({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Player Grid',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.long_grids != undefined) {
  //     var _id = item.long_grids;
  //     let response = await appLongGridsService.Get_Long({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Long Grid',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.tile_grids != undefined) {
  //     var _id = item.tile_grids;
  //     let response = await appTileGridsService.Get_Tile({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Tile Grid',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.guide_horizontal != undefined) {
  //     var _id = item.guide_horizontal;

  //     let response = await appGuide.Get_TV_Guide({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Guide Horizontal',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.list_downloads != undefined) {
  //     var _id = item.list_downloads;
  //     let response = await appListDownloads.Get_List_Downloads_List({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Download List',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.survey != undefined) {
  //     var _id = item.survey;
  //     let response = await appSurveyService.Get_Promotions_Survey({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Survey',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.webview != undefined) {
  //     var _id = item.webview;
  //     let response = await appWebviewService.Get_Webview({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Webview',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.singles != undefined) {
  //     var _id = item.singles;
  //     let response = await appSingleService.Get_Hero_Single({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Single',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.sliders != undefined) {
  //     var _id = item.sliders;
  //     let response = await appSliderService.Get_Hero_Slider({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Slider',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.players != undefined) {
  //     var _id = item.players;
  //     let response = await appPlayerService.Get_Hero_Player({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Player',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.details_bottom != undefined) {
  //     var _id = item.details_bottom;
  //     let response = await appDetailsBottom.Get_Details_Bottom({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Details Bottom',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.details_top != undefined) {
  //     var _id = item.details_top;
  //     let response = await appDetailsTop.Get_Details_Top({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Details Top',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.details_centered != undefined) {
  //     var _id = item.details_centered;
  //     let response = await appDetailsCentered.Get_Details_Centered({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Details Centered',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.details_standard != undefined) {
  //     var _id = item.details_standard;
  //     let response = await appDetailsStandard.Get_Details_Standard({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Details Standard',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   }
  //   ///audio details
  //   else if (item.details_audiostandard != undefined) {
  //     var _id = item.details_audiostandard;
  //     let response = await appAudio.Get_Details_AudioStandard({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Audio Details Standard',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else if (item.details_minimal != undefined) {
  //     var _id = item.details_minimal;
  //     let response = await appDetailsMinimal.Get_Details_Minimal({
  //       query_params: {
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //         _id: _id,
  //       },
  //       _id: _id,
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //       return await Utils.convertElementToApplication(
  //         'Details Minimal',

  //         application,
  //         pagename,
  //         response.data,
  //         index,
  //         type
  //       );
  //     } else {
  //       return application;
  //     }
  //   } else {
  //     return application;
  //   }

  //   ///39 klopt dat?
  // };
}
export default Utils;
