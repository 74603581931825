import { Route, Switch, useRouteMatch } from 'react-router';
import PushCampaignsList from './push-campaigns/list';

const PushCampaignsIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${path}/details/:_id`}>
        <PushCampaignDetails />
      </Route>
      <Route path={`${path}/add`}>
        <PushCampaignAdd />
      </Route> */}
      <Route path={`${path}/`}>
        <PushCampaignsList />
      </Route>
    </Switch>
  );
};

export default PushCampaignsIndex;
