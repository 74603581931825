import { Button, Form, Input, Modal, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { IMSNameParams } from '../../../../pages/dashboard';
import cmsService from '../../../../services/areas/content/epg/epg_urls';
import { ContentContext } from '../../../../contexts/contentContext';
import { useContext, useState } from 'react';
import { TextArea } from '../../form';

type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData?: {
    url: string;
    name: string;
  };
};

const EpgUrlModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [epgBest, setEpgBest] = useState(false);
  const [provider, setProvider] = useState('');
  const { t } = useTranslation();
  const onSubmitLocal = async (values: any) => {
    let type = values.type;

    if (values.supplier == 'TV Profile') {
      type = 'JSON';
    } else if (values.supplier == 'XMLTV') {
      type = 'XML';
    }

    values = {
      ...values,
      type: type,
      added: moment().date().toLocaleString(),
      status: 'Active',
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Add_Epg_Urls(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      onSubmit(values);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
  };
  useEffectOnce(() => {
    form.setFieldsValue(initialData);
  });
  const onChangeProvider = (value) => {
    setProvider(value);
    if (value == 'EPG Best') {
      setEpgBest(true);
    } else {
      setEpgBest(false);
    }
  };

  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('add-egp-url')}</h4>
            <Form form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
              <label className="col-form-label">{t('name')}</label>
              <Form.Item
                name="name"
                className="form-group mb-0"
                rules={[
                  {
                    required: true,
                    message: t('name-is-required'),
                  },
                ]}
              >
                <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('e-g-usa-channels-guide')} />
              </Form.Item>

              <label className="col-form-label">{t('source-url')}</label>
              <Form.Item
                name="url"
                className="form-group mb-0"
                rules={[
                  {
                    required: true,
                    message: t('url-is-required'),
                  },
                ]}
              >
                <Input type={'url'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="https://epgurl.com" />
              </Form.Item>
              <label className="col-form-label">{t('source-supplier')}</label>
              <Form.Item initialValue={'TV Profile'} name={'supplier'}>
                <Select onChange={(value) => onChangeProvider(value)} className="default-select" defaultActiveFirstOption={true} defaultValue={'TV Profile'}>
                  <Select.Option value={'TV Profile'} key={'TV Profile'}>
                    {t('tv-profile')}
                  </Select.Option>
                  <Select.Option value={'EPG Best'} key={'EPG Best'}>
                    {t('epg-best')}
                  </Select.Option>
                  <Select.Option value={'Mobibase'} key={'Mobibase'}>
                    {t('mobibase')}
                  </Select.Option>
                  <Select.Option value={'XMLTV'} key={'XMLTV'}>
                    XMLTV
                  </Select.Option>
                </Select>
              </Form.Item>

              <label className="col-form-label">{t('source-format')}</label>
              <Form.Item initialValue={'JSON'} name={'type'}>
                <Select className="default-select" defaultActiveFirstOption={true} defaultValue={'JSON'}>
                  <Select.Option value={'JSON'} key={'JSON'}>
                    JSON
                  </Select.Option>
                  <Select.Option value={'XML'} key={'XML'}>
                    XMLTV
                  </Select.Option>
                </Select>
              </Form.Item>
              {provider === 'TV Profile' && (
                <>
                  <label className="col-form-label">Parental Country</label>
                  <Form.Item initialValue={'--select a country--'} name={'country'}>
                    <Select className="default-select" defaultActiveFirstOption={true} defaultValue={''}>
                      {deployment?.countries.map((country) => (
                        <Select.Option value={country.iso3} key={country.iso3}>
                          {country.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <label className="col-form-label">Translation Language</label>
                  <Form.Item initialValue={'--select a language--'} name={'language'}>
                    <Select className="default-select" defaultActiveFirstOption={true} defaultValue={''}>
                      {deployment?.languages.map((language) => (
                        <Select.Option value={language.code} key={language.code}>
                          {language.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )}
              {epgBest && (
                <>
                  <label className="col-form-label">{t('api-key')}</label>
                  <Form.Item name="apikey" extra={t('you-must-obtain-the-api-key-from-epg-best-settings-and-paste-it-above-next-to-that-you-also-need-to-whitelist-our-ip-address-104-155-26-83-in-the-epg-best-settings-menu')} className="form-group mb-0">
                    <TextArea rows={4} className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('your-api-key')} />
                  </Form.Item>
                </>
              )}
              <div className="col-md-12 p-0">
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-source-url')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EpgUrlModal;
