import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Translations from '../../../../../../../common/components/Content/Translations/Translations';
import { Translations as TRLNS } from '../../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../../services/areas/content/games/games';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import Tags from '../../../../../../../common/components/Content/Tags/Tags';
import Providers from '../../../../../../../common/components/Content/Providers/Providers';
import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../../constants/TVMSRoles';
import Share from '../../../../../../../common/components/Content/Share/Share';
import ParentalControl from '../../../../../../../common/components/Content/Parental/Parental';
import moment from 'moment';
import ReleaseSchedules from '../../../../../../../common/components/Content/ReleaseSchedules/ReleaseSchedules';

const EditGames = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [schedules, setSchedules] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const [provider, setProvider] = useState('');
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;
  const [share, setShare] = useState(false);

  useEffect(() => {
    setSchedules(data.data.releaseschedule);
    setTranslations(data.data.translations);
    setTags(data.data.tags);
    setProvider(data.data.provider?.name);
    setShare(data.data.share ?? false);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: any) => {
    setIsAdding(true);

    values = {
      _id: _id,
      ...data.data,
      name: values.name,
      placement: {
        ut_start: String(moment().add(-99, 'year').unix()),
        ut_end: String(moment().add(99, 'year').unix()),
        no_play_on_trial: false,
      },
      releaseschedule: schedules,
      share: share,
      game_provider: '',
      game_id: values.game_id,
      game_slug: '',
      game_affiliate: values.game_affiliate,
      translations: translations,
      tags: tags,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      parental: {
        ...values.parental,
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };

    let response = await cmsService.Update_Games(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('games-name')} />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('game-id')}</label>
                <Form.Item className="col-md-7" name="game_id">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="D9SDKDF" />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">{t('game-affiliate')}</label>
                <Form.Item className="col-md-7" name="game_affiliate">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="D9SDKDF" />
                </Form.Item>
              </div>
            </div>
            {!isProvider && <Providers data={provider} setProvider={setProvider} />}
            <Tags data={tags} setTags={setTags} />

            <ParentalControl />
            <ReleaseSchedules setSchedules={setSchedules} data={schedules} />
            <Share data={share} setShare={setShare} />
            <Translations data={translations} service_name={service?.name ?? ''} clientName={clientName} setTranslations={setTranslations} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-games')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditGames;
