import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import nl from './locales/nl.json';
import fr from './locales/fr.json';
import pt from './locales/pt.json';
import es from './locales/es.json';
import sk from './locales/sk.json';


const DETECTION_OPTIONS = { order: ['localStorage', 'navigator'], caches: ['localStorage'], lookupLocalStorage: 'lang', }

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    supportedLngs: ['en','nl','pt','fr','es', 'sk'],  
    detection: DETECTION_OPTIONS,
    resources: {
      en: {
        translation: { ...en },
      },
      nl: {
        translation: { ...nl },
      },
      pt: {
        translation: { ...pt },
      },
      fr: {
        translation: { ...fr },
      },
      es: {
        translation: { ...es },
      },
      sk: {
        translation: { ...sk },
      },
    },
    fallbackLng: 'en',
    returnNull: false,
    interpolation: {
      escapeValue: false, // React handles escaping
    },
    debug: false
  });

export default i18n;
