import { Client } from '../../interfaces/forms/clients/clients';
import { IBaseResponse } from '../../interfaces/responses/IBaseResponse';
import axios from '../../utils/axios';
import { API_ENDPOINTS } from '../_APIConstants';

class ClientService {
  addClient = async (data: Client): Promise<IBaseResponse> => {
    return await axios
      .post(API_ENDPOINTS.V1.Client.ADD_CLIENT, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  updateClient = async (data: Client): Promise<IBaseResponse> => {
    return await axios
      .post(API_ENDPOINTS.V1.Client.UPDATE_CLIENT, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
  //old
  getClient = async (data: any) => {
    return await axios
      .get(`${API_ENDPOINTS.V1.Client.GET_CLIENT}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  getClientByName = async (data: any) => {
    return await axios
      .get(`${API_ENDPOINTS.V1.Client.GET_CLIENT_BY_NAME}/${data._id}`, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  //new
  detailsClient = async (data: any) => {
    return await axios
      .get(`${API_ENDPOINTS.V1.Client.DETAILS_CLIENT}/${data._id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  checkClient = async (data: any) => {
    return await axios
      .get(`${API_ENDPOINTS.V1.Client.CHECK_CLIENT}/${data._id}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  copyToObjectStorage = async (data: any): Promise<IBaseResponse> => {
    return await axios
      .post(API_ENDPOINTS.V1.Client.COPY_CLIENT, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
export default new ClientService();
