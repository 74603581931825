import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../context_api/AuthContext';
import { Dropdown, type MenuProps } from 'antd'
import { faAngleDown, faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { isInRoles } from '../VisibleForRole';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


const LanguageSelector = () => {
  const { state: AuthState } = useContext(AuthContext);
  const { i18n } = useTranslation();


  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const getCountryName = (iso2) => {
    if (iso2 !== undefined) {
      var getCountryNames = new Intl.DisplayNames(['en'], { type: 'language' });
      return getCountryNames.of(iso2?.toUpperCase());
    }
  };

  const CountryButton = ({ iso2 }) => {
    var getCountryNames = new Intl.DisplayNames(['en'], { type: 'language' });
    var country = iso2 === 'cimode' ? 'Show translation keys' : getCountryNames.of(iso2?.toUpperCase());
    return (
      <div onClick={() => changeLanguage(iso2)} style={{ display: 'flex', fontSize: 13, margin: 10, fontWeight: getCountryName(i18n.language) === country ? 'bold' : 'normal', width: 100 }}>
        {`${country}`}
      </div>
    );
  };

  const langs: MenuProps['items'] = [
    {
      key: '1',
      label: <CountryButton key={'1'} iso2={'en'}></CountryButton>,
    },
    {
      key: '2',
      label: <CountryButton key={'2'} iso2={'fr'}></CountryButton>,
    },
    {
      key: '3',
      label: <CountryButton key={'3'} iso2={'nl'}></CountryButton>,
    },
    {
      key: '4',
      label: <CountryButton key={'4'} iso2={'pt'}></CountryButton>,
    },
    {
      key: '5',
      label: <CountryButton key={'5'} iso2={'es'}></CountryButton>,
    },
    {
      key: '6',
      label: <CountryButton key={'6'} iso2={'sk'}></CountryButton>,
    },
  ];

  if (AuthState.user && isInRoles(AuthState.user.role, [TVMSRoles.SuperAdmin])) {
    langs.push({
      key: '0',
      label: <CountryButton key={'0'} iso2={'cimode'}></CountryButton>,
    });
  }

  return (
    <Dropdown menu={{ items: langs }}>
      <div className="nav-link pl-2 pl-lg-4 pr-0 user-menu mr-4">
        <FontAwesomeIcon className="ml-2 align-middle" icon={faGlobe as IconProp} style={{ marginRight: 10, height: 18, color: '#222' }} />
        <span className="d-none d-lg-inline-block" style={{ color: '#222' }}>
          {i18n.language === 'cimode' ? 'Show translation keys' : getCountryName(i18n.language)}
        </span>
        <FontAwesomeIcon className="ml-2 align-middle" icon={faAngleDown as IconProp} />
      </div>
    </Dropdown>
  )
}

export default LanguageSelector
