import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Select, Table, Tooltip } from 'antd';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AppBuilderContext } from '../../../contexts/appbuilderContext';
import user_interfaces from '../../../services/areas/appbuilder/applications/application';
import * as HttpStatusCodes from 'http-status-codes';
import { IABP_Lists } from '../../../interfaces/forms/common/IABP_Lists';
import { IMSNameParams } from '../../../pages/dashboard';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

const ServiceSelections = (params: { setData: any; data?: any; app?: any }) => {
  const { deployment } = useContext(AppBuilderContext);
  const [dataLocal, setDataLocal] = useState([] as any);
  const [selectedService, setSelectedService] = useState('');
  const [loading, setLoading] = useState(true);
  const [allApps, setAllApps] = useState([] as any);
  const { clientName } = useParams<IMSNameParams>();
  const { t } = useTranslation();

  const handleAddSubmit = async () => {
    setLoading(true);
    var test = allApps.find((d: any) => d.name == selectedService);

    if (test != undefined) {
      console.log(test);
      var newArr = dataLocal;
      newArr.push({
        packageid: test.packageid,
        appid: test.appid,
        deployment: test.deployment,
        app: test.app,
        name: test.name,
        nested_name: test.nested_name,
        nested_image: test.nested_image,
      });
      setDataLocal(newArr);
      params.setData(newArr);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleDelete = (row: any) => {
    setLoading(true);
    var test = dataLocal.filter((d: any) => d._id != row._id);
    if (test != undefined) {
      setDataLocal(test);
      params.setData(test);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const [tableSearchState, setTableSearchState] = useState<IABP_Lists>({
    pageNumber: 1,
    pageSize: 10,
    query_params: {
      cms: deployment?.name ?? '',
      instance: clientName,
    },
  });

  useEffect(() => {
    if (params.data != undefined) {
      if (params.data.length > 0) {
        setDataLocal(params.data);
      }
    }
    setLoading(false);
  }, [params.data]);

  const setSelectedNestedApps = (value: any) => {
    setSelectedService(value.value);
  };

  const getUserInterfacesList = async (tableSearchState: IABP_Lists) => {
    setLoading(true);
    let response = await user_interfaces.List_Apps(tableSearchState);
    if (response && response.status_code === HttpStatusCodes.OK) {
      if (response.data.data.length > 0) {
        setAllApps(response.data.data.filter((d) => d.deleted != true));
      }
      setLoading(false);
    }
  };

  useEffectOnce(() => {
    getUserInterfacesList(tableSearchState);
  });

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-5">
            <div className="row-md-12">
              <div className="row">
                <div className="col-md-5">
                  <Select loading={loading} onChange={setSelectedNestedApps} className={'default-select row ml-1'} placeholder={t('select-app')} labelInValue>
                    {allApps.map((app) => {
                      if (app.name != params.app.branding.name) {
                        return (
                          <Select.Option value={app.name} key={app.name}>
                            {app.deployment}:{app.name} {t('parenthesis-app-id-colon')}
                            {app.appid}
                            {t('close-parenthesis')}
                          </Select.Option>
                        );
                      }
                    })}
                  </Select>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <Button onClick={handleAddSubmit} className="ant-btn btn btn-secondary rounded-pill width-240 height-48 mr-2 mt-1 ml-4">
                      {t('add-nested-app')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Table
            loading={loading}
            rowKey={(dataLocal) => dataLocal}
            columns={[
              {
                title: 'Nested Name',
                dataIndex: 'nested_name',
              },
              {
                title: 'Nested Image',
                dataIndex: 'nested_image',
                render: (t, r) => <img src={`${r.nested_image}`} alt="" />,
                // render: () => <img src={$nested_image} alt="" />,
                // cell: (row) => (
                //   <div className="text-center" style={{ backgroundColor: '#ececec', display: 'flex', padding: 10, justifyContent: 'center' }}>
                //     {row.images.square != undefined && <Image width="75px" src={'https://cloudtv.akamaized.net/' + row.images.square}></Image>}
                //     {row.images.poster != undefined && <Image width="100px" src={'https://cloudtv.akamaized.net/' + row.images.poster}></Image>}
                //     {row.images.widescreen != undefined && <Image width="160px" src={'https://cloudtv.akamaized.net/' + row.images.widescreen}></Image>}
                //   </div>
                // ),
              },
              {
                title: t('deployment'),
                dataIndex: 'deployment',
              },
              {
                title: t('service'),
                dataIndex: 'app',
              },
              {
                title: t('app-name'),
                dataIndex: 'name',
              },

              {
                title: t('app-id'),
                dataIndex: 'appid',
              },
              {
                title: t('package-id'),
                dataIndex: 'packageid',
              },
              {
                title: t('actions'),
                dataIndex: 'action',
                width: 100,
                render: (tags: any, row: any, index_: any) => (
                  <span className="justify-content-end">
                    <Tooltip placement="top" title={`Delete`}>
                      <Button
                        onClick={() => {
                          handleDelete(row);
                        }}
                        className="mx-2 cursor-pointer"
                        type="text"
                      >
                        <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                      </Button>
                    </Tooltip>
                  </span>
                ),
              },
            ]}
            pagination={{
              position: ['bottomLeft'],
              pageSize: 20,
              hideOnSinglePage: true,
              showTotal: (total, range) => {
                return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total });
              },
            }}
            dataSource={dataLocal}
            key={JSON.stringify(dataLocal)}
          />
        </div>
      </div>
    </div>
  );
};
export default ServiceSelections;
