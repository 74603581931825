import { Button, Form, Input, notification } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Security from '../../../../../../common/components/Content/Security/Security';
import PayPerView from '../../../../../../common/components/Content/PayPerView/PayPerView';
import SubContent from '../../../../../../common/components/Content/Content/SubContent';
import Translations from '../../../../../../common/components/Content/Translations/Translations';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';
import { Translations as TRLNS } from '../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../services/areas/content/albums/albums';
import { IMSNameParams } from '../../../../../dashboard';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import { ContentContext } from '../../../../../../contexts/contentContext';
import ReleaseSchedules from '../../../../../../common/components/Content/ReleaseSchedules/ReleaseSchedules';
import Tags from '../../../../../../common/components/Content/Tags/Tags';
import Providers from '../../../../../../common/components/Content/Providers/Providers';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';
import { AuthContext } from '../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';
import Share from '../../../../../../common/components/Content/Share/Share';
import ParentalControl from '../../../../../../common/components/Content/Parental/Parental';
import moment from 'moment';

const AddAlbum = () => {
  const history = useHistory();
  const params = useParams<IMSNameParams & { deployment: string }>();
  const { clientName } = useParams<IMSNameParams>();
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [security, setSecurity] = useState([] as any);
  const [payperview, setPayPerView] = useState([] as any);
  const [icon, setIcon] = useState([] as any);
  const [songs, setSongs] = useState([] as any);
  const [schedules, setSchedules] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { service, deployment } = useContext(ContentContext);
  const [tags, setTags] = useState([] as any);
  const [provider, setProvider] = useState('No Provider');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const [share, setShare] = useState(false);

  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    var payperview_ = [] as any;
    if (payperview.rule?.access_length == undefined) {
      var curs = [] as any;
      deployment?.currencies.forEach((cur, index) => {
        curs.push({
          iso3: cur.isoCurrency,
          credits_rented: 0,
          credits_purchased: 0,
          key: index,
        });
      });
      payperview_ = {
        enable: payperview.enable,
        rule: undefined,
        currencies: curs,
      };
    } else {
      payperview_ = payperview;
    }
    values = {
      name: values.name,
      artist: values.artist,
      security: security,
      placement: {
        ut_start: String(moment().add(-99, 'year').unix()),
        ut_end: String(moment().add(99, 'year').unix()),
        no_play_on_trial: false,
      },
      releaseschedule: schedules,
      share: share,
      translations: translations,
      payperview: payperview_,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      provider_status: isProvider ? 'validation' : '',
      tags: tags,
      songs: songs,
      images: {
        square: icon.url.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
    };
    let response = await cmsService.Add_Album(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-albums-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-album')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-your-album-collection-and-offer-it-bundled-in-packages-to-your-customers')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('album')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('album-name')} />
                </Form.Item>
              </div>

              <div className="row">
                <label className="col-md-3 col-form-label">Band/Singer/Songwriter</label>
                <Form.Item
                  className="col-md-7"
                  name="artist"
                  rules={[
                    {
                      required: true,
                      message: t('band-singer-songwriter-is-required'),
                    },
                    {
                      whitespace: true,
                      message: t('band-singer-songwriter-is-not-correct'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="Band/Singer/Songwriter" />
                </Form.Item>
              </div>
            </div>
            {!isProvider && <Providers setProvider={setProvider} />}
            <Translations data={translations} service_name={params.deployment} clientName={clientName} setTranslations={setTranslations} />
            <Tags setTags={setTags} />
            <Security setSecurity={setSecurity} />

            <ParentalControl />

            <ReleaseSchedules setSchedules={setSchedules} />
            <Share setShare={setShare} />
            <PayPerView service_name={params.deployment} clientName={clientName} setPayPerView={setPayPerView} />
            <ImageUploader
              onSubmit={(values: any) => {
                setIcon(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadName: t('album-image'),
                uploadType: 'square',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.ALBUMS.IMAGES,
              }}
            />

            <SubContent setData={setSongs} type={'Songs'} data={songs} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-album')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;
