/* eslint-disable eqeqeq */
import { Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../dashboard';

import cmsChannels from '../../../../../services/areas/content/channels/channels';
import cmsGames from '../../../../../services/areas/content/games/games';
import cmsCams from '../../../../../services/areas/content/cams/cams';
import cmsMovies from '../../../../../services/areas/content/movies/movies';
import cmsShorts from '../../../../../services/areas/content/shorts/shorts';
import cmsSeries from '../../../../../services/areas/content/series/series';
import cmsCourses from '../../../../../services/areas/content/courses/courses';
import cmsRadio from '../../../../../services/areas/content/radio/radio';
import cmsAlbums from '../../../../../services/areas/content/albums/albums';
import cmsApps from '../../../../../services/areas/content/apps/apps';
import cmsPodcasts from '../../../../../services/areas/content/podcasts/podcasts';

import { ContentContext } from '../../../../../contexts/contentContext';
import { Spin } from '../../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';

//exporter // streams // epg info etcv
const Importer = () => {
  const [form] = useForm();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const { t } = useTranslation();
  const [selectedContentType, setSelectedContentType] = useState<string | undefined>(undefined);
  const [isAdding, setIsAdding] = useState(false);

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12">
          <h2 className="page-title">Image Enhancer</h2>
          <p className="h6 font-weight-normal line-height-26">Use this tool to re-import your images from our huge movies and series database</p>
        </div>
      </div>
      <Form form={form} onFinish={(values: any) => {}} onFinishFailed={(errorInfo) => {}}>
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            {!isAdding && (
              <div className="card-bg-light pl-5 pt-4 mt-4 pb-5">
                <h4 className="text-primary mb-2 mt-4">{t('content-type')}</h4>
                <p>{t('select-the-content-type-where-you-would-like-to-import-your-excel-to')}</p>
                <div className="row">
                  <div className="col-md-5">
                    <Form.Item name={'columns'}>
                      <Select className="default-select" onSelect={setSelectedContentType} defaultActiveFirstOption={true}>
                        <Select.Option value={'Movies'} key={5}>
                          {t('movies')}
                        </Select.Option>
                        <Select.Option value={'Series'} key={9}>
                          {t('series')}
                        </Select.Option>
                        {/* <Select.Option value={'Seasons'} key={10}>
                          {t('seasons')}
                        </Select.Option> */}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}

            {isAdding && (
              <div className="card-bg-light p-5 mt-4">
                <div className="pl-2 pb-4">
                  <div className="row text-center h-100 w-100">
                    <div className="col-12 align-self-center pt-5 pb-5">
                      <Spin size="large" spinning={true} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};
export default Importer;
