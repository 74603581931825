import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { memo, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useEffectOnce, useLocation } from 'react-use';
import DefaultUserIcon from '../../../assets/images/user.png';
import { AuthContext } from '../../../context_api/AuthContext';
import { AuthActionTypes } from '../../../context_api/AuthContext/action-types';
import { DrawerContext } from '../../../context_api/DrawerContext';
import { DrawerActionTypes } from '../../../context_api/DrawerContext/action-types';
import { IMSNameParams } from '../../../pages/dashboard';
import { useQuery } from '@tanstack/react-query';
import * as DeploymentService from '../../../services/deployments/deployment';
import { NotVisibleForRole, VisibleForRole } from '../VisibleForRole';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import DeploymentSettings from '../../../pages/dashboard/pages/home/components/dashboard/settings/deploymentSettings';
import ServiceSettings from '../../../pages/dashboard/pages/home/components/dashboard/settings/serviceSettings';
import { useTranslation } from 'react-i18next';
import { faTrophy } from '@fortawesome/pro-regular-svg-icons';
import type { MenuProps } from 'antd';
import ClientService from '../../../services/client';

import LanguageSelector from '../LanguageSelector';

const { Header } = Layout;

type IMSDashboardProps = {
  isSideBarShow: Boolean;
  path: string;
};
const IMSDashboardHeader = (props: IMSDashboardProps) => {
  const location = useLocation();
  const { clientName, deployment, service } = useParams<IMSNameParams>();
  const history = useHistory();
  const { state: AuthState, dispatch: AuthDispatch } = useContext(AuthContext);
  const { state: DrawerState, dispatch: DrawerDispatch } = useContext(DrawerContext);
  const [showDeploymentSettings, setShowDeploymentSettings] = useState(false);
  const [showServiceSettings, setShowServiceSettings] = useState(false);
  const { t } = useTranslation();
  const [languages, setLanguages] = useState([] as any);
  const [countries, setCountries] = useState([] as any);
  const [currencies, setCurrencies] = useState([] as any);
  const [client, setClient] = useState([] as any);
  const { data } = useQuery(['deployment', deployment], async () => {
    if (deployment !== undefined && deployment !== '') {
      return DeploymentService.find(clientName, { name: deployment })
        .then((r) => r.data)
        .catch((e) => Promise.reject(e));
    }
  });

  useEffectOnce(() => {
    (async () => {
      await ClientService.getClientByName({
        _id: clientName,
      }).then((response) => {
        setClient(response.data);
      });
    })();
  });

  useEffect(() => {
    if (data !== undefined) {
      if (data.services !== undefined) {
        var test = data.services.find((s) => s.name === service);
        if (test !== undefined) {
          setCountries(test.countries);
          setLanguages(test.languages);
          setCurrencies(test.currencies);
        } else {
          setCountries(data?.countries);
          setLanguages(data?.languages);
          setCurrencies(data?.currencies);
        }
      }
    }
  }, [data]);

  var clientRole = false;
  if (AuthState?.user?.role === 'superadmin' || AuthState?.user?.role === 'admin' || AuthState?.user?.role === 'SuperAdmin') {
    clientRole = false;
  }

  var locSplit = location?.pathname?.split('/');
  var extraPath = '';
  var canAdd = true;
  if (locSplit !== undefined) {
    for (let i = 4; i < locSplit.length + 3; i++) {
      if (locSplit[i] !== undefined) {
        var letters = onlyLetters(locSplit[i]);
        var numbers = onlyNumbers(locSplit[i]);
        if (letters === false && numbers === false) {
          if (locSplit[i].length !== 24 && canAdd === true) {
            extraPath += ' > ' + locSplit[i];
          }
        } else {
          if (locSplit[i] !== 'content' && locSplit[i] !== 'customers' && locSplit[i] !== 'billing' && locSplit[i] !== 'reporting' && locSplit[i] !== 'marketing' && locSplit[i] !== 'apps') {
            if (canAdd === true) {
              extraPath += ' > ' + locSplit[i];
            }
          }
        }
      }
    }
  }
  //24
  function onlyLetters(str) {
    return Boolean(str.match(/^[A-Za-z]*$/));
  }
  function onlyNumbers(str) {
    return Boolean(str.match(/^[0-9]*$/));
  }

  useEffect(() => {
    if (location.trigger === 'popstate') {
      let currentLocation = location.pathname?.toLowerCase();

      if (currentLocation && !['cms', 'crm', 'iq', 'uit'].some((v) => currentLocation!.includes(v))) {
        DrawerDispatch({
          type: DrawerActionTypes.SET_SIDEBAR_ROUTES,
          default_selected_key: '',
          default_open_key: '',
          routes: [],
          service_name: '',
          service_type: '',
          client_dashboard_path: '',
        });
      }
    }
  }, [DrawerState.client_info, location, DrawerDispatch]);

  const changeMainMenu = (openKeys: any) => {
    if (openKeys && openKeys.length > 0) {
      document.body.classList.add('menu-overlay');
    } else {
      document.body.classList.remove('menu-overlay');
    }
  };
  const handleMenuClick = ({ key }: any) => {
    AuthDispatch({ type: AuthActionTypes.LOGOUT });
    history.push('/login');
  };

  const submitSettings = () => {
    history.go(0);
  };

  const profiles: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Menu className="header-dropdown mt-1">
          <div className="px-4 py-2">
            <div className="media border-bottom pb-3 d-flex">
              <img className="rounded-circle mr-3" src={DefaultUserIcon} title={t('user')} alt={t('user')} style={{ height: 45, width: 45 }} />
              <div className="media-body">
                <h5 className="mt-0 text-primary" style={{ paddingBottom: 0, marginBottom: 8 }}>
                  {AuthState.user?.name}
                </h5>
                <p className="mb-0">{AuthState.user?.email}</p>
                <Button className="mt-5" onClick={handleMenuClick}>
                  {t('logout')}
                </Button>
              </div>
            </div>
          </div>
        </Menu>
      ),
    },
  ];

  return (
    <>
      <Header className="p-0 d-flex z-overly-10" style={{ backgroundColor: DrawerState.defaultOpenKey !== '' ? '#fff' : '#fff', marginLeft: extraPath === '' ? 20 : 0 }}>
        <nav className="d-flex align-items-center w-100 px-3 px-lg-4 py-0" style={{ backgroundColor: '#fff', height: 64 }}>
          <div className="ml-1 row" style={{ color: '#222', marginTop: 12 }}>
            {client.type !== undefined && (
              <div className="mr-2">
                <FontAwesomeIcon size={'lg'} icon={faTrophy} color={client.type === 'Startup' ? 'bronze' : client.type === 'Professional' ? 'silver' : 'gold'}></FontAwesomeIcon>
              </div>
            )}
            <div className="mr-2" style={{ color: '#222' }}>
              {t('client-tvms')}
            </div>
            {' > '}
            {clientName} &nbsp;
            {!clientRole && extraPath !== '' && (
              <div>
                {' > '}
                {t('and-gt-and-nbsp-dashboard')}
              </div>
            )}
            &nbsp; {extraPath !== ' > ' && extraPath}
          </div>
          <div className="row mt-2" style={{ position: 'absolute', right: 20, display: 'flex', alignItems: 'center' }}>
            <NotVisibleForRole roles={[TVMSRoles.Vendor, TVMSRoles.Provider]}>
              <Menu onOpenChange={changeMainMenu} triggerSubMenuAction={'click'} mode="horizontal" className="border-0 mr-0 mr-lg-4 bg-transparent">
                <Menu.Item key="alipay" className="support-center">
                  <a target="_blank" style={{ color: '#222' }} rel="noopener noreferrer" href="https://mwareiptv.gitbook.io/tvms/">
                    {t('support-center')}
                  </a>
                </Menu.Item>
              </Menu>
            </NotVisibleForRole>
            <LanguageSelector />
            <Dropdown menu={{ items: profiles }}>
              <div className="nav-link pl-2 pl-lg-4 pr-0 user-menu mr-4">
                <img className="rounded-circle mr-2" src={DefaultUserIcon} title={t('user')} alt={t('user')} style={{ height: 40, width: 40 }} />
                <span className="d-none d-lg-inline-block" style={{ color: '#222' }}>
                  {AuthState.user?.name}
                </span>
                <FontAwesomeIcon className="ml-2 align-middle" icon={faAngleDown as IconProp} />
              </div>
            </Dropdown>
          </div>
        </nav>
      </Header>

      {data !== undefined && <DeploymentSettings deployment={data} clientName={clientName} visible={showDeploymentSettings} onSubmit={() => submitSettings()} onCancel={() => setShowDeploymentSettings(false)}></DeploymentSettings>}
      {data !== undefined && <ServiceSettings service={service || ''} deployment={deployment} clientName={clientName} visible={showServiceSettings} onSubmit={() => submitSettings()} onCancel={() => setShowServiceSettings(false)}></ServiceSettings>}

      {deployment !== undefined && languages !== undefined && (
        <div className="col-12" style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 0, backgroundColor: '#f9f9f9', borderBottom: '1px solid #dfdfdf', borderTop: '1px solid #dfdfdf' }}>
          <div style={{ display: 'flex', width: '100%', fontSize: 12 }}>
            <div style={{ width: '33%', paddingRight: 20 }}>
              <b>{t('languages')}</b>
              <div style={{ width: '100', paddingRight: 20 }}>
                {languages.map((lang, index) => {
                  return (
                    <span key={index} style={{ marginRight: 3 }}>
                      {lang.name}
                      {languages.length !== index + 1 ? ', ' : ''}
                    </span>
                  );
                })}
              </div>
            </div>
            <div style={{ width: '33%', paddingRight: 20 }}>
              <b>{t('countries')}</b>
              <div style={{ width: '100%', paddingRight: 20 }}>
                {countries.map((lang, index) => {
                  return (
                    <span key={index} style={{ marginRight: 3 }}>
                      {lang.name}
                      {countries.length !== index + 1 ? ', ' : ''}
                    </span>
                  );
                })}
              </div>
            </div>

            <div style={{ width: '33%', paddingRight: 20 }}>
              <b>{t('currencies')}</b>
              <div style={{ width: '100%', paddingRight: 20 }}>
                {currencies.map((cur, index) => {
                  return (
                    <span key={index} style={{ marginRight: 3 }}>
                      {cur.isoCurrency}
                      {currencies.length !== index + 1 ? ', ' : ''}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row" style={{ height: '100%', display: 'flex', justifyContent: 'flex-start', alignContent: 'flex-start', paddingRight: 20 }}>
            <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]}>
              <>
                {service === undefined && (
                  <Button className="btn-primary rounded-pill" onClick={() => setShowDeploymentSettings(true)}>
                    <FontAwesomeIcon style={{ marginTop: 4 }} icon={faCog}></FontAwesomeIcon>
                  </Button>
                )}
              </>
            </VisibleForRole>
            <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]}>
              <>
                {service !== undefined && (
                  <Button className="btn-primary rounded-pill" onClick={() => setShowServiceSettings(true)}>
                    <FontAwesomeIcon style={{ marginTop: 4 }} icon={faCog}></FontAwesomeIcon>
                  </Button>
                )}
              </>
            </VisibleForRole>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(IMSDashboardHeader);
