import { Button, Col, Modal, Tooltip } from 'antd';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../ReactBCTable';
import { IReactBCTableColumn } from '../../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faEdit, faTrashCan, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BundleModal from './Bundles/BundleModal';
import NestedCategories from './NestedCategories';

import cmsAlbums from '../../../../services/areas/content/albums/bundles';
import cmsApps from '../../../../services/areas/content/apps/bundles';
import cmsPodcasts from '../../../../services/areas/content/podcasts/bundles';
import cmsMovies from '../../../../services/areas/content/movies/bundles';
import cmsShorts from '../../../../services/areas/content/shorts/bundles';
import cmsSeries from '../../../../services/areas/content/series/bundles';
import cmsChannels from '../../../../services/areas/content/channels/bundles';
import cmsRadios from '../../../../services/areas/content/radio/bundles';
import cmsCams from '../../../../services/areas/content/cams/bundles';
import cmsCourses from '../../../../services/areas/content/courses/bundles';
import cmsGames from '../../../../services/areas/content/games/bundles';

import moment from 'moment';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { ContentContext } from '../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NestedTable = (params: { type: any }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const { confirm } = Modal;
  const history = useHistory();
  const { t } = useTranslation();
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState([] as any);

  const filterValidationAccepted = {
    id: 'provider_status',
    value: { filterValue: 'accepted', filterType: 'text' },
  } as any;

  const filterValidationEmpty = {
    id: 'provider_status',
    value: { filterValue: '', filterType: 'text' },
  } as any;

  const filterValidationUndefined = {
    id: 'provider_status',
    value: { filterValue: 'undefined', filterType: 'undefined' },
  } as any;

  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 250,
    filters: [filterValidationUndefined, filterValidationEmpty, filterValidationAccepted],
    query_params: {
      instance: clientName,
      cms: deployment?.name ?? '',
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);

  useEffectOnce(() => {
    switch (params.type) {
      case 'Albums':
        (async () => await getAlbumBundles(tableSearchState))();
        break;
      case 'Apps':
        (async () => await getAppBundles(tableSearchState))();
        break;
      case 'Podcasts':
        (async () => await getPodcastBundles(tableSearchState))();
        break;
      case 'Channels':
        (async () => await getChannelBundles(tableSearchState))();
        break;
      case 'Radios':
        (async () => await getRadioBundles(tableSearchState))();
        break;
      case 'EpgTv':
        (async () => await getChannelBundles(tableSearchState))();
        break;
      case 'EpgRadio':
        (async () => await getRadioBundles(tableSearchState))();
        break;
      case 'Movies':
        (async () => await getMovieBundles(tableSearchState))();
        break;
      case 'Shorts':
        (async () => await getShortsBundles(tableSearchState))();
        break;
      case 'Courses':
        (async () => await getCoursesBundles(tableSearchState))();
        break;
      case 'Series':
        (async () => await getSeriesBundles(tableSearchState))();
        break;
      case 'Cams':
        (async () => await getCamBundles(tableSearchState))();
        break;
      case 'Games':
        (async () => await getGamesBundles(tableSearchState))();
        break;
    }
  });

  const addAlbumBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Add_Album_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateAlbumBundle = async (package_: any, name: any) => {
    let response = await cmsAlbums.Update_Album_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumBundles(tableSearchState);
    }
  };
  const deleteAlbumBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Delete_Album_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAlbumBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsAlbums.List_Album_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishAlbumBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Publish_Album_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.ALBUMS.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addAppBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsApps.Add_App_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateAppBundle = async (package_: any, name: any) => {
    let response = await cmsApps.Update_App_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppBundles(tableSearchState);
    }
  };
  const deleteAppBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsApps.Delete_App_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAppBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsApps.List_App_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishAppBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsApps.Publish_App_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.APPS.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addPodcastBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Add_Podcast_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updatePodcastBundle = async (package_: any, name: any) => {
    let response = await cmsPodcasts.Update_Podcast_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastBundles(tableSearchState);
    }
  };
  const deletePodcastBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Delete_Podcast_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getPodcastBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsPodcasts.List_Podcast_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishPodcastBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Publish_Podcast_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.PODCASTS.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addCamBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsCams.Add_Cam_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCamBundle = async (package_: any, name: any) => {
    let response = await cmsCams.Update_Cam_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamBundles(tableSearchState);
    }
  };
  const deleteCamBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsCams.Delete_Cam_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCamBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCams.List_Cam_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishCamBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCams.Publish_Cam_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CAMS.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addChannelBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Add_Channel_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getChannelBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateChannelBundle = async (package_: any, name: any) => {
    let response = await cmsChannels.Update_Channel_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getChannelBundles(tableSearchState);
    }
  };
  const deleteChannelBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Delete_Channel_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getChannelBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getChannelBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsChannels.List_Channel_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      console.log(response.data);
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishChannelBundle = async (package_: any) => {
    setIsLoading(true);
    let responseEpg = await cmsChannels.Publish_Channel_Epg({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.EPG,
      },
      ...package_,
    });
    if (responseEpg && responseEpg.status_code === HttpStatusCodes.OK) {
      getChannelBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addRadioBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Add_Radio_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRadioBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateRadioBundle = async (package_: any, name: any) => {
    let response = await cmsRadios.Update_Radio_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },

      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRadioBundles(tableSearchState);
    }
  };
  const deleteRadioBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Delete_Radio_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRadioBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getRadioBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsRadios.List_Radio_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishRadioBundle = async (package_: any) => {
    setIsLoading(true);
    let responseEpg = await cmsRadios.Publish_Radio_Epg({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.RADIOS.EPG,
      },
      ...package_,
    });
    if (responseEpg && responseEpg.status_code === HttpStatusCodes.OK) {
      getRadioBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addMovieBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Add_Movie_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateMovieBundle = async (package_: any, name: any) => {
    let response = await cmsMovies.Update_Movie_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieBundles(tableSearchState);
    }
  };
  const deleteMovieBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Delete_Movie_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getMovieBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsMovies.List_Movie_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishMovieBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Publish_Movie_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.MOVIES.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addShortsBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Add_Shorts_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateShortsBundle = async (package_: any, name: any) => {
    let response = await cmsShorts.Update_Shorts_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsBundles(tableSearchState);
    }
  };
  const deleteShortsBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Delete_Shorts_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getShortsBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsShorts.List_Shorts_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishShortsBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Publish_Shorts_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SHORTS.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addSeriesBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Add_Series_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateSeriesBundle = async (package_: any, values: any) => {
    let response = await cmsSeries.Update_Series_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesBundles(tableSearchState);
    }
  };
  const deleteSeriesBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Delete_Series_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getSeriesBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsSeries.List_Series_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishSeriesBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Publish_Series_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  const addCoursesBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Add_Course_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCoursesBundle = async (package_: any, name: any) => {
    let response = await cmsCourses.Update_Course_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesBundles(tableSearchState);
    }
  };
  const deleteCoursesBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Delete_Course_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCoursesBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCourses.List_Course_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishCoursesBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Publish_Course_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  //games
  const addGamesBundle = async (values: any) => {
    setIsLoading(true);
    let response = await cmsGames.Add_Games_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateGamesBundle = async (package_: any, name: any) => {
    let response = await cmsGames.Update_Games_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesBundles(tableSearchState);
    }
  };
  const deleteGamesBundle = async (row: any) => {
    setIsLoading(true);
    let response = await cmsGames.Delete_Games_Bundles({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesBundles(tableSearchState);
    }
    setIsLoading(false);
  };
  const getGamesBundles = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsGames.List_Games_Bundless(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishGamesBundle = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsGames.Publish_Games_Bundles({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.GAMES.BUNDLES,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesBundles(tableSearchState);
    }
    setIsLoading(false);
  };

  ///publish egp
  const publisTvEpgBundle = async (row: any) => {
    setIsLoading(true);
    let responseEpg = await cmsChannels.Publish_Channel_Epg({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.EPG,
      },
      name: '',
      categories: [],
    });
    if (responseEpg && responseEpg.status_code === HttpStatusCodes.OK) {
      // if (responseChannel && responseChannel.status_code === HttpStatusCodes.OK) {
      getChannelBundles(tableSearchState);
      //  }
    }
    setIsLoading(false);
  };

  const deleteBundle = (row: any) => {
    switch (params.type) {
      case 'Albums':
        (async () => await deleteAlbumBundle(row))();
        break;
      case 'Apps':
        (async () => await deleteAppBundle(row))();
        break;
      case 'Cams':
        (async () => await deleteCamBundle(row))();
        break;
      case 'Channels':
        (async () => await deleteChannelBundle(row))();
        break;
      case 'Radios':
        (async () => await deleteRadioBundle(row))();
        break;
      case 'Podcasts':
        (async () => await deletePodcastBundle(row))();
        break;
      case 'Movies':
        (async () => await deleteMovieBundle(row))();
        break;
      case 'Shorts':
        (async () => await deleteShortsBundle(row))();
        break;
      case 'Series':
        (async () => await deleteSeriesBundle(row))();
        break;
      case 'Courses':
        (async () => await deleteCoursesBundle(row))();
        break;
      case 'Games':
        (async () => await deleteGamesBundle(row))();
        break;
    }
  };

  const onSubmitModal = (values: any) => {
    setIsLoading(true);
    setSelectedBundle({
      ...selectedBundle,
      name: values.name,
      upsell: values.upsell,
      ...values,
    });

    var selectedBundle_ = {
      ...selectedBundle,
      name: values.name,
      upsell: values.upsell,
      ...values,
    };

    switch (params.type) {
      case 'Albums':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateAlbumBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addAlbumBundle(values))();
        }
        break;
      case 'Apps':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateAppBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addAppBundle(values))();
        }
        break;
      case 'Cams':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateCamBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addCamBundle(values))();
        }
        break;
      case 'Channels':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateChannelBundle(selectedBundle_, values))();

          //setSelectedBundle(undefined);
        } else {
          (async () => await addChannelBundle(values))();
        }
        break;
      case 'Radios':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateRadioBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addRadioBundle(values))();
        }
        break;
      case 'Movies':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateMovieBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addMovieBundle(values))();
        }
        break;
      case 'Shorts':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateShortsBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addShortsBundle(values))();
        }
        break;
      case 'Series':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateSeriesBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addSeriesBundle(values))();
        }
        break;
      case 'Courses':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateCoursesBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addCoursesBundle(values))();
        }
        break;
      case 'Games':
        if (selectedBundle_._id !== undefined) {
          (async () => await updateGamesBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addGamesBundle(values))();
        }
        break;
      case 'Podcasts':
        if (selectedBundle_._id !== undefined) {
          (async () => await updatePodcastBundle(selectedBundle_, values))();
          //          setSelectedBundle(undefined);
        } else {
          (async () => await addPodcastBundle(values))();
        }
        break;
    }
    setShowModal(false);
  };

  const onSubmitCategories = (package_: any) => {
    setIsLoading(true);
    switch (params.type) {
      case 'Albums':
        (async () => await updateAlbumBundle(package_, package_.name))();
        break;
      case 'Apps':
        (async () => await updateAppBundle(package_, package_.name))();
        break;
      case 'Cams':
        (async () => await updateCamBundle(package_, package_.name))();
        break;
      case 'Channels':
        (async () => await updateChannelBundle(package_, package_.name))();
        break;
      case 'Radios':
        (async () => await updateRadioBundle(package_, package_.name))();
        break;
      case 'Movies':
        (async () => await updateMovieBundle(package_, package_.name))();
        break;
      case 'Shorts':
        (async () => await updateShortsBundle(package_, package_.name))();
        break;
      case 'Series':
        (async () => await updateSeriesBundle(package_, package_.name))();
        break;
      case 'Podcasts':
        (async () => await updatePodcastBundle(package_, package_.name))();
        break;
      case 'Courses':
        (async () => await updateCoursesBundle(package_, package_.name))();
        break;
      case 'Games':
        (async () => await updateGamesBundle(package_, package_.name))();
        break;
    }
    setShowModal(false);
  };
  const onDeleteCategory = (package_: any) => {
    switch (params.type) {
      case 'Albums':
        (async () => await updateAlbumBundle(package_, package_.name))();
        break;
      case 'Apps':
        (async () => await updateAppBundle(package_, package_.name))();
        break;
      case 'Cams':
        (async () => await updateCamBundle(package_, package_.name))();
        break;
      case 'Channels':
        (async () => await updateChannelBundle(package_, package_.name))();
        break;
      case 'Radios':
        (async () => await updateRadioBundle(package_, package_.name))();
        break;
      case 'Movies':
        (async () => await updateMovieBundle(package_, package_.name))();
        break;
      case 'Shorts':
        (async () => await updateShortsBundle(package_, package_.name))();
        break;
      case 'Series':
        (async () => await updateSeriesBundle(package_, package_.name))();
        break;
      case 'Podcasts':
        (async () => await updatePodcastBundle(package_, package_.name))();
        break;
      case 'Courses':
        (async () => await updateCoursesBundle(package_, package_.name))();
        break;
      case 'Games':
        (async () => await updateGamesBundle(package_, package_.name))();
        break;
    }
    setShowModal(false);
  };

  // const refreshList = () => {
  //   switch (params.type) {
  //     case 'Albums':
  //       (async () => await getAlbumBundles(tableSearchState))();
  //       break;
  //     case 'Cams':
  //       (async () => await getCamBundles(tableSearchState))();
  //       break;
  //     case 'Channels':
  //       (async () => await getChannelBundles(tableSearchState))();
  //       break;
  //     case 'Radios':
  //       (async () => await getRadioBundles(tableSearchState))();
  //       break;
  //     case 'EpgTv':
  //       (async () => await getChannelBundles(tableSearchState))();
  //       break;
  //     case 'EpgRadio':
  //       (async () => await getRadioBundles(tableSearchState))();
  //       break;
  //     case 'Movies':
  //       (async () => await getMovieBundles(tableSearchState))();
  //       break;
  //     case 'Shorts':
  //       (async () => await getShortsBundles(tableSearchState))();
  //       break;
  //     case 'Series':
  //       (async () => await getSeriesBundles(tableSearchState))();
  //       break;
  //     case 'Podcasts':
  //       (async () => await getPodcastBundles(tableSearchState))();
  //       break;
  //     case 'Courses':
  //       (async () => await getCoursesBundles(tableSearchState))();
  //       break;
  //   }
  //   setShowModal(false);
  // };

  const publishBundle = (row: any) => {
    switch (params.type) {
      case 'Albums':
        (async () => await publishAlbumBundle(row))();
        break;
      case 'Apps':
        (async () => await publishAppBundle(row))();
        break;
      case 'Cams':
        (async () => await publishCamBundle(row))();
        break;
      case 'Channels':
        (async () => await publishChannelBundle(row))();
        break;
      case 'Radios':
        (async () => await publishRadioBundle(row))();
        break;
      case 'EpgTv':
        (async () => await publisTvEpgBundle(row))();
        break;
      case 'EpgRadio':
        (async () => await publishRadioBundle(row))();
        break;
      case 'Movies':
        (async () => await publishMovieBundle(row))();
        break;
      case 'Shorts':
        (async () => await publishShortsBundle(row))();
        break;
      case 'Series':
        (async () => await publishSeriesBundle(row))();
        break;
      case 'Podcasts':
        (async () => await publishPodcastBundle(row))();
        break;
      case 'Courses':
        (async () => await publishCoursesBundle(row))();
        break;
      case 'Games':
        (async () => await publishGamesBundle(row))();
        break;
    }
    setShowModal(false);
  };

  const publishBundles = async () => {
    setIsLoading(true);
    if (params.type === 'Channels') {
      await cmsChannels.Publish_Channel_All_Epg({
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.EPG,
        },
      });
      //  refreshList();
      setIsLoading(false);
      // then add this to the function that is called for re-rendering
      history.go(0);
    } else if (params.type === 'Radios') {
      await cmsRadios.Publish_Radio_All_Epg({
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.RADIOS.EPG,
        },
      });
      //  refreshList();
      setIsLoading(false);
      // then add this to the function that is called for re-rendering
      history.go(0);
    } else {
      data.forEach(async (item: any) => {
        await publishBundle({ _id: item._id });
      });
      // refreshList();
      setIsLoading(false);
    }
  };

  const packageColumns: IReactBCTableColumn<[]>[] = [
    {
      title: 'Bundle CMS Type',
      dataKey: '_id',
      width: '20%',
      cell: (row: any) => (
        <>
          {(row.external === undefined || row.external === null) && (
            <div>
              <div style={{ alignSelf: 'center' }}>{t('tvms-internal')}</div>
            </div>
          )}
          {(row.external !== undefined || row.external === null) && (
            <div>
              <div style={{ alignSelf: 'center' }}>
                {row.external?.cms_type
                  .match(/([A-Z]?[^A-Z]*)/g)
                  .slice(0, -1)
                  .join(' ')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: t('bundle-name'),
      dataKey: 'name',
      width: '46%',
      cell: (row: any) => <span>{row.name}</span>,
    },
    {
      title: t('publish-date'),
      dataKey: 'publish',
      width: '33%',
      cell: (row: any) => (
        <div>
          <div style={{ alignSelf: 'center' }}>{moment.utc(row.publish).local().format('lll')}</div>
        </div>
      ),
    },
    {
      title: t('actions'),
      dataKey: 'action',
      cell: (row) => (
        <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
          {params.type !== 'EpgTv' && params.type !== 'EpgRadio' && (
            <Tooltip placement="top" title={`Edit`}>
              <Button
                className="mx-2 cursor-pointer"
                onClick={async () => {
                  confirm({
                    title: t('delete-bundle-make-sure-its-not-used-with-your-packages'),
                    okText: t('yes'),
                    okType: 'danger',
                    cancelText: t('no'),
                    onOk() {
                      deleteBundle(row);
                    },
                    onCancel() {},
                  });
                }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon" icon={faTrashCan} />
                <div>{t('delete')}</div>
              </Button>
            </Tooltip>
          )}
          <Tooltip placement="top" title={`Publish Bundle`}>
            <Button className="cursor-pointer" onClick={() => publishBundle(row)} type="text">
              <FontAwesomeIcon className="action-icon" icon={faUpload} />
              <div>{t('publish')}</div>
            </Button>
          </Tooltip>

          {params.type !== 'EpgTv' && params.type !== 'EpgRadio' && (
            <Tooltip placement="top" title={`Edit`}>
              <Button
                className="mx-2 cursor-pointer"
                onClick={() => {
                  setSelectedBundle(row);
                  setShowModal(true);
                }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon" icon={faEdit} />
                <div>{t('edit')}</div>
              </Button>
            </Tooltip>
          )}
        </Col>
      ),
    },
  ];

  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start">
          <span className="ml-3 ml-md-4 "></span>
        </div>
        <div className="col-md-12">
          <div className="row float-right">
            <Button loading={isLoading} disabled={isLoading} className="btn btn-secondary rounded-pill btn-default float-right  height-48 mr-2" onClick={() => publishBundles()}>
              {t('publish-bundles')}
            </Button>
            {params.type !== 'EpgTv' && params.type !== 'EpgRadio' && (
              <Button disabled={isLoading} className="btn btn-secondary rounded-pill btn-default float-right  height-48" onClick={() => setShowModal(!showModal)}>
                {t('add-bundle')}
              </Button>
            )}
          </div>
          {showModal && (
            <BundleModal
              initialData={{
                type: params.type,
                ...selectedBundle,
              }}
              visible={showModal}
              onSubmit={onSubmitModal}
              onCancel={() => setShowModal(!showModal)}
            />
          )}
        </div>
      </div>
      {params.type !== 'EpgTv' && params.type !== 'EpgRadio' && (
        <ReactBCTable
          // onChange={(pageNumber, pageSize, pageSort, filters) => getContent({pageNumber, pageSize, pageSort, filters})}
          isLoading={isLoading}
          totalRecords={pagination.count}
          pageSize={pagination.pageSize}
          currentPage={pagination.pageNumber}
          tableId={'user_interfaces_tables'}
          columns={packageColumns}
          data={data}
          hasChild={true}
          childComponent={(row: any, index) => {
            return <NestedCategories loading={isLoading} package={{ ...data[index] }} deployment={deployment} clientName={clientName} type={params.type} data={row} onSubmit={onSubmitCategories} onDeleteCategory={onDeleteCategory} />;
          }}
        />
      )}
      {(params.type === 'EpgTv' || params.type === 'EpgRadio') && (
        <ReactBCTable
          // onChange={(pageNumber, pageSize, pageSort, filters) => getContent({pageNumber, pageSize, pageSort, filters})}
          isLoading={isLoading}
          totalRecords={pagination.count}
          pageSize={pagination.pageSize}
          currentPage={pagination.pageNumber}
          tableId={'user_interfaces_tables'}
          columns={packageColumns}
          data={data}
          hasChild={false}
        />
      )}
    </div>
  );
};

export default NestedTable;
