import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import THEOplayerWrapper from './TheoPlayerWrapper';
import 'theoplayer/ui.css';
import { useEffectOnce } from 'react-use';
import { getToken } from './PlayerHelpers';
import drmService from '../../../../services/areas/content/drm/drm';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams } from 'react-router-dom';
import * as HttpStatusCodes from 'http-status-codes';

type IUpdateLanguageModalProps = {
  visible: boolean;
  onCancel: () => void;
  initialData: any;
  type: string;
};

const PlayerModal = (props: IUpdateLanguageModalProps) => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const { visible, onCancel, initialData, type } = props;
  const { t } = useTranslation();
  const [source, setSource] = useState([] as any);

  const getStreamType = (url) => {
    var type = 'application/x-mpegURL';
    if (url == undefined || url == '') {
      return type;
    }
    var url_ = url?.split('?');
    var extension = url_[0].split('.').pop();
    if (extension === 'mp4') {
      type = 'video/mp4';
    }
    if (extension === 'mpd') {
      type = 'application/dash+xml';
    }
    if (extension === 'm3u8') {
      type = 'application/x-mpegURL';
    }
    return type;
  };
  const getDrmDetails = async () => {
    let response = await drmService.List_DRM_Settings({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      pageNumber: 0,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      startPlayer(response.data.data);
    }
  };

  useEffectOnce(() => {
    getDrmDetails();
  });

  const startPlayer = async (data) => {
    console.log(data);
    var setDrmConfiguration;
    if (initialData.drm?.supplier == 'buydrm') {
      setDrmConfiguration = {
        integration: 'keyos',
        widevine: {
          licenseAcquisitionURL: initialData.drm?.licenseServer,
          headers: {
            customData: initialData.drm?.headers.customData,
          },
        },
      };
    } else if (initialData.drm?.supplier == 'irdeto') {
      setDrmConfiguration = {
        integration: 'irdeto',
        widevine: {
          certificateURL: initialData.drm?.certificateUrlIrdeto,
          licenseAcquisitionURL: 'https://wv-keyos.licensekeyserver.com/licenseServer/widevine/v1/' + initialData.drm?.accountId + '/license',
        },
        contentId: 'channel1',
      };
    }
    console.log(type);
    switch (type) {
      case 'channels':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.channels.urls.standard_url),
              type: getStreamType(initialData.streams.channels.urls.standard_url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'cams':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.cams.urls.standard_url),
              type: getStreamType(initialData.streams.cams.urls.standard_url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'radio':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.radios.urls.standard_url),
              type: getStreamType(initialData.streams.radios.urls.standard_url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'movies':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.movies.url),
              type: getStreamType(initialData.streams.movies.url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'shorts':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.shorts.url),
              type: getStreamType(initialData.streams.shorts.url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'Season Episodes':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.series.url),
              type: getStreamType(initialData.streams.series.url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'Lessons':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.courses.url),
              type: getStreamType(initialData.streams.courses.url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'Songs':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.albums.url),
              type: getStreamType(initialData.streams.albums.url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'Podcast Episodes':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.podcasts.url),
              type: getStreamType(initialData.streams.podcasts.url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      case 'radios':
        setSource({
          sources: [
            {
              src: await getToken(initialData, data[0]?.token, initialData.streams.radios.url),
              type: getStreamType(initialData.streams.radios.url),
              contentProtection: setDrmConfiguration,
              dash: {
                ignoreAvailabilityWindow: true,
              },
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  const onPlay = useCallback(() => {
    console.log('The player has started playing.');
  }, []);

  return (
    <Modal visible={visible} style={{ border: '2px solid #ebebeb', borderRadius: 10 }} width={760} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-4 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>
              {t('player')}: {initialData.name}
            </h4>
            <p style={{ paddingBottom: 20 }}>{t('player_info_test')}</p>
            <THEOplayerWrapper source={source} onPlay={onPlay} autoplay={true}></THEOplayerWrapper>
          </div>
          <div className="col-md-12 mt-4">
            <p>
              <a style={{ color: '#000000' }} href="https://theoplayer.com">
                {t('theoplayer_info')} Theo Player.
              </a>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PlayerModal;
