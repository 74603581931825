import { Form, Input, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import Placement from '../../Content/Placement/Placement';
import Translations from '../Translations/Translations';
import Surveys from '../Survey/Surveys';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index: any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const [placement, setPlacement] = useState([] as any);
  const [translations, setTranslations] = useState([] as any);
  const [translationsLocal, setTranslationsLocal] = useState([] as any);
  const [showTitle, setShowTitle] = useState(true);
  const [surveys, setSurveys] = useState([] as any);
  const [surveysLocal, setSurveysLocal] = useState([] as any);

  const onSubmitLocal = (values: any) => {
    var translations_ = [] as any;
    translationsLocal.forEach((element: any) => {
      translations_.push({
        language: element.language,
        title: element.title,
      });
    });

    let dataOut = {
      ...data,
      questions: surveysLocal,
      list: {
        show_title: showTitle,
        title: translations_ ?? [],
      },
    };

    onSubmit(dataOut, type, props.index);
  };

  useEffect(() => {
    if (data != undefined) {
      setSurveys(data.questions);
      setSurveysLocal(data.questions);
      form.setFieldsValue(data.content);
    }
  }, [data, form]);

  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-survey-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row" style={{}}>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                        <Surveys setSurveys={setSurveysLocal} data={surveys}></Surveys>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ padding: 15 }}>
                    <Placement data={placement} setPlacement={setPlacement}></Placement>
                  </div>
                  <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                    <h5 className="text-primary">{t('survey-title')}</h5>
                    <p> </p>

                    <div className="row pt-2">
                      <label className="col-md-4 col-form-label">{t('show-title')}</label>

                      <div className="col">
                        <Form.Item className="form-group pb-0 " name={['list', 'show_title']}>
                          <Switch className="ch-switch" checked={showTitle} defaultChecked={showTitle} onChange={() => setShowTitle(!showTitle)} />
                        </Form.Item>
                      </div>
                      <div className="col"></div>
                      <div className="col"></div>
                    </div>

                    <div className="row p-3">
                      <div className="col" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Translations setTranslations={setTranslationsLocal} data={translations}></Translations>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-survey')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
