import { Button, DatePicker, Form, Input, Modal, Switch } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import dayjs from 'dayjs';
type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: any;
};

const ScheduleModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const [isFetchingData] = useState<boolean>(false);
  const { t } = useTranslation();

  const convertedScheduleDateTimesFromUnixToDayjs = (schedule: any): any => {
    if (!schedule) {
      return undefined;
    }
    return {
      ...schedule,
      start: schedule.start ? dayjs.unix(schedule.start) : undefined,
      end: schedule.end ? dayjs.unix(schedule.end) : undefined,
    };
  };

  useEffectOnce(() => {
    const initialFormData = convertedScheduleDateTimesFromUnixToDayjs(initialData);

    form.setFieldsValue(initialFormData);
  });

  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('update-schedule')}</h4>
            {!isFetchingData && (
              <Form form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
                <label className="col-form-label">{t('language')}</label>
                <Form.Item name="country">
                  <Input disabled></Input>
                </Form.Item>
                <label className="col-form-label">{t('start-date')}</label>
                <Form.Item name={'start'} className="form-group pb-0 ">
                  <DatePicker showToday className="form-control height-48 bg-white font-size-14 text-default" />
                </Form.Item>
                <label className="col-form-label">{t('end-date')}</label>
                <Form.Item name={'end'} className="form-group pb-0 ">
                  <DatePicker showToday className="form-control height-48 bg-white font-size-14 text-default" />
                </Form.Item>
                <label className="col-form-label">{t('enable-preview')}</label>
                <Form.Item name="show_preview" className="form-group mb-0">
                  <Switch></Switch>
                </Form.Item>
                <label className="col-form-label">{t('ribbon-preview')}</label>
                <Form.Item name="show_preview_ribbon" className="form-group mb-0">
                  <Switch></Switch>
                </Form.Item>
                <label className="col-form-label">{t('ribbon-new-release')}</label>
                <Form.Item name="show_release_ribbon" className="form-group mb-0">
                  <Switch></Switch>
                </Form.Item>

                <div className="col-md-12 p-0">
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('update-schedule')}
                  </Button>
                </div>
              </Form>
            )}
            {isFetchingData && (
              <div className="row text-center h-100 w-100">
                <div className="col-12 align-self-center">
                  <Spin size="large" spinning={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleModal;
