import React from 'react';
import { FormSection, Input, Item, Table, FormHeading, TextArea, EditButtonWithFormModal, MultipleImageUpload, ImageUploader } from '../../../../common/components/form';
import { Form } from 'antd';
import { BillingContext } from '../../../../contexts/billingContext';
import { noWhiteSpaces, noSpecialCharacters } from '../../../../utils/validators';
import { useTranslation } from 'react-i18next';

export const GeneralFields: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <FormSection title={t('details')}>
      <Item label={t('name')} name="name" rules={[{ required: true, message: t('name-is-required') }]} extra={t('the-product-name')}>
        <Input placeholder={t('name')} />
      </Item>
      <Item label={t('mapping-id')} name="mapping_id" rules={[{ required: false, message: t('mapping-id-is-required') }]} extra={t('a-choosen-id-to-map-this-product-to-your-own-systems')}>
        <Input placeholder={t('mapping-id')} />
      </Item>
      {children}
    </FormSection>
  );
};

export const UpsellFields = ({ initialValues }) => {
  const { t } = useTranslation();
  if (initialValues.type == 'BASE') {
    return (
      <FormSection title={t('in-app-upsell-settings')} subtitle={t('configure-the-text-and-qr-code-hyperlink-to-where-customers-can-upgrade-their-subcription-to-be-displayed-in-the-upsell-modal-dialog-within-the-apps')}>
        <FormHeading>{t('hyperlink')}</FormHeading>
        <Item label={t('hyperlink-0')} name={['upsell', 'url']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: false, message: t('url-is-required') }]}>
          <Input placeholder={'https://urltosubcriptionupgrade'} />
        </Item>
        <FormHeading>{t('promotional-title')}</FormHeading>
        <TitleUpsell />
        <FormHeading>{t('promotional-text')}</FormHeading>
        <DescriptionsUpsell />
        <FormHeading>{t('promotional-image-16-9')}</FormHeading>
        <ImagesUpsell />
      </FormSection>
    );
  } else {
    return <> </>;
  }
};
const DescriptionsUpsell = () => {
  const formInstance = Form.useFormInstance();
  const { service } = React.useContext(BillingContext);
  Form.useWatch(['upsell', 'descriptions']);
  const { t } = useTranslation();

  return (
    <Table
      dataSource={service?.languages.map((language, index) => ({
        key: index,
        code: language.code,
        name: language.name,
        value: formInstance.getFieldValue(['upsell', 'descriptions', index, 'description']),
      }))}
      columns={[
        {
          title: t('language'),
          dataIndex: 'name',
          key: 'language',
          width: '25%',
        },
        {
          title: t('description'),
          key: 'description',
          dataIndex: 'value',
          width: '50%',
          render: (description) => <span>{description || t('add-a-description-for-this-language')}</span>,
        },
        {
          title: t('actions'),
          key: 'actions',
          width: '25%',
          render: ({ key, value, code }: { key: number; code: string; name: string; value: string }) => (
            <>
              <Item hidden key={`language-${key}`} name={['upsell', 'descriptions', key, 'language']} rules={[{ required: false, message: t('language-is-required') }]} initialValue={code} />
              <Item hidden key={`description-${key}`} name={['upsell', 'descriptions', key, 'description']} rules={[{ required: false, message: t('description-is-required') }]} />

              <EditButtonWithFormModal
                heading={t('description')}
                onOk={(form) => {
                  formInstance.setFieldValue(['upsell', 'descriptions', key, 'description'], form.getFieldValue('description'));
                }}
              >
                <Item key={`description-${key}`} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label={t('description')} name="description" rules={[{ required: false, message: t('description-is-required') }]} initialValue={value}>
                  <TextArea placeholder={t('description')} rows={4} />
                </Item>
              </EditButtonWithFormModal>
            </>
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};
const TitleUpsell = () => {
  const formInstance = Form.useFormInstance();
  const { service } = React.useContext(BillingContext);
  Form.useWatch(['external', 'title']);
  const { t } = useTranslation();

  return (
    <Table
      dataSource={service?.languages.map((language, index) => ({
        key: index,
        code: language.code,
        name: language.name,
        value: formInstance.getFieldValue(['external', 'title', index, 'title']),
      }))}
      columns={[
        {
          title: t('language'),
          dataIndex: 'name',
          key: 'language',
          width: '25%',
        },
        {
          title: t('title'),
          key: 'title',
          dataIndex: 'value',
          width: '50%',
          render: (title) => <span>{title || t('add-a-title-for-this-language')}</span>,
        },
        {
          title: t('actions'),
          key: 'actions',
          width: '25%',
          render: ({ key, value, code }: { key: number; code: string; name: string; value: string }) => (
            <>
              <Item hidden key={`language-${key}`} name={['external', 'title', key, 'language']} rules={[{ required: false, message: t('language-is-required') }]} initialValue={code} />
              <Item hidden key={`title-${key}`} name={['external', 'title', key, 'title']} rules={[{ required: false, message: t('title-is-required') }]} />

              <EditButtonWithFormModal
                heading={t('title')}
                onOk={(form) => {
                  formInstance.setFieldValue(['external', 'title', key, 'title'], form.getFieldValue('title'));
                }}
              >
                <Item key={`title-${key}`} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label={t('title')} name="title" rules={[{ required: false, message: t('title-is-required') }]} initialValue={value} extra={t('add-a-translated-description-to-your-product')}>
                  <TextArea placeholder={t('title')} rows={4} />
                </Item>
              </EditButtonWithFormModal>
            </>
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};
const ImagesUpsell = () => {
  const formInstance = Form.useFormInstance();
  const image = formInstance.getFieldValue(['external', 'image']) || [];
  return <ImageUploader itemName={['external', 'image']} {...{ image }} />;
};

export const ExternalFields = () => {
  const { t } = useTranslation();
  return (
    <FormSection title={t('cart-setup')} subtitle={t('create-custom-translated-names-slugs-and-descriptions-for-your-product-to-be-used-shopping-cart-and-or-customer-portal')}>
      <FormHeading>{t('names')}</FormHeading>
      <Names />
      <FormHeading>{t('descriptions')}</FormHeading>
      <Descriptions />
      <FormHeading subtitle={t('images-will-be-shown-in-your-shopping-cart-and-can-be-used-on-your-own-platforms')}>{t('images')}</FormHeading>
      <Images />
    </FormSection>
  );
};

const Images = () => {
  const formInstance = Form.useFormInstance();
  const images = formInstance.getFieldValue(['external', 'images']) || [];

  return <MultipleImageUpload itemName={['external', 'images']} {...{ images }} />;
};

const Names = () => {
  const { service } = React.useContext(BillingContext);
  const formInstance = Form.useFormInstance();
  const { t } = useTranslation();
  Form.useWatch(['external', 'names']);

  return (
    <Table
      dataSource={service?.languages.map((language, index) => ({
        key: index,
        code: language.code,
        name: language.name,
        value: formInstance.getFieldValue(['external', 'names', index, 'name']),
      }))}
      columns={[
        {
          title: t('language'),
          dataIndex: 'name',
          key: 'language',
          width: '25%',
        },
        {
          title: t('name'),
          key: 'name',
          dataIndex: 'value',
          width: '50%',
          render: (name) => <span>{name || t('add-a-name-for-this-language')}</span>,
        },
        {
          title: t('actions'),
          key: 'actions',
          width: '25%',
          render: ({ key, value, code }: { key: number; code: string; name: string; value: string }) => (
            <>
              <Item hidden key={`language-${key}`} name={['external', 'names', key, 'language']} rules={[{ required: false, message: t('language-is-required') }]} initialValue={code} />
              <Item hidden key={`name-${key}`} name={['external', 'names', key, 'name']} rules={[{ required: false, message: t('name-is-required') }]} />

              <EditButtonWithFormModal
                heading={t('name')}
                onOk={(form) => {
                  formInstance.setFieldValue(['external', 'names', key, 'name'], form.getFieldValue('name'));
                }}
              >
                <Item label={t('name')} name="name" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} rules={[{ required: false, message: t('name-is-required') }]} initialValue={value} extra={t('add-a-translated-name-for-your-product')}>
                  <Input placeholder={t('name')} />
                </Item>
              </EditButtonWithFormModal>
            </>
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};
const Descriptions = () => {
  const formInstance = Form.useFormInstance();
  const { service } = React.useContext(BillingContext);
  Form.useWatch(['external', 'descriptions']);
  const { t } = useTranslation();

  return (
    <Table
      dataSource={service?.languages.map((language, index) => ({
        key: index,
        code: language.code,
        name: language.name,
        value: formInstance.getFieldValue(['external', 'descriptions', index, 'description']),
      }))}
      columns={[
        {
          title: t('language'),
          dataIndex: 'name',
          key: 'language',
          width: '25%',
        },
        {
          title: t('description'),
          key: 'description',
          dataIndex: 'value',
          width: '50%',
          render: (description) => <span>{description || t('add-a-description-for-this-language')}</span>,
        },
        {
          title: t('actions'),
          key: 'actions',
          width: '25%',
          render: ({ key, value, code }: { key: number; code: string; name: string; value: string }) => (
            <>
              <Item hidden key={`language-${key}`} name={['external', 'descriptions', key, 'language']} rules={[{ required: false, message: t('language-is-required') }]} initialValue={code} />
              <Item hidden key={`description-${key}`} name={['external', 'descriptions', key, 'description']} rules={[{ required: false, message: t('description-is-required') }]} />

              <EditButtonWithFormModal
                heading={t('description')}
                onOk={(form) => {
                  formInstance.setFieldValue(['external', 'descriptions', key, 'description'], form.getFieldValue('description'));
                }}
              >
                <Item key={`description-${key}`} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label={t('description')} name="description" rules={[{ required: false, message: t('description-is-required') }]} initialValue={value} extra={t('add-a-translated-description-to-your-product')}>
                  <TextArea placeholder={t('description')} rows={4} />
                </Item>
              </EditButtonWithFormModal>
            </>
          ),
        },
      ]}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};
