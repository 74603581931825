import { ChartInput } from "@mwaretv/database/build/backend/util/queryGenerator";
import axios from "axios";
import { t } from "i18next";
import { formatChartInput } from "@mwaretv/database/build/backend/apexChartConfig/apexChartConfig";

class ChartInputs_Service {

  Get_ChartInputs = async (clientName: string, deploymentName: string, serviceName: string, area: string): Promise<ChartInput[]> => {

    const jsonFileName = `dashboard_${area}_input.json`;

    let path: string;
  
    if (area === "content" || area === "apps") {
  
      path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deploymentName + '/dashboards/' + jsonFileName;
  
    }
    else {
  
      path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deploymentName + '/' + serviceName + '/dashboards/' + jsonFileName;
  
    }
  
    let { data } = await axios.get(path);

    data?.forEach(chartInput => {
      chartInput.series?.forEach(series => {
        formatChartInput(series, t('week'), t('empty'));
      });
    });

    data = data.sort((a, b) => {
      if (a?.order == null && b?.order == null) return 0;
      if (a?.order == null) return 1;
      if (b?.order == null) return -1;
      return a?.order - b?.order;
    });

    return data;

  };

}

export default new ChartInputs_Service();
