import { Button, Form, Input, notification, Rate, Select, Switch } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cmsService from '../../../../../../../services/areas/content/channels/channels';
import { IMSNameParams } from '../../../../../../dashboard';
import ParentalControl from '../../../../../../../common/components/Content/Parental/Parental';
import { Channels } from '../../../../../../../interfaces/forms/areas/content/channels/channels';
import Placement from '../../../../../../../common/components/Content/Placement/Placement';
import Tags from '../../../../../../../common/components/Content/Tags/Tags';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import Providers from '../../../../../../../common/components/Content/Providers/Providers';
import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../../constants/TVMSRoles';
import Share from '../../../../../../../common/components/Content/Share/Share';
import moment from 'moment';
import ReleaseSchedules from '../../../../../../../common/components/Content/ReleaseSchedules/ReleaseSchedules';

const AddNewMusicAlbum = (data: any) => {
  const params = useParams<IMSNameParams & { deployment: string }>();
  const [isAdding, setIsAdding] = useState(false);

  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const [schedules, setSchedules] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const { deployment } = useContext(ContentContext);
  const [provider, setProvider] = useState('');
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;
  const [share, setShare] = useState(false);

  useEffect(() => {
    setSchedules(data.data.releaseschedule);
    setTags(data.data.tags);
    setProvider(data.data.provider?.name);
    setShare(data.data.share ?? false);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: Channels) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    console.log(values);
    values = {
      _id: params._id,
      ...data.data,
      ...values,
      parental: {
        ...values.parental,
      },
      placement: {
        ut_start: String(moment().add(-99, 'year').unix()),
        ut_end: String(moment().add(99, 'year').unix()),
        no_play_on_trial: false,
      },
      releaseschedule: schedules,
      share: share,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      tags: tags,
      query_params: {
        instance: clientName,
        cms: params.deployment ?? '',
      },
    };
    console.log(values);
    let response = await cmsService.Update_Channel(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-3 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-5"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('channel-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('number')}</label>
                <Form.Item className="col-md-5" name="number">
                  <Input defaultValue={0} type={'number'} className="form-control bg-white font-size-14 text-default p-3rem" placeholder="101" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">{t('notes')}</label>
                <Form.Item className="col-md-5" name="meta">
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('use-this-to-add-notes')} />
                </Form.Item>
              </div>
            </div>
            {!isProvider && <Providers data={provider} setProvider={setProvider} />}
            <ParentalControl />
            <ReleaseSchedules setSchedules={setSchedules} data={schedules} />
            <Share data={share} setShare={setShare} />
            <Tags data={tags} setTags={setTags} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-channel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
