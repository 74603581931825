import { Route, Switch, useRouteMatch } from 'react-router';
import SmsCampaignsList from './sms-campaigns/list';

const SmsCampaignsIndex = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route path={`${path}/details/:_id`}>
        <SmsCampaignDetails />
      </Route>
      <Route path={`${path}/add`}>
        <SmsCampaignAdd />
      </Route> */}
      <Route path={`${path}/`}>
        <SmsCampaignsList />
      </Route>
    </Switch>
  );
};

export default SmsCampaignsIndex;
