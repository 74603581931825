'use client';

import React, { useCallback, useEffect, useState } from 'react';
import type { Player, PlayerConfiguration, PreloadType, SourceDescription } from 'theoplayer';
import * as THEOplayer from 'theoplayer';

export interface THEOplayerWrapperProps {
  preload?: PreloadType;
  autoplay?: boolean;
  source: SourceDescription;
  onPlay?: () => void;
}

function THEOplayerWrapper({ preload, autoplay, source, onPlay }: THEOplayerWrapperProps) {
  const theoLicense = 'sZP7IYe6T6fL3QfL0K3KImk63letFSx13oa-ClhLIOkgCSglTS313K0zIDf6FOPlUY3zWokgbgjNIOf9flPe3uRL3ofZFDC_3lB-3QatCZzrIQfZFSeLCKhk3lbo3oBcImfVfK4_bQgZCYxNWoryIQXzImf90Sbz0Lac3Sfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3L5t0Sak0LeZ3u5zFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya';
  const [player, setPlayerEl] = usePlayer({
    license: theoLicense,
    libraryLocation: '../../../../../../../node_modules/theoplayer',
  });

  useEffect(() => {
    if (!player) return;
    player.preload = preload ?? 'none';
  }, [player, preload]);

  useEffect(() => {
    if (!player) return;
    player.autoplay = autoplay ?? false;
  }, [player, autoplay]);

  useEffect(() => {
    if (!player) return;
    player.source = source;
  }, [player, source]);

  useEffect(() => {
    if (!player || !onPlay) return;
    player.addEventListener('play', onPlay);
    return () => {
      player.removeEventListener('play', onPlay);
    };
  }, [player, onPlay]);

  return (
    <div style={{ backgroundColor: 'black', padding: 5, borderRadius: 5 }}>
      <div className={'video-js theoplayer-skin vjs-16-9'} ref={setPlayerEl} />
    </div>
  );
}

function usePlayer(configuration: PlayerConfiguration): [Player | null, (el: HTMLElement | null) => void] {
  const [player, setPlayer] = useState<Player | null>(null);
  const setPlayerEl = useCallback((el: HTMLElement | null) => {
    if (player !== null) {
      player.destroy();
      setPlayer(null);
    }
    if (el !== null) {
      const player = new THEOplayer.Player(el, configuration);
      setPlayer(player);
    }
  }, []);
  return [player, setPlayerEl];
}

export default THEOplayerWrapper;
