import { Form, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import providerService from '../../../../services/areas/content/provider/provider/index';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../pages/dashboard';
import * as HttpStatusCodes from 'http-status-codes';

const Security_ = (params: { setProvider: any; data?: any }) => {
  let [form] = useForm();
  const [providers, setProviders] = useState([] as any);
  const [provider, setProvider] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    if (params.data != undefined) {
      setProvider(params.data);
    }
    getProviders();
  }, [params?.data]);

  const setProviderLocal = (value: any) => {
    setProvider(value);
    params.setProvider(value);
  };

  const getProviders = async () => {
    let response = await providerService.List_Provider({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var provider = [] as any;
      response.data.data.forEach((element) => {
        provider.push(element.name);
      });
      setProviders(provider);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('provider')}</h4>

      <div className="col-md-12 card-bg-light p-5">
        <div className="col">
          <div className="row">
            <label className="col-md-3 col-form-label">{t('provider')}</label>
            <div className="col-md-5">
              <Select style={{ width: '100%' }} defaultValue={provider} loading={isLoading} value={provider} onChange={(value) => setProviderLocal(value)} placeholder={t('select-provider')}>
                <Select.Option key={'No Provider'} value={t('no-provider')}>
                  {t('no-provider')}
                </Select.Option>
                {providers.map((provider) => {
                  return (
                    <Select.Option key={provider} value={provider}>
                      {provider}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security_;
