export function checkImageRatio(file: any, ratio: any) {
  console.log(ratio);
  return new Promise((resolve) => {
    const image = new Image();
    let fr = new FileReader();
    fr.onload = function () {
      if (fr !== null && typeof fr.result == 'string') {
        image.src = fr.result;
      }
    };
    fr.readAsDataURL(file);
    image.onload = async function () {
      const width = image.width;
      const height = image.height;
      var imageRatio = width / height;
      var ratioBool = false;
      var needsResizing = false;
      if (imageRatio == ratio) {
        ratioBool = true;
      } else if (imageRatio > ratio - 0.04 && imageRatio < ratio + 0.04) {
        ratioBool = true;
        needsResizing = true;
      }
      resolve({
        status: ratioBool,
        width: image.width,
        height: image.height,
        resize: needsResizing,
      });
    };
  });
}
export function getImageSizes(file: any) {
  return new Promise((resolve) => {
    const image = new Image();
    let fr = new FileReader();
    fr.onload = function () {
      if (fr !== null && typeof fr.result == 'string') {
        image.src = fr.result;
      }
    };
    fr.readAsDataURL(file);
    image.onload = async function () {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
  });
}
// export const checkImageSize = (height: any, width: any, type: any): any => {
//   switch (type) {
//     case 'freeform':
//       return {
//         size: true,
//         error: false,
//       };
//     case 'landscape':
//       if (width == 1920 && height == 1080) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 1920 && height > 1080) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'portrait':
//       if (width == 1080 && height == 1920) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 1080 && height > 1920) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'brandlogo':
//       if (width == 200 && height == 50) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 200 && height > 50) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'brandicon':
//       if (width == 200 && height == 200) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 200 && height > 200) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'brandfavicon':
//       if (width == 50 && height == 50) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 50 && height > 50) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'background':
//       if (width == 1920 && height == 1080) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 1920 && height > 1080) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'widescreen':
//       if (width == 640 && height == 360) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 640 && height > 360) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//       case 'banner':
//         if (width == 1200 && height == 400) {
//           return {
//             size: true,
//             error: false,
//           };
//         } else {
//           if (width > 1200 && height > 400) {
//             return {
//               size: false,
//               error: false,
//             };
//           } else {
//             return {
//               size: false,
//               error: true,
//             };
//           }
//         }
//     case 'tile':
//       if (width == 384 && height == 216) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 384 && height > 216) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'square':
//       if (width == 300 && height == 300) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 300 && height > 300) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'poster':
//       if (width == 340 && height == 500) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 340 && height > 500) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'logo':
//       if (width == 300 && height == 100) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 300 && height > 100) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'portrait_wide_logo':
//       if (width == 1080 && height == 100) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 1080 && height > 100) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//     case 'landscape_wide_logo':
//       if (width == 1920 && height == 75) {
//         return {
//           size: true,
//           error: false,
//         };
//       } else {
//         if (width > 1920 && height > 75) {
//           return {
//             size: false,
//             error: false,
//           };
//         } else {
//           return {
//             size: false,
//             error: true,
//           };
//         }
//       }
//   }
// };
export const getCorrectSizing = (type: any) => {
  switch (type) {
    case 'freeform':
      return {
        width: 0,
        height: 0,
      };
    case 'landscape':
      return {
        width: 1920,
        height: 1080,
      };
    case 'app_banner':
      return {
        width: 960,
        height: 168,
      };
    case 'portrait':
      return {
        width: 1080,
        height: 1920,
      };
    case 'brandlogo':
      return {
        width: 200,
        height: 50,
      };
    case 'brandicon':
      return {
        width: 200,
        height: 200,
      };
    case 'brandfavicon':
      return {
        width: 50,
        height: 50,
      };
    case 'background':
      return {
        width: 1920,
        height: 1080,
      };
    case 'widescreen':
      return {
        width: 640,
        height: 360,
      };
    case 'banner':
      return {
        width: 1200,
        height: 400,
      };
    case 'tile':
      return {
        width: 384,
        height: 216,
      };
    case 'square':
      return {
        width: 300,
        height: 300,
      };
    case 'poster':
      return {
        width: 340,
        height: 500,
      };
    case 'logo':
      return {
        width: 300,
        height: 100,
      };
    case 'portrait_wide_logo':
      return {
        width: 1080,
        height: 100,
      };
    case 'landscape_wide_logo':
      return {
        width: 1920,
        height: 75,
      };
  }
};
// export function resizeImage(file: File, type: any, format: any) {
//   var sizing = getCorrectSizing(type);
//   return new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       sizing?.width ?? 0,
//       sizing?.height ?? 0,
//       format,
//       100,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       'base64'
//     );
//   });
// }
