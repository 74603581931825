import { Button, Form, Input, Select, Switch, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { CustomNotification } from '../../../../../common/components/Notification';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { STORAGE_ENDPOINTS } from '../../../../../services/storage_paths';
import { IMSNameParams } from '../../../../dashboard';
import ImageUploader from '../../../../../common/components/ImageUploader/ImageUploader';
import { useEffectOnce } from 'react-use';
import { IABP_Lists } from '../../../../../interfaces/forms/common/IABP_Lists';
import { ErrorModal } from '../../../../../common/components/ErrorModal/ErrorModal';
import * as ShoppingCartService from '../../../../../services/areas/billing/shoppingCartService';

const AddApp = () => {
  let history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(AppBuilderContext);
  const [form] = useForm();
  const { t } = useTranslation();
  const [landscape, setLanscape] = useState([] as any);
  const [portrait, setPortrait] = useState([] as any);
  const [logo, setLogo] = useState([] as any);
  const [nested, setNested] = useState([] as any);
  const [icon, setIcon] = useState([] as any);
  const [favicon, setFavicon] = useState([] as any);
  const [selectedService, setSelectedService] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [allServices, setAllServices] = useState([] as any);
  const [packages, setPackages] = useState([] as any);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const packageid = Form.useWatch('Package', form);

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    let test = checkPackageId(values.Package);
    if (test == false) {
      CustomNotification({ error: t('this-package-id-already-exists') });
    } else {
      let response = await appService.Add_Application({
        query_params: {
          cms: deployment?.name ?? '',
          instance: clientName,
          path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', '').APPBUILDER.IMAGES,
        },
        deployment: deployment?.name ?? '',
        client: clientName,
        deleted: false,
        tp: 'sZP7IYe6T6Pg3Sbi0lhL06k1Tu5iFSaZIlf-ClA6IOkg0S5r0lho0lUeClb6FOPlUY3zWokgbgjNIOf9flPe3uRL3ofZFDC_3lB-3QatCZzrIQfZFSeLCKhk3lbo3oBcImfVfK4_bQgZCYxNWoryIQXzImf90Sbc0Sbi3lai0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lRc3L0Z0Lbk0SezFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikf6i6CDrebKjNIOfVfKXpIwPqdDxzU6fVfKINbK4zU6fVfKgqbZfVfGxNsK4pf6i6UwIqbZfVfGUgCKjLfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya',
        branding: {
          enabled: enabled,
          name: values.Name,
          nested_name: values.Nested_Name,
          packageid: values.Package,
          deployment: deployment?.name ?? '',
          service: selectedService.name,
          appid: '',
          background_images: {
            portrait: portrait.url == undefined ? portrait : portrait.url,
            landscape: landscape.url == undefined ? landscape : landscape.url,
          },
          logos: {
            logo: logo?.url == undefined ? logo : logo.url,
            icon: icon.url == undefined ? icon : icon.url,
            favicon: favicon.url == undefined ? favicon : favicon.url,
            nested: nested.url == undefined ? undefined : nested.url,
          },
          dbs: {
            dep: {
              hostname: deployment?.storage?.hostname ?? '',
              region: deployment?.storage?.region ?? '',
              label: deployment?.storage?.label ?? '',
              key: deployment?.storage?.accessKey + '[-]' + deployment?.storage?.secretAccessKey,
              hosting: deployment?.storage?.hosting ?? '',
            },
            ser: {
              hostname: selectedService?.storage?.hostname ?? '',
              region: selectedService?.storage?.region ?? '',
              label: selectedService?.storage?.label ?? '',
              key: selectedService?.storage?.accessKey + '[-]' + selectedService?.storage?.secretAccessKey,
              hosting: selectedService?.storage?.hosting ?? '',
            },
          },
        },
        screens: [{ type: 'Home' }],
      });
      if (response && response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: t('app-added-successfully'),
        });
        var data = response.data as any;
        history.push(redirectUrl + '/applications/branding/' + data._id);
      } else CustomNotification({ error: response.errors });
    }
    setIsLoading(false);
  };
  const checkPackageId = (packageId) => {
    var test = packages.find((p) => p.name == packageId);
    if (test == undefined) {
      return true;
    } else {
      return false;
    }
  };
  const loadServices = () => {
    setAllServices(deployment?.services);
  };
  const setServiceSelection = async (service) => {
    setIsLoading(true);
    console.log(service);
    console.log(allServices);
    var serviceOut = allServices.find((s) => s.name === service) as any;
    console.log(serviceOut);
    setSelectedService(serviceOut);
    setIsLoading(false);
  };

  const [tableSearchState, setTableSearchState] = useState<IABP_Lists>({
    pageNumber: 1,
    pageSize: 10,
    query_params: {
      cms: deployment?.name ?? '',
      instance: clientName,
    },
  });
  const loadPackages = async () => {
    let response = await appService.List_Application_Dirs(tableSearchState);
    if (response && response.status_code === HttpStatusCodes.OK) {
      if (response.data.data) {
        setPackages(response.data.data);
      }
    }
  };
  useEffectOnce(() => {
    loadPackages();
    loadServices();
  });

  const onBlurCheckPackageId = () => {
    var test = checkPackageId(packageid);
    if (test == false) {
      alert(t('this-package-id-has-been-taken-already-please-choose-another-one'));
    }
  };

  return (
    <div className="container-fluid">
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={async (values) => {
          await handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card">
          <div className="card-body p-5">
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12 mb-2">
                  <h2 className="page-title mt-4 mb-5">{t('add-app')}</h2>
                </div>
                <div className="card-bg-light p-5 mb-4">
                  <h4 className="text-primary mb-2">App Status</h4>
                  <p>Toggle this switch to enable or disable the app for your customers.</p>
                  <div className="row">
                    <label className="col-md-2 col-form-label">Status</label>
                    <Form.Item className="col-md-5" name={['branding', 'enabled']} valuePropName="checked">
                      <Switch className="ch-switch" checked={enabled} defaultChecked={false} onChange={() => setEnabled(!enabled)} />
                    </Form.Item>
                  </div>
                </div>
                <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                  <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                    {t('app-name')}
                  </h4>
                  <p>{t('this-is-used-for-building-your-apps-and-will-be-shown-under-the-app-icon-on-your-clients-devices')}</p>
                  <div className="row">
                    <label className="col-md-2 col-form-label">{t('name')}</label>
                    <Form.Item
                      className="col-md-5"
                      rules={[
                        {
                          required: true,
                          message: t('app-name-is-required'),
                        },
                      ]}
                      name="Name"
                    >
                      <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
                    </Form.Item>
                  </div>
                </div>
                <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                  <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                    Nested App Name
                  </h4>
                  <p>{t('this-is-used-for-building-your-apps-and-will-be-shown-under-the-app-icon-on-your-clients-devices')}</p>
                  <div className="row">
                    <label className="col-md-2 col-form-label">{t('name')}</label>
                    <Form.Item className="col-md-5" name="Nested_Name">
                      <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name')} />
                    </Form.Item>
                  </div>
                  <div className="row-md-12">
                    <ImageUploader
                      onSubmit={(values: any) => {
                        setNested(values);
                      }}
                      initialData={{
                        client: clientName,
                        deployment: deployment,
                        service: undefined,
                        uploadedImage: nested,
                        uploadName: 'Nested Image',
                        uploadType: 'tile',
                        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                      }}
                    />
                  </div>
                </div>

                <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                  <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                    {t('package-identifier')}
                  </h4>
                  <p>{t('this-is-used-to-build-your-apps-with-and-acts-like-a-global-identifier-troughout-the-whole-system')}</p>
                  <p>{t('the-package-id-is-always-setup-in-this-format-com-yourbrandname-app')}</p>
                  <div className="row">
                    <label className="col-md-2 col-form-label">{t('package-id')}</label>
                    <Form.Item
                      className="col-md-5"
                      rules={[
                        {
                          required: true,
                          message: t('package-id-is-required'),
                        },
                        {
                          pattern: /\w{1,}\.\w{1,}\.\w{1,}/gm,
                          message: t('please-enter-a-valid-package-id'),
                        },
                      ]}
                      name="Package"
                    >
                      <Input onBlur={() => onBlurCheckPackageId()} className="form-control bg-white font-size-14 text-default" placeholder={t('for-example-com-yourapp-app')} />
                    </Form.Item>
                  </div>
                </div>
                <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                  <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                    {t('linked-service')}
                  </h4>
                  <p>{t('select-a-service-to-link-this-app-to-for-user-authenication-shopping-cart-selections-and-general-routing-options')}</p>
                  <div className="row">
                    <label className="col-md-2 col-form-label">{t('service-selection')}</label>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: t('service-selection-is-required'),
                        },
                      ]}
                      className="col-md-5"
                      name={['Service']}
                    >
                      <Select defaultValue={selectedService} onChange={setServiceSelection} className="default-select" placeholder={t('select-service')}>
                        {allServices.map((service) => {
                          return (
                            <Select.Option value={service.name} key={service.name}>
                              {service.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {/* <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                  <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                    {t('app-font')}
                  </h4>
                  <div className="row">
                    <label className="col-md-2 col-form-label">{t('font-selection')}</label>
                    <Form.Item
                      className="col-md-5"
                      rules={[
                        {
                          required: true,
                          message: t('app-name-is-required'),
                        },
                      ]}
                      name={['Font']}
                    >
                      <Select className="default-select" placeholder={t('select-type')}>
                        <Select.Option value={'Helvetica'} key={'Helvetica'}>
                          Helvetica
                        </Select.Option>
                        <Select.Option value={'Abel-Regular'} key={'Abel-Regular'}>
                          Abel
                        </Select.Option>
                        <Select.Option value={'Lato-Regular'} key={'Lato-Regular'}>
                          Lato
                        </Select.Option>
                        <Select.Option value={'Montserrat-Regular'} key={'Montserrat-Regular'}>
                          Montserrat
                        </Select.Option>
                        <Select.Option value={'OpenSans-Regular'} key={'OpenSans-Regular'}>
                          Open Sans
                        </Select.Option>
                        <Select.Option value={'Oswald-Regular'} key={'Oswald-Regular'}>
                          Oswald
                        </Select.Option>
                        <Select.Option value={'RobotoCondensed-Regular'} key={'RobotoCondensed-Regular'}>
                          Roboto
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div> */}

                <div className="row-md-12">
                  <ImageUploader
                    onSubmit={(values: any) => {
                      setLanscape(values);
                    }}
                    initialData={{
                      client: clientName,
                      deployment: deployment,
                      service: undefined,
                      uploadName: 'App Landscape Background Image',
                      uploadType: 'landscape',
                      path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                    }}
                  />
                </div>
                <div className="row-md-12">
                  <ImageUploader
                    onSubmit={(values: any) => {
                      setPortrait(values);
                    }}
                    initialData={{
                      client: clientName,
                      deployment: deployment,
                      service: undefined,
                      uploadName: 'App Portrait Background Image',
                      uploadType: 'portrait',
                      path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                    }}
                  />
                </div>

                <div className="row-md-12">
                  <ImageUploader
                    onSubmit={(values: any) => {
                      setLogo(values);
                    }}
                    initialData={{
                      client: clientName,
                      deployment: deployment,
                      service: undefined,
                      uploadName: 'Brand Logo',
                      uploadType: 'brandlogo',
                      path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                    }}
                  />
                </div>
                <div className="row-md-12">
                  <ImageUploader
                    onSubmit={(values: any) => {
                      setIcon(values);
                    }}
                    initialData={{
                      client: clientName,
                      deployment: deployment,
                      service: undefined,
                      uploadName: 'Brand Icon',
                      uploadType: 'brandicon',
                      path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                    }}
                  />
                </div>
                <div className="row-md-12">
                  <ImageUploader
                    onSubmit={(values: any) => {
                      setFavicon(values);
                    }}
                    initialData={{
                      client: clientName,
                      deployment: deployment,
                      service: undefined,
                      uploadName: 'Brand Favicon',
                      uploadType: 'brandfavicon',
                      path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button loading={isLoading} htmlType="submit" className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-app')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddApp;
