import { Button, Card, Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { FormHeading, Input, Item, TextArea } from "../../../../../common/components/form";
import { useContext, useState } from "react";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined, QuestionOutlined } from "@ant-design/icons";
import { ChartDesign } from "@mwaretv/database/build/backend/models/reporting/chartdesigns/chartdesign";
import { useParams } from "react-router";
import { ReportingContext } from "../../../../../contexts/reportingContext";
import { useEmailGateways } from "../../../../../services/areas/billing/emailGatewayService";

export const ReportDesignFields = ({ availableCharts }: { availableCharts: ChartDesign[] }) => {
  const [showHelp, setShowHelp] = useState(false);
  const {t} = useTranslation();
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(ReportingContext);
  const { data: emailGateways } = useEmailGateways({ params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', page: 1, limit: 9999 } });
  const emailGatewayOptions = emailGateways?.data.docs.map<{ label: string; value: string | undefined | null }>((x) => ({ label: x.name, value: x._id }));
  emailGatewayOptions?.splice(0, 0, {
    label: t('no-gateway'),
    value: null,
  });

  return (
    <>
      <Item hidden name={['_id']} />
      <Card>
        <div className="row">
          <div className="col-md-11">
            <FormHeading>{t('chart-design-report-basics')}</FormHeading>
          </div>
          <div className="col-md-1">
            <FormHeading>
              <Button className="btn-primary rounded-pill" icon={<QuestionOutlined />} onClick={() => setShowHelp(!showHelp)} block />
            </FormHeading>
          </div>
        </div>
        <div className="row">
          <Item name={['name']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-report-name')} rules={[{ required: true, message: t('chart-design-report-name-is-required')}]}>
            <Input />
          </Item>
        </div>
        <div className="row">
          <Item name={['fileName']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-report-filename')} rules={[{ required: true, message: t('chart-design-report-filename-is-required')}, { validator: (_, value) => { 
            if ((value as string)?.endsWith(".pdf")) {
              return Promise.resolve();
            } else {
              return Promise.reject(t('chart-design-report-filename-must-end-in-pdf'));
            }
          }, message: t('chart-design-report-filename-must-end-in-pdf') }]}>
            <Input />
          </Item>
        </div>
      </Card>
      <Card>
        <div className="row">
          <div className="col-md-11">
            <FormHeading>{t('chart-design-report-charts')}</FormHeading>
          </div>
          <div className="col-md-1">
            <FormHeading>
              <Button className="btn-primary rounded-pill" icon={<QuestionOutlined />} onClick={() => setShowHelp(!showHelp)} block />
            </FormHeading>
          </div>
        </div>
        <Form.List name={'pages'}>
          {(pages, { add, remove, move }) => (
            <>
            {(pages?.length ?? 0) === 0 &&
              <div className="row">
                <div className="col-24-md-1">
                  <label>&nbsp;</label>
                  <Button style={{ textAlign: "center" }} type="default" size="large" icon={<PlusOutlined />} onClick={() => add()} block />
                </div>
              </div>
            }
            {(pages?.length ?? 0) > 0 &&
              <>
              {pages.map((page, index, array) => (
                <div className="row">
                  <div className="col-24-md-1">
                    <label>&nbsp;</label>
                    <Button style={{ textAlign: "center" }} type="default" size="large" icon={<ArrowUpOutlined />} onClick={() => move(index, index - 1)} block />
                  </div>
                  <div className="col-24-md-1">
                    <label>&nbsp;</label>
                    <Button style={{ textAlign: "center" }} type="default" size="large" icon={<ArrowDownOutlined />} onClick={() => move(index, index + 1)} block />
                  </div>
                  <Item name={[page.name, 'chartId']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-chart-name')} rules={[{ required: true, message: t('chart-design-chart-name-is-required') }]}>
                    <Select className="default-select" placeholder={t('chart-design-select-chart')}>
                      {availableCharts.map((chart) => {
                        return (
                          <Select.Option value={chart._id} key={chart._id}>
                            {chart.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Item>
                  <div className="col-24-md-1">
                    <label>&nbsp;</label>
                    <Button style={{ textAlign: "center" }} type="default" size="large" icon={<DeleteOutlined />} onClick={() => remove(page.name)} block />
                  </div>
                  {index === (array.length - 1) &&
                    <div className="col-24-md-1">
                      <label>&nbsp;</label>
                      <Button style={{ textAlign: "center" }} type="default" size="large" icon={<PlusOutlined />} onClick={() => add()} block />
                    </div>
                  }
                </div>
              ))}
              </>
            }
            </>
          )}
        </Form.List>
      </Card>
      <Card>
        <div className="row">
          <div className="col-md-11">
            <FormHeading>{t('chart-design-report-email-info')}</FormHeading>
          </div>
        </div>
        <div className="row">
          <Item name={['emailInfo', 'gateway']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('gateway-for-sending-emails')} rules={[{ required: true, message: t('email-gateway-is-required')}]}>
            <Select options={emailGatewayOptions} />
          </Item>
        </div>
        <div className="row">
          <Item name={['emailInfo', 'subject']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('subject')} rules={[{ required: true, message: t('subject-is-required')}]}>
            <Input />
          </Item>
        </div>
        <div className="row">
          <Item name={['emailInfo', 'body']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('email-body')} rules={[{ required: true, message: t('email-body-is-required')}]}>
            <TextArea rows={4} />
          </Item>
        </div>
      </Card>
      <Card>
        <div className="row">
          <div className="col-md-11">
            <FormHeading>{t('chart-design-report-recipients')}</FormHeading>
          </div>
        </div>
        <Form.List name={['emailInfo', 'recipients']}>
          {(recipients, { add, remove }) => (
            <>
            {(recipients?.length ?? 0) === 0 &&
              <div className="row">
                <div className="col-24-md-1">
                  <label>&nbsp;</label>
                  <Button style={{ textAlign: "center" }} type="default" size="large" icon={<PlusOutlined />} onClick={() => add()} block />
                </div>
              </div>
            }
            {(recipients?.length ?? 0) > 0 &&
              <>
              {recipients.map((recipient, index, array) => (
                <div className="row">
                  <Item name={[recipient.name, 'emailAddress']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('email-address')} rules={[{ required: true, message: t('email-address-is-required') }]}>
                    <Input />
                  </Item>
                  <Item name={[recipient.name, 'method']} className="col-24-md-3" labelCol={{ span: 24 }} label={t('method')} rules={[{ required: true, message: t('method-is-required') }]}>
                    <Select className="default-select" placeholder={t('select-method')}>
                      <Select.Option value={"to"} key={"to"}>
                        {t('to')}
                      </Select.Option>
                      <Select.Option value={"cc"} key={"cc"}>
                        CC
                      </Select.Option>
                      <Select.Option value={"bcc"} key={"bcc"}>
                        BCC
                      </Select.Option>
                    </Select>
                  </Item>
                  <div className="col-24-md-1">
                    <label>&nbsp;</label>
                    <Button style={{ textAlign: "center" }} type="default" size="large" icon={<DeleteOutlined />} onClick={() => remove(recipient.name)} block />
                  </div>
                  {index === (array.length - 1) &&
                    <div className="col-24-md-1">
                      <label>&nbsp;</label>
                      <Button style={{ textAlign: "center" }} type="default" size="large" icon={<PlusOutlined />} onClick={() => add()} block />
                    </div>
                  }
                </div>
              ))}
              </>
            }
            </>
          )}
        </Form.List>
      </Card>
    </>
  );
};