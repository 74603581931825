import { Button, Form, Input, notification, Rate } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Translations from '../../../../../../common/components/Content/Translations/Translations';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';
import { Translations as TRLNS } from '../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../services/areas/content/series/series';
import { IMSNameParams } from '../../../../../dashboard';
import SubContent from '../../../../../../common/components/Content/Content/SubContent';
import Actors from '../../../../../../common/components/Content/Actors/Actors';
import { Seasons } from '../../../../../../interfaces/forms/areas/content/series/seasons';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import Tags from '../../../../../../common/components/Content/Tags/Tags';
import ReleaseSchedules from '../../../../../../common/components/Content/ReleaseSchedules/ReleaseSchedules';
import { useEffectOnce } from 'react-use';
import Providers from '../../../../../../common/components/Content/Providers/Providers';
import { AuthContext } from '../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../constants/TVMSRoles';
import moment from 'moment';

const AddNewMusicAlbum = () => {
  const history = useHistory();
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [logo, setLogo] = useState([] as any);
  const [schedules, setSchedules] = useState([] as any);
  const [poster, setPoster] = useState([] as any);
  const [backdrop, setBackdrop] = useState([] as any);
  const [actors, setActors] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [episodes, setEpisodes] = useState([] as any);
  const [provider, setProvider] = useState('No Provider');
  const [form] = useForm();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -4).join('/');
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;

  const handleSubmit = async (values: Seasons) => {
    setIsAdding(true);
    var translations_ = [] as any;
    translations.map((translation: any) => {
      var new_ = {
        name: translation.name,
        description: translation.description,
      };
      translations_.push(new_);
    });
    values = {
      name: values.name,
      series: '',
      number: values.number,
      released: values.released,
      language: values.language,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      rating: values.rating != undefined ? values.rating : '1',
      studio: values.studio,
      placement: {
        ut_start: String(moment().add(-99, 'year').unix()),
        ut_end: String(moment().add(99, 'year').unix()),
        no_play_on_trial: false,
      },
      releaseschedule: schedules,
      episodes: episodes,
      actors: actors,
      tags: tags,
      translations: translations_,
      images: {
        logo: logo?.url?.replace('https://cloudtv.akamaized.net', ''),
        background: backdrop?.url?.replace('https://cloudtv.akamaized.net', ''),
        poster: poster?.url?.replace('https://cloudtv.akamaized.net', ''),
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };

    let response = await cmsService.Add_Season(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      // if (response.data._id != undefined && _id != undefined) {
      //  // getSeries(response.data);
      // } else {
      //   notification.success({
      //     message: t('added-successfully'),
      //   });
      // }
      notification.success({
        message: t('added-successfully'),
      });
      history.go(0);
      setIsAdding(false);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
      history.push(redirectUrl + '/series/seasons');
    }
  };

  // const getSeries = async (season: any) => {
  //   setIsAdding(true);
  //   let response = await cmsService.Details_Series({
  //     query_params: {
  //       instance: clientName,
  //       cms: deployment?.name ?? '',
  //     },
  //     _id: _id,
  //   });
  //   if (response && response.status_code === HttpStatusCodes.OK) {
  //     form.setFieldsValue(response.data);
  //     var series = response.data as any;
  //     updateSeries(series, season);
  //   }
  // };
  // const updateSeries = async (series: any, season: any) => {
  //   var seasons = series.seasons;
  //   seasons.push(season);

  //   let response = await cmsService.Update_Series({
  //     query_params: {
  //       instance: clientName,
  //       cms: deployment?.name ?? '',
  //     },
  //     ...series,
  //     seasons: seasons,
  //   });
  //   if (response && response.status_code === HttpStatusCodes.OK) {
  //     notification.success({
  //       message: t('added-successfully'),
  //     });
  //     history.push(redirectUrl + '/series/details/' + _id);
  //   }
  //   setIsAdding(false);
  // };

  // const loadSeries = async () => {
  //   let response = await cmsService.Details_Series({
  //     query_params: {
  //       instance: clientName,
  //       cms: deployment?.name ?? '',
  //     },
  //     _id: _id,
  //   });
  //   if (response && response.status_code === HttpStatusCodes.OK) {
  //     form.setFieldsValue(response.data);
  //     var series = response.data as any;
  //     setSeries(series);
  //   }
  // };
  useEffectOnce(() => {
    //loadSeries();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-series-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-season')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-your-series-season-collection-and-offer-it-bundled-in-packages-to-your-customers')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-3 mb-3">{t('add-season')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('season-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('season-number')}</label>
                <Form.Item
                  className="col-md-7"
                  name="number"
                  rules={[
                    {
                      required: true,
                      message: t('number-is-required'),
                    },
                  ]}
                >
                  <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="0" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('released')}</label>
                <Form.Item
                  className="col-md-7"
                  name="released"
                  rules={[
                    {
                      required: true,
                      message: t('released-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="1999" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('studio')}</label>
                <Form.Item
                  className="col-md-7"
                  name="studio"
                  rules={[
                    {
                      required: true,
                      message: t('studio-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('marvel-studios')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('season-language')}</label>
                <Form.Item
                  className="col-md-7"
                  name="language"
                  rules={[
                    {
                      required: true,
                      message: t('language-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('english')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('star-rating')}</label>
                <Form.Item className="col-md-7 mt-2" name="rating">
                  <Rate defaultValue={1} />
                </Form.Item>
              </div>
            </div>

            {!isProvider && <Providers setProvider={setProvider} />}

            <Translations data={translations} service_name={service?.name ?? ''} clientName={clientName} setTranslations={setTranslations} />

            <Actors setActors={setActors} />

            <Tags setTags={setTags} />

            <ReleaseSchedules setSchedules={setSchedules} />

            <SubContent setData={setEpisodes} type={'Season Episodes'} data={episodes} />

            <ImageUploader
              onSubmit={(values: any) => {
                setPoster(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadName: t('season-poster'),
                uploadType: 'poster',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setBackdrop(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                uploadName: t('season-background'),
                uploadType: 'background',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
            <ImageUploader
              onSubmit={(values: any) => {
                setLogo(values);
              }}
              initialData={{
                client: clientName,
                deployment: deployment,
                service: undefined,
                notMandatory: true,
                uploadName: t('season-logo'),
                uploadType: 'logo',
                path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.IMAGES,
              }}
            />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-season')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
