import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { FormHeading, Item } from "../../../../common/components/form";
import ReportLogoUploader from "./reportLogoUploader";
import { Logo } from "@mwaretv/database/build/backend/models/reporting/reportSettings/reportSettings";

export const ReportSettingsFields = ({ logo }: { logo?: Logo}) => {
  const {t} = useTranslation();

  return (
    <>
      <Item hidden name={['_id']} />
      <Card>
        {/* <div className="row">
          <div className="col-md-12">
            <FormHeading>{t('report-settings')}</FormHeading>
          </div>
        </div> */}
        <ReportLogoUploader logo={logo} />
      </Card>
    </>
  );
};