import { useEffect, useState } from 'react';
import { Application } from '../../../../../interfaces/forms/areas/appbuilder/applications/application/application';
import { Rectangle_Rails } from '../../../../../interfaces/forms/areas/appbuilder/builder/elements/rails/rectangle/rectangle';
import { GetRailsElements } from './helpers/helper';
import { useTranslation } from 'react-i18next';

export type LayoutProps = {
  data: Rectangle_Rails;
  application: Application;
};

const FilterRails = (props: LayoutProps) => {
  useEffect(() => {}, [props.data]);
  const [color, setColor] = useState('#999999');
  const { t } = useTranslation();

  return (
    <div style={{ backgroundColor: props.data.placement.background_color }}>
      <div
        className="container remove-hor-padding"
        style={{
          paddingTop: props.application.theme?.rails_top_margin ?? 5,
          paddingBottom: props.application.theme?.rails_bottom_margin ?? 5,
        }}
      >
        {color == '#bf8c8c' && <div style={{ zIndex: 100, position: 'absolute', bottom: 0, right: 0, backgroundColor: 'crimson', color: '#ffffff', padding: 5, opacity: 0.85, fontSize: 12, fontWeight: 'bold', borderRadius: 5, marginRight: 10, marginBottom: 5, paddingLeft: 10, paddingRight: 10 }}>{t('publish-your-list')}</div>}
        <div style={{ gridAutoColumns: '100%', display: 'grid' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateRows: 'min-content',
              gridAutoFlow: 'column',
              gridAutoColumns: 355,
              gap: 5,
              overflowX: 'auto',
              overscrollBehaviorInline: 'contain',
              marginTop: 10,
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            <GetRailsElements form={'Filter'} type={''} props={props} color={color} isActive={false} height={200} width={355}></GetRailsElements>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterRails;
