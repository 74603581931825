import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs'

type ActiveCustomersChartProps = {
  chart_data: { serviceName: string; response: { activeCustomers: number; dateOfCount: string }[] }[];
};

function ActiveCustomersChart({ props }: { props: ActiveCustomersChartProps }) {
  const {t} = useTranslation();
  
  const allDates = new Set<string>();
  props.chart_data.forEach(({ response }) => {
    if (response) {
      response.forEach(({ dateOfCount }) => {
        if (dayjs(dateOfCount).month() === dayjs().month()) {
          allDates.add(dateOfCount);
        }
      });
    }
  });

  let categories: string[] = Array.from(allDates);

  try {
    categories = enrichDates(categories);
  }
  catch (error) {
    console.log(error);
  }

  const columnColors = ['#1ac884', '#0e7050', '#3cd69a', '#11865d', '#5ee4b0', '#149c6a', '#80f2c6', '#17b277', '#a2ffdc']; // alternating shades of green

  // the (stacked) columns will represent the actual day-to-day (daily) active customers per service,
  // while the line will represent the monthly highest-to-date (i.e. the peak) active customers for all services summed.
  const seriesPerServicePeak: any[] = [];
  let seriesPerServiceDaily: any[] = [];

  // since the lines and columns need different layouts/designs, and since there can be a different numbers of services,
  // we need to tell Apex Charts per series what the layout/design will be.
  const strokeWidthArray: any[] = [];
  const opacityFromArray: any[] = [];
  const opacityToArray: any[] = [];
  const colorsArray: any[] = [];
  const gradientToColorsArray: any[] = [];

  props.chart_data.forEach(({ serviceName, response }, index) => {
    const serviceDataPeak: (number | null)[] = [];
    const serviceDataDaily: (number | null)[] = [];

    let peak: number = 0;

    categories.forEach((categoryDate) => {
      const dataEntry = response?.find((entry) => entry.dateOfCount === categoryDate);

      serviceDataDaily.push(dataEntry?.activeCustomers ?? 0);

      if ((dataEntry?.activeCustomers ?? 0) > peak) {
        peak = dataEntry?.activeCustomers ?? 0;
      }
      
      serviceDataPeak.push(peak);

    });

    seriesPerServicePeak.push({
      name: serviceName,
      data: serviceDataPeak,
    });
    seriesPerServiceDaily.push({
      name: serviceName,
      data: serviceDataDaily,
      type: 'column'
    });

    // here we tell Apex Charts the layout/design of the column series
    strokeWidthArray.push(0);
    opacityFromArray.push(0.85);
    opacityToArray.push(0.85);
    colorsArray.push(columnColors[index]);
    gradientToColorsArray.push(columnColors[index]);
  });

  // here we tell Apex Charts the layout/design of the line series
  strokeWidthArray.push(3);
  opacityFromArray.push(0.8);
  opacityToArray.push(0);
  colorsArray.push("#485Fe0");
  // yes, the gradientToColorsArray is skipped here

  const seriesTotal = {
    name: t('deployment-total'),
    type: 'area',
    data: new Array(seriesPerServicePeak[0]?.data?.length).fill(0).map((value, index) => seriesPerServicePeak?.map((x) => x.data[index] ?? 0).reduce((a, b) => a + b, 0)),
    color: '#485Fe0',
  };

  const chartOptions: any = {
    chart: {
      type: 'area',
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: '20%'
      },
    },
    colors: colorsArray,
    stroke: {
      width: strokeWidthArray,
      curve: 'straight',
    },
    fill: {
      type: 'gradient',
      gradient: {
        enabled: true,
        shade: 'light',
        type: 'vertical',
        gradientToColors: gradientToColorsArray,
        opacityFrom: opacityFromArray,
        opacityTo: opacityToArray,
      },
    },
    markers: {
      size: 5,
      colors: ['#919fec'],
      strokeColor: '#485fe0',
      strokeWidth: 3,
    },
    xaxis: {
      categories: categories.map(category => {
        return dayjs(category).format('MMM D');
      }),
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    noData: {
      text: t('chart-design-no-data'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#505972',
        fontSize: '28px',
        fontFamily: undefined
      }
    }
  };


  return <ReactApexChart options={chartOptions} series={[...seriesPerServiceDaily, seriesTotal]} height={'400px'} />;
}

const enrichDates = (categories: string[]): string[] => {

  if (categories.length === 0) {
    return categories;
  }

  const numberOfDaysInMonth = dayjs(categories[0]).daysInMonth();
  const month = dayjs(categories[0]).month();
  const year = dayjs(categories[0]).year();

  for (let i = 1; i <= numberOfDaysInMonth; i++) {
    if (!categories.some(category => dayjs(category).date() === i)) {
      try {
        categories.push(dayjs(new Date(year, month, i)).format("YYYY-MM-DD"));
      }
      catch (error) {
        console.log(error);
      }
    }
  }

  return categories;

}

export default ActiveCustomersChart;
