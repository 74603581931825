import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Translations from '../../../../../../../common/components/Content/Translations/Translations';
import { Translations as TRLNS } from '../../../../../../../interfaces/responses/areas/content/shared/translations';
import cmsService from '../../../../../../../services/areas/content/courses/courses';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import Teachers from '../../../../../../../common/components/Content/Teachers/Teachers';
import { Courses } from '../../../../../../../interfaces/forms/areas/content/courses/courses';
import ParentalControl from '../../../../../../../common/components/Content/Parental/Parental';
import Providers from '../../../../../../../common/components/Content/Providers/Providers';
import { AuthContext } from '../../../../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../../../../constants/TVMSRoles';
import Share from '../../../../../../../common/components/Content/Share/Share';
import moment from 'moment';
import ReleaseSchedules from '../../../../../../../common/components/Content/ReleaseSchedules/ReleaseSchedules';

const AddNewMusicAlbum = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState<TRLNS[]>([]);
  const [teachers, setTeachers] = useState([] as any);
  const [tags, setTags] = useState([] as any);
  const [parental, setParental] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const [schedules, setSchedules] = useState([] as any);
  const [provider, setProvider] = useState('');
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;
  const [share, setShare] = useState(false);

  useEffect(() => {
    setSchedules(data.data.releaseschedule);
    setTags(data.data.tags);
    setTeachers(data.data.teachers);
    setTranslations(data.data.translations);

    setProvider(data.data.provider?.name);
    setShare(data.data.share ?? false);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: Courses) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    var translations_ = [] as any;
    translations.map((translation: any) => {
      var new_ = {
        name: translation.name,
        description: translation.description,
      };
      translations_.push(new_);
    });
    values = {
      _id: _id,
      ...data.data,
      name: values.name,
      difficulty: values.difficulty,
      released: values.released,
      language: values.language,
      placement: {
        ut_start: String(moment().add(-99, 'year').unix()),
        ut_end: String(moment().add(99, 'year').unix()),
        no_play_on_trial: false,
      },
      releaseschedule: schedules,
      share: share,
      provider: {
        name: isProvider ? AuthState?.user?.name : provider,
      },
      teachers: teachers,
      translations: translations_,
      parental: {
        ...values.parental,
      },
      tags: tags,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };

    let response = await cmsService.Update_Course(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('details')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('course-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('released')}</label>
                <Form.Item
                  className="col-md-7"
                  name="released"
                  rules={[
                    {
                      required: true,
                      message: t('released-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="1999" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('difficulty')}</label>
                <Form.Item
                  className="col-md-7"
                  name="difficulty"
                  rules={[
                    {
                      required: true,
                      message: t('dificulty-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('moderate')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('course-language')}</label>
                <Form.Item
                  className="col-md-7"
                  name="language"
                  rules={[
                    {
                      required: true,
                      message: t('language-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder={t('english')} />
                </Form.Item>
              </div>
            </div>
            {!isProvider && <Providers data={provider} setProvider={setProvider} />}
            <ParentalControl />
            <ReleaseSchedules setSchedules={setSchedules} data={schedules} />
            <Share data={share} setShare={setShare} />
            <Translations data={translations} service_name={service?.name ?? ''} clientName={clientName} setTranslations={setTranslations} />
            <Teachers data={teachers} setTeachers={setTeachers} />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-course')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
