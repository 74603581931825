import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Placement from '../../Content/Placement/Placement';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index: any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
  const { t } = useTranslation();
  const [placement, setPlacement] = useState([] as any);

  const onSubmitLocal = (values: any) => {
    var ad = {
      ut_start: placement.ut_start,
      ut_end: placement.ut_end,
      publisher_id: values.publisher_id,
    };
    let dataOut = {
      ...data,
      ...ad,
    };

    onSubmit(dataOut, type, props.index);
  };

  useEffect(() => {
    if (data != undefined) {
      setPlacement({
        no_play_on_trial: false,
        ut_end: data.ut_end ?? moment(moment().add('year', 99)).unix(),
        ut_start: data.ut_start ?? moment(moment()).unix(),
      });
    }
  }, [data, form]);

  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-castoola-ad-settings')}</h4>

            <div className="row mt-5">
              <div className="col-12">
                <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                  <div className="col">
                    <div className="row" style={{}}>
                      <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                        <div className="row mb-3">
                          <label className="col-md-4 col-form-label">{t('publisher-id')}</label>
                          <div className="col-md-8" style={{ paddingLeft: 0 }}>
                            <Form.Item
                              name="publisher_id"
                              className="form-group pb-0 col"
                              rules={[
                                {
                                  required: true,
                                  message: t('enter-button-name'),
                                },
                              ]}
                            >
                              <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder="" />
                            </Form.Item>
                          </div>
                          <div className="col"></div>
                          <div className="col"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ padding: 15 }}>
                    <Placement data={placement} setPlacement={setPlacement}></Placement>
                  </div>

                  <div className="col-md-12 p-0 mt-3">
                    <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                      {t('update-castoola-ad')}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
