import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator'
import { AreaChart, BarChart, ColumnChart, PieChart, PlainValueChart } from '../../../../common/components/Charts';
import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { AvailableChartTypesReporting, AvailableEntitiesReporting } from '@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums';
import * as HttpStatusCodes  from 'http-status-codes';
import { Button, Modal, Select, Tabs, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { GaugeChart } from '../../../../common/components/Charts/GaugeChart';
import dashboardService from '../../../../services/areas/reporting/dashboards/dashboardService';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Item } from '../../../../common/components/form';
import chartInputService from '../../../../services/areas/reporting/chartinputs/chartInputService';
import { AuthContext } from '../../../../context_api/AuthContext';
import { TVMSRoles } from '../../../../constants/TVMSRoles';
import { isInRoles } from '../../../../common/components/VisibleForRole';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { AppErrorList } from '../apperrors/AppErrorList';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';

const Dashboard = () => {
  const { t } = useTranslation();
  const { clientName, _id: clientId } = useParams<{ clientName: string, _id: string }>();
  const { deployment, service } = React.useContext(AppBuilderContext);
  const [chartInputs, setChartInputs] = useState<ChartInput[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = `/client/${clientName}/${clientId}/${deployment?.name}`;
  const [toCopyChartInputId, setToCopyChartInputId] = useState(undefined);
  const [nameOfServiceToCopyChartTo, setNameOfServiceToCopyChartTo] = useState(deployment?.services[0]?.name ?? "");
  const { state: AuthState } = useContext(AuthContext);
  const hasEditPermissions = isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin, TVMSRoles.Employee]);

  const fetchData = async () => {
    try {
      const chartInputs = await chartInputService.Get_ChartInputs(clientName, deployment?.name ?? "", service?.name ?? "", "apps");

      setChartInputs(chartInputs);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = async (): Promise<void> => {

    setIsLoading(true);

    try {
      const requestData: TvmsApiRequestData = {
        urlParams: {
          area: "apps",
        },
        queryParams: {
          clientName: clientName,
          deploymentName: deployment?.name ?? "",
          serviceName: service?.name ?? "",
        },
      };

      const response = await dashboardService.Generate(requestData);

      if (response.statusCode === HttpStatusCodes.OK) {
        await fetchData();
      }
      else {
        console.log(JSON.stringify(response));
      }
    }
    catch (err) {
      console.log(err);
    }

    setIsLoading(false);

  }

  return (
    <div className="container-fluid" style={{ padding: 30 }}>
      <Tabs
        className="default-tab"
        items={[
          {
            key: 'dashboard',
            label: t('dashboard'),
            children: (
              <ContainerWrapper>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <h2 className="page-title">{t('dashboard')}</h2>
                  </div>
                  <div className="col-md-4" />
                  <div className="col-md-2 d-flex justify-content-center justify-content-md-end align-items-start">
                    <Button disabled={isLoading} className="ant-btn btn btn-secondary rounded-pill width-240 height-48" onClick={() => handleRefresh()}>
                      {t('chart-design-refresh-dashboard')}
                    </Button>
                  </div>
                </div>
                <div className="row mt-4">
                {chartInputs != null && chartInputs.map(chartInput => {

                  const columnClassName = `col-md-${chartInput.width12column ?? 4}`;

                  return (
                    <>
                      <div className={columnClassName} style={{ marginBottom: "30px" }}>
                        <div style={chartInput.color ? { color: chartInput.color, backgroundImage: chartInput.backgroundImage, border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 } : { backgroundImage: 'linear-gradient(#f4f4f4, #ececec)', border: '1px solid #e9e9e9', borderRadius: 5, padding: 10 }}>
                          <div style={{ display: "flex", marginBottom: "10px" }}>
                            <div>
                              <p className="h5 font-weight-normal line-height-26" style={{ margin: 10 }}>
                                {chartInput.title}
                              </p>
                            </div>
                            {hasEditPermissions && chartInput.entityType !== AvailableEntitiesReporting.CUSTOMER &&
                            <div style={{ marginLeft: "auto", zIndex: 1 }}>
                              <Tooltip placement="top" title={t('chart-design-copy-chart')}>
                                <Button type="text" className="cursor-pointer" style={{ height: '100%', color: chartInput.color ?? '#505972' }} onClick={() => setToCopyChartInputId(chartInput.id)}>
                                  <FontAwesomeIcon icon={faCopy} />
                                </Button>
                              </Tooltip>
                            </div>
                            }
                          </div>
                          {chartInput.chartType === AvailableChartTypesReporting.BAR &&
                            <BarChart chartInput={chartInput} />
                          }
                          {chartInput.chartType === AvailableChartTypesReporting.COLUMN &&
                            <ColumnChart chartInput={chartInput} />
                          }
                          {chartInput.chartType === AvailableChartTypesReporting.LINE &&
                            <AreaChart chartInput={chartInput} />
                          }
                          {chartInput.chartType === AvailableChartTypesReporting.PIE &&
                            <PieChart chartInput={chartInput} />
                          }
                          {chartInput.chartType === AvailableChartTypesReporting.PLAIN_VALUE &&
                            <PlainValueChart chartInput={chartInput} />
                          }
                          {chartInput.chartType === AvailableChartTypesReporting.GAUGE &&
                            <GaugeChart chartInput={chartInput} />
                          }
                          <span style={{ marginLeft: 10 }}>{t('chart-design-data-as-of') + ": " + dayjs.unix(chartInput.timestamp).format("MMMM D, YYYY HH:mm")}</span>
                        </div>
                      </div>
                    </>
                  )
                })}
                </div>
                <Modal open={!!toCopyChartInputId} onCancel={() => setToCopyChartInputId(undefined)} okButtonProps={{href: `${baseUrl}/${nameOfServiceToCopyChartTo}/reporting/queries/copy/${toCopyChartInputId}`}}>
                  <Item labelCol={{ span: 24 }} label={`${t("chart-design-copy-chart-to-service-reporting")}:`}>
                    <Select style={{ width: '100%' }} defaultValue={nameOfServiceToCopyChartTo} onChange={(value) => {
                      setNameOfServiceToCopyChartTo(value);
                    }}>
                    {deployment?.services.map(service => {
                      return (
                        <Select.Option value={service.name} key={service.name}>
                          {service.name}
                        </Select.Option>
                      );
                    })}
                    </Select>
                  </Item>
                </Modal>
              </ContainerWrapper>
            ),
          },
          {
            key: 'logs-apperrors',
            label: t('app-builder-error-logs'),
            children: (
              <AppErrorList />
            ),
          },
        ]}
      />
    </div>
  );
};

export default Dashboard;
