import axiosInstance from '../../../../utils/axios';
import { API_ENDPOINTS } from '../../../_APIConstants';
import { ReportSettings } from '@mwaretv/database/build/backend/models/reporting/reportSettings/reportSettings';
import { TvmsApiResponseData } from '@mwaretv/database/build/backend/interfaces/tvmsApiResponseData';
import { TvmsApiRequestData } from '@mwaretv/database/build/backend/interfaces/tvmsApiRequestData';

class ReportSettingsService {
  GetOnlyOrDefault = async (requestData: TvmsApiRequestData): Promise<TvmsApiResponseData<ReportSettings>> => {

    return await axiosInstance
      .get<TvmsApiResponseData<ReportSettings>>(API_ENDPOINTS.V1.REPORTING.REPORT_SETTINGS.DETAILS, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Add = async (requestData: TvmsApiRequestData<ReportSettings>): Promise<TvmsApiResponseData<ReportSettings>> => {

    return await axiosInstance
      .post<TvmsApiResponseData<ReportSettings>>(API_ENDPOINTS.V1.REPORTING.REPORT_SETTINGS.ADD, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Update = async (requestData: TvmsApiRequestData<ReportSettings>): Promise<TvmsApiResponseData<ReportSettings>> => {

    return await axiosInstance
      .put<TvmsApiResponseData>(`${API_ENDPOINTS.V1.REPORTING.REPORT_SETTINGS.UPDATE}/${requestData.urlParams?.id}`, requestData.payload, {
        params: requestData.queryParams,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}
const REPORTSETTINGS_SERVICE = new ReportSettingsService();
export default REPORTSETTINGS_SERVICE;
